import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useGenerateSignUpToken, useSignUpPassword } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { DesktopAuthHeader } from 'components';

import { Form } from './components';

const Password: React.FC = () => {
  const { t } = useTranslation('component.auth.password');
  const [csrfToken, setCsrfToken] = React.useState<string>('');

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutateAsync: sendSignUpCode, isLoading } = useGenerateSignUpToken();

  const { mutateAsync: submit } = useSignUpPassword(csrfToken);

  const token = searchParams.get('token') || '';
  const email = searchParams.get('email') || '';
  const systemIdentifier = searchParams.get('system_identifier') || '';
  const navigateState =
    email && systemIdentifier
      ? {
          state: {
            client_email: email,
            system_identifier: systemIdentifier,
          },
        }
      : {};

  React.useEffect(() => {
    if (token) {
      sendSignUpCode({
        code: token,
        email: email,
      })
        .then((res) => {
          if (res?.token) {
            setSearchParams({ email: email });
            setCsrfToken(res.token);
          }
        })
        .catch(() => {
          navigate(routes.auth.signUp.url);
        });
    } else {
      navigate(routes.auth.signUp.url);
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <DesktopAuthHeader title={t('passwordToMagix')} />
      <Form
        loading={isLoading}
        onButton={(data) =>
          submit({ ...data, username: email }).then(() => {
            navigate(routes.auth.signIn.url, navigateState);
          })
        }
      />
    </Box>
  );
};

export default Password;
