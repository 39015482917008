import { RouterProvider } from 'react-router-dom';
import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { themeConfig } from 'theme';
import GlobalStyle from 'theme/Global';
import { router } from 'wrappers';

import { createQueryClient } from 'utils';

const theme = extendTheme({
  ...themeConfig,
  ...withDefaultColorScheme({ colorScheme: 'primary' }),
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

const queryClient = createQueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <GlobalStyle />
        <RouterProvider router={router} />
      </ChakraProvider>
    </QueryClientProvider>
  );
};
