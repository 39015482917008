import React from 'react';

import useRouterState from './useRouterState';

const useGetSignInServiceEmail = () => {
  const [externalUserData, setExternalUserData] = React.useState<{
    client_email?: string;
    system_identifier?: string;
    returnUrl?: string;
  }>({
    client_email: undefined,
    system_identifier: undefined,
    returnUrl: undefined,
  });

  useRouterState({
    onChange: (n, location) => {
      if (location.state) {
        if (location.state.client_email && location.state.system_identifier) {
          setExternalUserData({
            client_email: location.state.client_email,
            system_identifier: location.state.system_identifier,
          });
        }
        if (location.state.returnUrl) {
          setExternalUserData((prevState) => ({
            ...prevState,
            returnUrl: location.state.returnUrl,
          }));
        }
      }
    },
    dependencies: [],
  });

  return externalUserData;
};

export default useGetSignInServiceEmail;
