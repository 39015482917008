import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, UploadBankReceipt } from 'types';

import { api } from 'utils';

const useUploadWithdrawBankReceipt = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  { id: string; data?: UploadBankReceipt }
> => {
  return useMutation(({ id, data }) =>
    api.put(`${url.uploadWithdrawBankReceipt}/${id}/approve`, data ? data : {})
  );
};

export default useUploadWithdrawBankReceipt;
