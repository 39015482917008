import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Badge,
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ReactComponent as InputWarningIcon } from 'assets/icons/input_warning.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCurrencies } from 'hooks';
import { useTranslation } from 'localization';
import { LDUser, LDUsers } from 'types';

import { Button } from 'components';

import InitForm from '../InitForm';

type LDListProps = { lds: LDUsers; symbol: string | undefined };

const LDList: React.FC<LDListProps> = ({ lds, symbol }) => {
  const { t } = useTranslation('component.deposit.ld');

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [searchParams] = useSearchParams();

  const [activeLD, setActiveLD] = React.useState<null | LDUser>(null);

  const { data: currencies } = useCurrencies({
    queries: {},
    enabled: Boolean(activeLD),
  });

  const currencyList =
    currencies?.list
      ?.filter((currency) =>
        activeLD?.ld_contract?.pay_with_currencies?.includes(currency.id)
      )
      .map((currency) => ({
        label: currency.title,
        value: currency.symbol,
      })) || [];

  dayjs.extend(utc);
  const now = dayjs.utc();

  return (
    <Box w={{ base: '100%' }}>
      <Text>{t('chooseLd')}</Text>
      <Accordion
        allowToggle
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        flexWrap="wrap"
        gap={{ base: 0, lg: 4 }}
        onChange={(active) => {
          if (lds && active !== null) {
            setActiveLD(lds?.[active as number]);
          }
        }}
      >
        {lds?.map((ld) => {
          const today = ld.ld_contract.working_hours.find(
            (daily) => daily.day === dayjs().get('d')
          );
          const isActiveToday = today?.is_open;
          const openTime = today?.open_time || '00:00';
          const closeTime = today?.close_time || '23:59';

          const startWorkingHour = dayjs(
            now.format(`YYYY-MM-DDT${openTime}:00+00:00`)
          );
          const endWorkingHour = dayjs(
            now.format(`YYYY-MM-DDT${closeTime}:00+00:00`)
          );

          const isActiveWorkingHour =
            now.diff(endWorkingHour, 'minute') < 0 &&
            now.diff(startWorkingHour, 'minute') > 0;

          return (
            <Box key={ld.id} w={{ base: '100%', lg: '45%' }} py={4}>
              <AccordionItem
                border="1px solid"
                borderRadius={10}
                bgColor={
                  isActiveToday && isActiveWorkingHour
                    ? activeLD?.id === ld.id
                      ? 'lightPrimary'
                      : ''
                    : 'rgba(255,153,153,0.1)'
                }
              >
                <AccordionButton>
                  <HStack w="100%">
                    <Text fontWeight="bold" textAlign="left">
                      {ld.ld_contract.nick_name}
                    </Text>
                    <Text fontSize="xs" textAlign="left">
                      {'( ' + ld.country.cca3 + ' )'}
                    </Text>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <VStack w="100%">
                    <HStack
                      w="100%"
                      justify="flex-start"
                      cursor="pointer"
                      onClick={() => onOpen()}
                    >
                      <InputWarningIcon />
                      <Text color="warning" fontSize="sm">
                        {t('addressesHint')}
                      </Text>
                    </HStack>
                    <HStack w="100%" justify="space-between">
                      <Text>{t('ldNumber')}</Text>
                      <Text>{ld.identifier_number}</Text>
                    </HStack>
                    <Divider />
                    {isActiveToday && isActiveWorkingHour ? (
                      <Box w="100%">
                        <InitForm
                          currencyList={currencyList}
                          ldUsers={lds}
                          activeLD={activeLD}
                          walletId={searchParams.get('wallet') || ''}
                          symbol={symbol}
                        />
                      </Box>
                    ) : (
                      <Alert colorScheme="yellow">
                        <Text color="warning">{t('isNotWorking')}</Text>
                      </Alert>
                    )}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Box>
          );
        })}
      </Accordion>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {activeLD?.ld_contract.nick_name + ' addresses'}
          </ModalHeader>
          <ModalBody>
            {activeLD?.ld_contract.addresses.map(({ city, address }) => {
              return (
                <Stack
                  key={address}
                  borderTop="1px solid gray"
                  justify="center"
                >
                  <Badge w="max-content">{city}</Badge>
                  <Text color="primary" mb={4}>
                    {address}
                  </Text>
                </Stack>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} title="Close" />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LDList;
