import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, KycLevel1Response } from 'types';

import { api } from 'utils';

const useKycLevel2 = (): UseMutationResult<
  KycLevel1Response | any,
  ErrorResponse
> => {
  return useMutation(() => api.post(url.kycLevel2));
};
export default useKycLevel2;
