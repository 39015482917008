import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Wallets } from 'types';

import { fixDecimal, walletColors } from 'utils';

type WalletsListType = {
  wallets: Wallets;
};
const WalletsList: React.FC<WalletsListType> = ({
  wallets,
}: WalletsListType) => {
  return (
    <VStack gap="4" w="full">
      {wallets.map((wallet) => {
        return (
          <HStack
            key={wallet.id}
            w="full"
            bg="secondary"
            px="4"
            py="1"
            borderRadius="lg"
            border="1px solid"
            borderColor={walletColors[wallet.currency.symbol] || 'white'}
            justifyContent="space-between"
          >
            <HStack>
              <Box
                w={10}
                h={10}
                objectFit="contain"
                borderRadius={25}
                overflow="hidden"
              >
                <img src={wallet.currency.icon} width="40px" height="40px" />
              </Box>
              <Text fontSize="xs">{wallet.currency.title}</Text>
            </HStack>
            <Text>
              {fixDecimal({
                value: wallet.amount,
                fractionDigits: wallet.currency.type === 'CRYPTO' ? 5 : 2,
              })}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
};
export default WalletsList;
