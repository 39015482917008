import { AccordionPanel, Stack } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Proposal } from 'types';

import { LDTransactionCard, LDTransactionMessage } from 'components';

import Pending from '../../../Pending';

type ProposalCardProps = {
  proposal: Proposal;
  refetch: () => void;
};

const ProposalCard: React.FC<ProposalCardProps> = ({
  proposal,
  refetch,
}: ProposalCardProps) => {
  const { t } = useTranslation('component.ldRequests.proposals');

  const STATES: Record<Proposal['status'], React.ReactElement | string> = {
    INIT: (
      <LDTransactionMessage
        title={t('waitForLdRate')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ),
    PENDING: (
      <Pending
        paymentMethods={proposal.source_wallet.user.ld_contract.payment_methods}
        addresses={proposal.source_wallet.user.ld_contract.addresses}
        proposalId={proposal?.id || ''}
        refetch={refetch}
      />
    ),
    CANCELED: (
      <LDTransactionMessage
        title={t('canceled')}
        status="error"
        textProps={{ color: 'error' }}
      />
    ),
    APPROVED: (
      <LDTransactionMessage
        title={t('approved')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ),
    REJECTED: (
      <LDTransactionMessage
        title={t('rejected')}
        status="error"
        textProps={{ color: 'error' }}
      />
    ),
    SETTLED: (
      <LDTransactionMessage
        title={t('waitForLDPaymentGateway')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ),
    COMPLETED: '',
    FAILED: '',
  };

  return (
    <AccordionPanel pb={4}>
      <LDTransactionCard
        amount={proposal.amount}
        identifier_number={proposal.source_wallet.user.identifier_number}
        pay_with={proposal.pay_with}
        symbol={proposal.destination_wallet.currency.symbol}
        rate={proposal.rate}
        note={proposal.note}
        payment_method={proposal.payment_method}
        payingValue={proposal.amount * proposal.rate}
        panel="USER"
        method="DEPOSIT"
      />
      <Stack mt="2">{STATES[proposal.status]}</Stack>
    </AccordionPanel>
  );
};
export default ProposalCard;
