/* eslint-disable no-unused-vars */
import axios, { Axios, AxiosPromise, AxiosRequestConfig } from 'axios';

import { standaloneToast } from 'utils';

export interface CustomAxiosInstance extends Axios {
  (config: AxiosRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRequestConfig): AxiosPromise;
}
// TODO: headers type
const api: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      if (typeof window !== 'undefined') {
        const token = localStorage.getItem('magix-token');
        if (token && headers) {
          headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return JSON.stringify(data);
    },
  ],
});

const notShowToast = [
  '/client/rates/provide',
  '/client/fees',
  '/client/contacts',
  '/client/swaps/available-source-wallets',
  '/client/swaps/available-destination-wallets',
];

api.interceptors.response.use(
  (response) => {
    if (response.config.method === 'post' || response.config.method === 'put') {
      if (
        response.config.url &&
        !notShowToast.some((element) => response.config.url?.includes(element))
      ) {
        standaloneToast({
          position: 'top-right',
          description: response.data.message,
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    return response.data.data;
  },
  (error) => {
    const { status, data } = error.response ?? { status: null };
    standaloneToast({
      position: 'top-right',
      description: data?.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    if (status === 401) {
      localStorage.removeItem('magix-token');
      if (window.location.pathname !== '/auth') {
        window.location.replace('/auth/login');
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
export default api;
