import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAccess, useWalletTabs } from 'hooks';
import { useTranslation } from 'localization';

import { Tabs } from 'components';

import {
  Head,
  LDDeposits,
  LDWithdraws,
  TransactionsTab,
  WalletsTab,
} from './components';

const Wallets: React.FC = () => {
  const { t } = useTranslation('page.wallets');

  const access = useAccess();
  const { active, setActive } = useWalletTabs();

  return (
    <Box w="100%">
      <Head />
      <Box my={{ base: 0, lg: 8 }}>
        <Tabs
          isLazy
          index={active}
          onChange={(activeIndex) => setActive(activeIndex)}
          tabData={[
            {
              label: t('tabs.wallets'),
              content: <WalletsTab />,
              isActive: true,
            },
            {
              label: t('tabs.transactions'),
              content: <TransactionsTab />,
              isActive: true,
            },
            {
              label: t('tabs.deposits'),
              content: <LDDeposits />,
              isActive: access.depositLDCreatePermission,
            },
            {
              label: t('tabs.withdraws'),
              content: <LDWithdraws />,
              isActive: access.withdrawLDCreatePermission,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Wallets;
