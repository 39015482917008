import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';

const PasswordStrength: React.FC<{ password: string }> = ({ password }) => {
  const { t } = useTranslation('component.auth.password.form');

  const passwordChecking = {
    bg: ['secondary2', 'secondary2', 'secondary2', 'secondary2'],
    color: 'secondary2',
    text: t('short'),
  };

  const checkList = [
    /(?=.*[A-Z])/.test(password),
    /(?=.*[a-z])/.test(password),
    /(?=.*[0-9])/.test(password),
    /(?=.*[!@#$&*])/.test(password),
  ];

  const checkCount = checkList.filter((x) => x === true).length;
  if (checkCount < 3 && checkCount > 0) {
    passwordChecking.bg = ['error', 'secondary2', 'secondary2', 'secondary2'];
    passwordChecking.color = 'error';
    passwordChecking.text = t('weak');
  }
  if (checkCount === 3) {
    passwordChecking.bg = ['warning', 'warning', 'secondary2', 'secondary2'];
    passwordChecking.color = 'warning';
    passwordChecking.text = t('medium');
  }
  if (checkCount > 3) {
    passwordChecking.bg = ['success', 'success', 'success', 'success'];
    passwordChecking.color = 'success';
    passwordChecking.text = t('strong');
    if (password.length < 8) {
      passwordChecking.bg = ['error', 'secondary2', 'secondary2', 'secondary2'];
      passwordChecking.color = 'error';
      passwordChecking.text = t('short');
    }
  }

  return (
    <Box my={1} w="100%" display="flex" alignItems="center" gap={5}>
      <Box display="flex" alignItems="center" w="100%" gap={1}>
        {[0, 1, 2, 3].map((item) => {
          return (
            <Box
              key={item}
              h={1}
              w="25%"
              borderRadius={5}
              bg={passwordChecking.bg[item]}
            />
          );
        })}
      </Box>
      <Box>
        <Text color={passwordChecking.color}>{passwordChecking.text}</Text>
      </Box>
    </Box>
  );
};

export default PasswordStrength;
