import { useSearchParams } from 'react-router-dom';

const useWithdrawTabs = () => {
  const [searchparams, setSearchParams] = useSearchParams();

  const tabs: {
    getByKey: Record<string, Record<string, number>>;
    getByIndex: Record<string, Record<number, string>>;
  } = {
    getByKey: {
      FIAT: { FIAT: 0, LD: 1 },
      CRYPTO: { CRYPTO: 0, LD: 1 },
    },
    getByIndex: {
      FIAT: {
        0: 'FIAT',
        1: 'LD',
      },
      CRYPTO: {
        0: 'CRYPTO',
        1: 'LD',
      },
    },
  };

  const depositType = searchparams.get('tab')?.toUpperCase() || '';
  const activeTabIndex = ({ type = 'FIAT' }: { type?: 'FIAT' | 'CRYPTO' }) =>
    tabs.getByKey[type][depositType];

  const changeActiveTab = ({
    index,
    type = 'FIAT',
  }: {
    index: number;
    type?: 'FIAT' | 'CRYPTO';
  }) => {
    searchparams.set('tab', tabs.getByIndex[type][index]);
    setSearchParams(searchparams);
  };

  return {
    getByIndex: tabs.getByIndex,
    getByKey: tabs.getByKey,
    activeTabIndex,
    changeActiveTab,
  };
};

export default useWithdrawTabs;
