import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as CircleCheck } from 'assets/icons/circle-check.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { useTimer } from 'hooks';
import { useTranslation } from 'localization';

import { Button } from 'components';

type DepositResultProps = {
  transactionResult: {
    status: 'success' | 'cancel';
    redirectUrl: string;
  };
};

const DepositResult: React.FC<DepositResultProps> = ({
  transactionResult,
}: DepositResultProps) => {
  const { t } = useTranslation('component.services.xm.deposit.result');
  const { counter } = useTimer(5);
  React.useEffect(() => {
    if (counter === 0) {
      window.location.replace(transactionResult.redirectUrl);
    }
  }, [counter]);

  return (
    <Stack
      bgColor="dark"
      px={4}
      py={2}
      w={{ base: 'full', lg: '600px' }}
      align="center"
      gap={4}
    >
      <Box>
        {transactionResult.status === 'success' ? (
          <CircleCheck width={80} height={80} />
        ) : (
          <Warning width={80} height={80} />
        )}
      </Box>
      <Box>
        <Text fontSize="2xl" fontWeight="500">
          {transactionResult.status === 'success'
            ? t('successTitle')
            : t('cancelTitle')}
        </Text>
      </Box>
      <Box>
        <Link to={transactionResult.redirectUrl}>
          <Button
            variant="filled"
            title={
              (transactionResult.status === 'success'
                ? t('successRedirect')
                : t('cancelRedirect')) +
              ' ( ' +
              counter +
              ' ) '
            }
            mt={8}
          />
        </Link>
      </Box>
    </Stack>
  );
};
export default DepositResult;
