import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useWalletTabs = () => {
  const [active, setActive] = React.useState<number>(0);

  const [searchparams, setSearchparams] = useSearchParams();

  React.useEffect(() => {
    if (searchparams.get('tab') === 'transaction') {
      setActive(1);
    } else if (searchparams.get('tab') === 'deposit') {
      setActive(2);
    } else if (searchparams.get('tab') === 'withdraw') {
      setActive(3);
    } else {
      setActive(0);
    }
  }, [searchparams.get('tab')]);

  const changeActiveTab = (index: number) => {
    const tabs: Record<number, string> = {
      0: 'wallet',
      1: 'transaction',
      2: 'deposit',
      3: 'withdraw',
    };
    setSearchparams({ tab: tabs[index] });
    setActive(index);
  };

  return { active, setActive: changeActiveTab };
};

export default useWalletTabs;
