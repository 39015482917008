import { Badge } from '@chakra-ui/react';

type PercentSelectProps = {
  list: number[];
  // eslint-disable-next-line no-unused-vars
  onPercent: (item: number) => void;
};

const PercentSelect: React.FC<PercentSelectProps> = ({
  list,
  onPercent,
}: PercentSelectProps) => {
  return list.map((item) => {
    return (
      <Badge
        key={item}
        w="max-content"
        my={2}
        py={2}
        px={4}
        minW={20}
        textAlign="center"
        borderRadius={10}
        fontSize="sm"
        cursor="pointer"
        onClick={() => onPercent(item)}
      >
        {item}%
      </Badge>
    );
  });
};

export default PercentSelect;
