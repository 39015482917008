import { Box, Text } from '@chakra-ui/react';
import { useMQ } from 'hooks';

type DesktopAuthHeaderType = { title: string; subTitle?: string };

const DesktopAuthHeader: React.FC<DesktopAuthHeaderType> = ({
  title,
  subTitle,
}: DesktopAuthHeaderType) => {
  const [isSmallScreen] = useMQ('min-width');

  if (isSmallScreen) {
    return (
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        mt={8}
      >
        <Text fontSize="5xl" fontWeight="500" fontFamily="Inter">
          {title}
        </Text>
        {subTitle && (
          <Text fontSize="md" fontFamily="Satoshi" mt={3}>
            {subTitle}
          </Text>
        )}
      </Box>
    );
  }
};

export default DesktopAuthHeader;
