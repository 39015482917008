import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, InitiateLogin, InitiateLoginResponse } from 'types';

import { api } from 'utils';

const useInitiateLogin = (): UseMutationResult<
  InitiateLoginResponse,
  ErrorResponse,
  InitiateLogin
> => {
  return useMutation((data) => api.post(url.initiateLogin, data));
};
export default useInitiateLogin;
