import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import SumsubWebSdk from '@sumsub/websdk-react';
// import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as ExchangeMagix } from 'assets/icons/exchange_magix.svg';
import { ReactComponent as Gear } from 'assets/icons/gear.svg';
import { ReactComponent as MagixLogo } from 'assets/icons/magixLogo.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { useAccess, useKycLevel2 } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';
import { UserLevel } from 'types';

import { IconButton, MenuList, Spinner } from 'components';

type HeaderType = {
  username: string | null;
  level: UserLevel | undefined;
  isLoading: boolean;
};

const Header: React.FC<HeaderType> = ({
  username = null,
  level = 'LEVEL0',
  isLoading,
}: HeaderType) => {
  const { t } = useTranslation('layout.header');
  const navigate = useNavigate();
  const location = useLocation();
  const access = useAccess();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: kycData, mutateAsync: upgradeLevel2 } = useKycLevel2();
  const {
    isOpen: isOpenLevel2,
    onOpen: onOpenLevel2,
    onClose: onCloseLevel2,
  } = useDisclosure();

  const levelType = {
    LEVEL0: 0,
    LEVEL1: 1,
    LEVEL2: 2,
    LD: 'Local Depositor',
    BROKER: 'BROKER',
  };

  const Level = () => {
    return (
      <Box display="inline">
        {isLoading ? (
          <Spinner size="sm" thickness="1px" />
        ) : Object.keys(levelType).includes(level) ? (
          levelType[level]
        ) : (
          0
        )}
      </Box>
    );
  };

  const Username = () => {
    return (
      <Box display="inline">
        {isLoading ? (
          <Spinner size="sm" thickness="1px" />
        ) : username ? (
          `${username} | `
        ) : (
          ''
        )}
      </Box>
    );
  };
  const currentPage = location.pathname.split('/')[1].split('/')[0];

  const ldLevel = (level || 'LD') === 'LD';

  const menuList = [
    {
      title: 'dashboard',
      link: '/dashboard',
      isActive: true,
    },
    { title: 'exchange', link: '/exchange', isActive: access.swapService },
    {
      title: 'transfer',
      link: '/transfer',
      isActive: access.transferService && !ldLevel,
    },
    { title: 'wallets', link: '/wallets', isActive: access.walletService },
    {
      title: 'requests',
      link: '/requests',
      isActive: ldLevel && access.requestLDService,
    },
    { title: 'settings', link: '/settings', isActive: true },
  ];

  const closeKycModalHandler = () => {
    navigate(0);
    onCloseLevel2();
  };
  // TODO : title header from router
  return (
    <HStack
      justify="space-between"
      bg={{ base: 'none', lg: 'dark' }}
      borderRadius={{ base: 'none', lg: 'lg' }}
      py={{ base: '2', lg: '3' }}
      px="6"
      borderBottom={{ base: '1px solid', lg: 'none' }}
      borderColor="body2"
      w="full"
    >
      <HStack
        justify="space-between"
        w="full"
        display={{ base: 'flex', lg: 'none' }}
      >
        <HStack gap="4">
          <MagixLogo />
          <Text textTransform="capitalize">{currentPage}</Text>
        </HStack>
        <HStack>
          {/* <IconButton ariaLabel="notification" icon={Bell} /> */}
          <IconButton
            ariaLabel="menu"
            icon={Menu}
            size="sm"
            onClick={() => onOpen()}
          />
        </HStack>
      </HStack>
      <HStack
        display={{ base: 'none', lg: 'flex' }}
        justify="space-between"
        w="full"
      >
        <HStack gap="2">
          <HStack>
            <Box display={{ base: 'none', lg: 'inline' }}>
              <Username />
            </Box>
            <Text>{t('level')} :</Text>
            <Level />
            {level === 'LEVEL1' && (
              <Text
                color="primary"
                cursor="pointer"
                onClick={() => upgradeLevel2([]).then(() => onOpenLevel2())}
              >
                {t('upgrade')}
              </Text>
            )}
          </HStack>
        </HStack>
        <HStack gap="4">
          {/* <IconButton ariaLabel="notification" icon={Bell} /> */}
          <Link to={routes.settings.url}>
            <IconButton ariaLabel="setting" icon={Gear} />
          </Link>
        </HStack>
      </HStack>
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent bg="secondary">
          <DrawerCloseButton />
          <DrawerHeader>
            <ExchangeMagix
              cursor="pointer"
              onClick={() => window.location.replace('/')}
            />
          </DrawerHeader>
          <DrawerBody px={0}>
            <MenuList
              list={menuList.filter((item) => item.isActive)}
              closeDrawer={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenLevel2}
        onClose={closeKycModalHandler}
        size={{ base: 'full', lg: 'full' }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('upgradeToLvl2')}</ModalHeader>
          <ModalCloseButton right={4} left="unset" />
          <ModalBody px={0} bg="sumsub">
            {kycData && (
              <SumsubWebSdk
                accessToken={kycData.token}
                expirationHandler={() => closeKycModalHandler()}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
export default Header;
