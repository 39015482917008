import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, AlertIcon, Box, Checkbox, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as InputErrorIcon } from 'assets/icons/input_error.svg';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';
import { emailRegex } from 'utils/regex';

import CustomerAgreement from '../CustomerAgreement';

type PayloadType = { email: string; agreement: boolean };
/* eslint-disable no-unused-vars */
type FormType = {
  onButton: (data: PayloadType) => void;
  isDisableForm?: boolean;
  counter: number;
  defaultEmail?: string;
};
const Form: React.FC<FormType> = ({
  onButton,
  isDisableForm = false,
  counter,
  defaultEmail,
}: FormType) => {
  const { t } = useTranslation('component.auth.signUp.form');

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .lowercase()
      .matches(emailRegex, t('email_validate'))
      .label('email')
      .required(),
    agreement: yup
      .boolean()
      .label('agreement')
      .oneOf([true], t('checkAgreement'))
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      agreement: false,
    },
  });

  const onSubmit = async (data: PayloadType) => {
    onButton(data);
  };

  const formProps = useInputAttributes(register, errors, {
    email: t('email'),
  });

  const resendButton = `${t('resend')} ( ${counter.toString()} )`;

  React.useEffect(() => {
    if (defaultEmail) {
      setValue('email', defaultEmail);
    }
  }, [defaultEmail]);

  return (
    <Box
      px={{ base: 2, sm: 0 }}
      w={{ base: '100%', sm: 312 }}
      mt={{ base: 2, md: 20 }}
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      {isDisableForm && (
        <Alert status="info" mb={4} w={{ base: '100%', md: '550px' }}>
          <AlertIcon />
          <Text color="info">{t('codeSent')}</Text>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...formProps.email}
          disabled={isDisableForm || Boolean(defaultEmail)}
        />
        <Checkbox
          {...formProps.agreement}
          disabled={isDisableForm}
          colorScheme="whiteAlpha"
          onChange={(e) => setValue('agreement', e.target.checked)}
          mt={4}
        >
          <CustomerAgreement />
          {errors?.agreement && (
            <Box pos="absolute" top={6} display="flex" alignItems="center">
              <InputErrorIcon />
              <Text color="error" pl={2} fontSize="xs">
                {errors.agreement.message}
              </Text>
            </Box>
          )}
        </Checkbox>
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={isDisableForm ? resendButton : t('submit')}
          isDisabled={isDisableForm}
          minW="100%"
          mt={{ base: 24, md: 8 }}
        />
      </form>
    </Box>
  );
};

export default Form;
