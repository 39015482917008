const walletColors: Record<string, string> = {
  EUR: '#003f88',
  USD: '#29A17E',
  TRY: '#e71d36',
  AUD: '#072ac8',
  EGP: '#8c1c13',
  SAR: '#52b788',
  JOD: '#df2935',
  IQD: '#df2935',
  //
  USDT: '#299393',
  AAVE: '#9391f7',
  ADA: '#0033ae',
  BAT: '#ff5000',
  MATIC: '#8b36d7',
  MKR: '#5ab7a8',
  SUI: '#4ca3ff',
  BCH: '#8dc351',
  YFI: '#0064cd',
  DAI: '#f9ae1a',
  LTC: '#345d9d',
  SUSHI: '#c868b0',
  COMP: '#00d395',
  SOL: '#8555f2',
  XRP: '#23292f',
  SHIB: '#ed4934',
  BTC: '#f7931a',
  ETH: '#131313',
  UNI: '#fcedf4',
  SNX: '#10043d',
  GRT: '#433aa9',
  USDC: '#2775ca',
  ZEC: '#ecb244',
  CRV: '#1afadc',
  LINK: '#335dd2',
};

export default walletColors;
