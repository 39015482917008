import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'localization';

import { Button } from 'components';

type ExchangeTypeButtonProps = {
  // eslint-disable-next-line no-unused-vars
  toggle: (type: 'buy' | 'sell') => void;
  type: 'buy' | 'sell';
};

const ExchangeTypeButton: React.FC<ExchangeTypeButtonProps> = ({
  toggle,
  type,
}: ExchangeTypeButtonProps) => {
  const { t } = useTranslation('component.exchange.advance');

  return (
    <Stack flexDir="row" gap={0}>
      <Button
        borderRightRadius={0}
        w="100%"
        title={t('buy')}
        bgColor={type === 'buy' ? 'darkPrimary' : 'unset'}
        _hover={{ bgColor: type === 'buy' ? 'darkPrimary' : 'transparent' }}
        border="1px solid"
        borderColor={type === 'buy' ? 'darkPrimary' : 'body2'}
        onClick={() => toggle('buy')}
        fontWeight="bold"
      />
      <Button
        borderLeftRadius={0}
        w="100%"
        title={t('sell')}
        bgColor={type === 'sell' ? 'error' : 'unset'}
        _hover={{ bgColor: type === 'sell' ? 'error' : 'transparent' }}
        border="1px solid"
        borderColor={type === 'sell' ? 'error' : 'body2'}
        onClick={() => toggle('sell')}
        fontWeight="bold"
      />
    </Stack>
  );
};

export default ExchangeTypeButton;
