import React from 'react';
import { Stack, StackProps, Text } from '@chakra-ui/react';

type LDTransactionKeyValueProps = {
  title: string;
  value: string | number | React.ReactNode;
  icon?: any;
} & StackProps;

const LDTransactionKeyValue: React.FC<LDTransactionKeyValueProps> = ({
  title,
  value,
  icon,
  ...props
}: LDTransactionKeyValueProps) => {
  return (
    <Stack flexDir="row" w="100%" justify="space-between" {...props}>
      <Stack flexDir="row" align="center">
        {icon}
        <Text fontSize="md">{title + ' : '}</Text>
      </Stack>
      <Text fontSize="md" color="body2" wordBreak="break-word">
        {value}
      </Text>
    </Stack>
  );
};
export default LDTransactionKeyValue;
