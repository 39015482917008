import { useNavigate } from 'react-router-dom';
import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import { useBalance, useWallets } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Button, DoughnutChart, Spinner, WalletList } from 'components';
import { fixDecimal } from 'utils';

const WalletOverview = () => {
  const { t } = useTranslation('component.dashboard.walletOverview');
  const navigate = useNavigate();

  const { data: wallets, isLoading } = useWallets({ queries: {} });
  const { data: balance } = useBalance();
  const Wallets = () => {
    return (
      <VStack w="100%">
        <Box w="100%">
          <Text fontSize="26px" fontWeight="semibold" textAlign="left">
            {t('title')}
          </Text>
        </Box>
        {balance && balance?.balance > 0 ? (
          <Stack
            w="100%"
            flexDir={{ base: 'column-reverse', lg: 'row' }}
            align="center"
          >
            <Box mt="2" w="full" maxW={{ base: 'full', lg: '296px' }}>
              <WalletList
                wallets={
                  wallets?.list
                    .sort((a, b) =>
                      a.currency.symbol === 'USDT'
                        ? -1
                        : b.currency.symbol === 'USDT'
                          ? 1
                          : a.currency.symbol.localeCompare(b.currency.symbol)
                    )
                    .sort((a, b) => b.amount - a.amount)
                    .slice(0, 5) || []
                }
              />
            </Box>
            <Box
              flex="1"
              w="275px"
              h="275px"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DoughnutChart
                wallets={wallets?.list || []}
                balance={
                  balance ? `$ ${fixDecimal({ value: balance.balance })}` : ''
                }
              />
            </Box>
          </Stack>
        ) : (
          <Stack
            w="100%"
            flexDir={{ base: 'column', lg: 'row' }}
            align="center"
            justifyContent="space-between"
          >
            <Box mt="2">
              <Text fontSize="20px">{t('walletBalanceEmpty')}</Text>
            </Box>
            <Box mt="2">
              <Button
                title={t('deposit')}
                variant="outline"
                bg="transparent"
                color="primary"
                _hover={{ color: 'body' }}
                onClick={() => navigate(routes.deposit.url)}
              />
            </Box>
          </Stack>
        )}
      </VStack>
    );
  };

  return (
    <Stack
      direction={{ base: 'column-reverse', lg: 'row' }}
      bg="dark"
      w="full"
      borderRadius="lg"
      justify="center"
      align="center"
      p={{ base: '4', md: 6 }}
      pb={{ base: '6', md: 4 }}
    >
      {isLoading && <Spinner />}
      {!isLoading && <Wallets />}
    </Stack>
  );
};
export default WalletOverview;
