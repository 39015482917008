import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Wallets } from 'types';

import { api } from 'utils';

const useSwapAvailableSources = (): UseMutationResult<
  Wallets,
  ErrorResponse
> => {
  return useMutation((data) => api.post(url.swapAvailableSource, data));
};
export default useSwapAvailableSources;
