import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  ErrorResponse,
  ListResponse,
  TransactionMethod,
  TransactionMethodCategory,
} from 'types';

const useTransactionMethods = ({
  queries,
  enabled,
}: {
  queries: {
    allowed_currencies?: string;
    category?: TransactionMethodCategory;
    allowed_countries?: string;
  };
  enabled?: boolean;
}): UseQueryResult<ListResponse<TransactionMethod>, ErrorResponse> => {
  let queryParams = '?status=ACTIVE&';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.transactionMethods}${queryParams}`], {
    enabled: enabled,
    keepPreviousData: false,
  });
};
export default useTransactionMethods;
