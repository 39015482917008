import { Alert, AlertProps, Text, TextProps } from '@chakra-ui/react';

type LDTransactionMessageProps = {
  title: string;
  textProps: TextProps;
} & AlertProps;

const LDTransactionMessage: React.FC<LDTransactionMessageProps> = ({
  title,
  textProps,
  ...props
}: LDTransactionMessageProps) => {
  return (
    <Alert {...props}>
      <Text {...textProps} w="100%" textAlign="center">
        {title}
      </Text>
    </Alert>
  );
};
export default LDTransactionMessage;
