import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useCreateBankAccount } from 'hooks';
import { useTranslation } from 'localization';
import { AccountFields } from 'types';

import { BankAccountForm } from './components';

type PayloadType = Record<string, string | number> & { title: string };

type AddBankAccountType = {
  wallet: string;
  transactionMethod: string;
  onClose: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  accountFields: AccountFields[];
};

const AddBankAccount: React.FC<AddBankAccountType> = ({
  wallet,
  onClose,
  onSuccess,
  isOpen,
  transactionMethod,
  accountFields,
}: AddBankAccountType) => {
  const { t } = useTranslation('component.deposit.initModal');

  const { mutateAsync: submit, isLoading: submitLoading } =
    useCreateBankAccount();

  const onSubmit = async (data: PayloadType) => {
    submit({
      wallet,
      transaction_method: transactionMethod,
      title: data.title,
      fields: data,
    }).then(() => {
      onSuccess();
      onClose();
    });
  };

  return (
    <Box>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              {t('modalTitle')}
            </Text>
          </ModalHeader>
          <ModalBody px={4} overflowY="auto">
            <BankAccountForm
              onSubmit={onSubmit}
              loading={submitLoading}
              accountFields={accountFields}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddBankAccount;
