import { useForm } from 'react-hook-form';
import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = { code: string };
/* eslint-disable no-unused-vars */
type FormType = {
  onButton: (data: PayloadType) => void;
  counter: number;
  resendCode: () => void;
};
const Form: React.FC<FormType> = ({
  onButton,
  counter,
  resendCode,
}: FormType) => {
  const { t } = useTranslation('component.auth.verification.form');

  const schema = yup.object().shape({
    code: yup.string().length(6).label('code').required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = async (data: PayloadType) => {
    onButton(data);
  };

  const formProps = useInputAttributes(register, errors, {
    code: t('code'),
  });

  const disabled = counter !== 0;

  return (
    <Box
      px={{ base: 2, sm: 0 }}
      w={{ base: '100%', sm: 312 }}
      mt={{ base: 2, md: 20 }}
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      {disabled && (
        <Alert status="info" mb={4} w={{ base: '100%', md: '550px' }}>
          <AlertIcon />
          <Text color="info">{t('codeSent')}</Text>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" gap={4}>
          <Box width="100%">
            <Input
              {...formProps.code}
              textAlign="center"
              // TODO : fix placeholder
              placeholder="     -     -     -     -     -     -     "
            />
          </Box>
          <Button
            variant="filled"
            display="block"
            minW={81}
            title={disabled ? counter.toString() : t('resend')}
            isDisabled={disabled}
            mt={{ base: 8, md: 8 }}
            onClick={() => resendCode()}
          />
        </Box>
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={t('submit')}
          minW="100%"
          mt={{ base: 4, md: 4 }}
        />
      </form>
    </Box>
  );
};

export default Form;
