import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { Currency, ErrorResponse, ListResponse } from 'types';

const useCurrencies = ({
  queries,
  enabled,
}: {
  queries: {
    type?: Currency['type'];
    countries?: string;
  };
  enabled?: boolean;
}): UseQueryResult<ListResponse<Currency>, ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.currencies}${queryParams}`], {
    enabled,
  });
};
export default useCurrencies;
