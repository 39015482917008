import React from 'react';
import { Box } from '@chakra-ui/react';
import { useForgotPassword, useTimer } from 'hooks';
import { useTranslation } from 'localization';

import { AuthSuggestion, DesktopAuthHeader } from 'components';

import { Form } from './components';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('component.auth.forgotPassword');
  const { counter, setCounter } = useTimer(0);

  const { mutateAsync: submit } = useForgotPassword();

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <DesktopAuthHeader title={t('reset')} />
      <Form
        onButton={(data) =>
          submit(data).then(() => {
            setCounter(120);
          })
        }
        counter={counter}
      />
      <AuthSuggestion include={['sign-up']} />
    </Box>
  );
};

export default ForgotPassword;
