import React from 'react';
import { Box } from '@chakra-ui/react';
import { useGetSignUpServiceEmail, useSignUpEmail, useTimer } from 'hooks';
import { useTranslation } from 'localization';

import { AuthSuggestion, DesktopAuthHeader } from 'components';

import { Form } from './components';

const SignUp: React.FC = () => {
  const { t } = useTranslation('component.auth.signUp');
  const { counter, setCounter } = useTimer(0);

  const { mutateAsync: submit } = useSignUpEmail();

  const externalUserData = useGetSignUpServiceEmail();

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <DesktopAuthHeader
        title={t('signUpToMagix')}
        subTitle={t('openAccount')}
      />
      <Form
        onButton={(data) =>
          submit(data).then(() => {
            setCounter(120);
          })
        }
        isDisableForm={counter !== 0}
        counter={counter}
        defaultEmail={externalUserData.client_email}
      />
      <AuthSuggestion include={['sign-in']} />
    </Box>
  );
};

export default SignUp;
