import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  IconButton,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as AddIcon } from 'assets/icons/addNewAccount.svg';
import {
  useCryptoWhiteList,
  useDebounce,
  useFee,
  useFeeCalculation,
  useTransactionMethods,
  useWithdrawCrypto,
  useWithdrawSendOtp,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { routes } from 'statics';
import { Wallet } from 'types';

import {
  BankTransferHint,
  Button,
  Input,
  PercentSelect,
  Spinner,
} from 'components';
import AddCryptoWhitelist from 'components/core/AddCryptoWhitelist';
import { fixDecimal, yup } from 'utils';

import CurrentBalance from '../CurrentBalance';
import DestinationAmount from '../DestinationAmount';
import { ApproveCryptoModal } from '..';

type CryptoTabProps = { wallet?: Wallet };

const CryptoTab: React.FC<CryptoTabProps> = ({ wallet }) => {
  const { t } = useTranslation('component.withdraw.crypto');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchparams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const availableAmount = (wallet?.amount || 0) - (wallet?.frozen_amount || 0);

  const {
    isOpen: isOpenApprove,
    onOpen: openApprove,
    onClose: closeApprove,
  } = useDisclosure();

  const [activeWithdrawAccount, setActiveWithdrawAccount] =
    React.useState<string>('');

  const { mutateAsync: submit, isLoading: submitLoading } = useWithdrawCrypto();
  const { mutateAsync: requestOtp } = useWithdrawSendOtp();

  // Manage form in 5 code block
  const schema = yup.object().shape({
    transaction_method: yup
      .string()
      .label(t('form.transaction_method'))
      .required(),
    crypto_withdrawal_account: yup
      .string()
      .label(t('form.crypto_withdrawal_account'))
      .required(),
    note: yup.string().label(t('form.note')),
    amount: yup
      .number()
      // TODO: circular dependency
      // .min(fee?.minimum_credit || 0)
      // .max(fee?.maximum_credit || 100000)
      .label(t('form.amount'))
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async () => {
    requestOtp([]).then(() => {
      openApprove();
    });
  };

  const sendOtpHandler = async (code: number) => {
    submit({
      ...getValues(),
      amount: parseFloat(getValues('amount').toString()),
      code: code.toString(),
      source_wallet: wallet?.id || '',
    }).then(() => {
      closeApprove();
      reset();
      navigate(
        `${routes.wallets.url}?tab=transaction&wallet=${wallet?.currency.id}`
      );
    });
  };

  const formProps = useInputAttributes(register, errors, {
    transaction_method: t('form.transaction_method'),
    crypto_withdrawal_account: t('form.crypto_withdrawal_account'),
    note: t('form.note'),
    amount: t('form.amount'),
  });

  // Manage TR methods in 4 code block
  const {
    data: transactionMethods,
    isSuccess: isSuccessTrMethods,
    isFetching: isRefetchingTrMethods,
    isLoading: isLoadingTrMethods,
    isRefetching: isFetchingTrMethods,
  } = useTransactionMethods({
    queries: wallet
      ? {
          allowed_currencies: wallet.currency.id || '',
          category: 'WITHDRAW_CRYPTO',
        }
      : {},
    enabled: Boolean(wallet),
  });

  React.useEffect(() => {
    if (isSuccessTrMethods && transactionMethodList.length > 0) {
      setValue('transaction_method', transactionMethodList[0].value);
    }
    // handles When we need to get fee
    setSearchParams(searchparams);
  }, [isSuccessTrMethods, isRefetchingTrMethods]);

  const transactionMethodList =
    transactionMethods?.list?.map((transactionMethod) => ({
      label: transactionMethod.title,
      value: transactionMethod.id,
      key: transactionMethod.key,
    })) || [];

  // Set active transaction method
  const trMethod =
    transactionMethods?.list.find(
      (item) => item.id === watch('transaction_method')
    ) ||
    transactionMethods?.list[0] ||
    null;

  // Manage fee in a code block
  const {
    data: fee,
    isLoading: isLoadingFee,
    isFetching: isFetchingFee,
  } = useFee({
    queries: {
      currency: wallet?.currency.id || '',
      transaction_method: trMethod?.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  // Manage whitelist in 2 code block
  const { data: cryptoWhiteList, refetch: refetchCryptoWhiteList } =
    useCryptoWhiteList({
      queries: trMethod ? { wallet: wallet?.id || '' } : {},
      enabled: Boolean(trMethod),
    });

  const cryptoWhiteListList =
    cryptoWhiteList?.list?.map((bankAcc) => ({
      label: bankAcc.title,
      value: bankAcc.id,
    })) || [];

  const selectedWithdrawAccount = cryptoWhiteList?.list.find(
    (item) => item.id === activeWithdrawAccount
  );

  // Manage fee in 3 code block
  const {
    data: calculatedFee,
    mutateAsync: getCalculatedFee,
    isLoading: calculatedFeeLoading,
  } = useFeeCalculation();

  const debounceResult = useDebounce(
    parseFloat((watch('amount') || 0).toString()),
    500
  );

  React.useEffect(() => {
    if (
      fee &&
      fee.id &&
      debounceResult &&
      wallet &&
      availableAmount >= debounceResult
    ) {
      getCalculatedFee({
        data: { amount: debounceResult as number },
        id: fee.id,
      });
    }
  }, [fee, debounceResult]);

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          p={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.transaction_method}
              isSelectBox
              options={transactionMethodList}
              isDisabled={!wallet?.id}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDir={{ base: 'column-reverse', md: 'row' }}
          alignItems="center"
          w="100%"
          pb={8}
          px={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }} pos="relative">
            <Input
              {...formProps.amount}
              bgColor="lightPrimary"
              border="none"
              borderRadius={5}
              py={10}
              px={10}
              type="number"
              min="0"
              step="0.00001"
              fontSize="2xl"
              fontWeight="500"
              _placeholder={{ color: 'grey' }}
            />
            {wallet && (
              <Text pos="absolute" top={10} right={2} fontWeight="bold">
                {wallet?.currency.symbol}
              </Text>
            )}
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              w="100%"
            >
              <PercentSelect
                list={[25, 50, 75, 100]}
                onPercent={(item) =>
                  wallet
                    ? setValue(
                        'amount',
                        parseFloat(
                          fixDecimal({
                            value: (availableAmount * item) / 100,
                            fractionDigits: 5,
                          })
                        )
                      )
                    : ''
                }
              />
            </Box>
          </Box>
          {wallet && (
            <CurrentBalance
              amount={availableAmount}
              symbol={wallet?.currency.symbol}
              type={wallet?.type}
            />
          )}
        </Box>
        {isLoadingFee && isFetchingFee && (
          <Stack w="100%" align="center" justify="center" py={10}>
            <Spinner size="md" />
          </Stack>
        )}
        {fee && wallet && (
          <BankTransferHint
            maximum_credit={fee.maximum_credit}
            minimum_credit={fee.minimum_credit}
            maximum={fee.maximum}
            minimum={fee.minimum}
            percent={fee.percent}
            symbol={wallet.currency.symbol}
            type="withdraw-crypto"
          />
        )}
        <Box display="flex" alignItems="flex-end" w="100%" p={8} gap={25}>
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.crypto_withdrawal_account}
              isSelectBox
              options={cryptoWhiteListList}
              onChange={(e) => {
                setActiveWithdrawAccount(e.target.value);
              }}
            />
          </Box>
          {isLoadingTrMethods && isFetchingTrMethods && (
            <Stack w="100%" align="center" justify="center">
              <Spinner size="md" />
            </Stack>
          )}
          {wallet && trMethod && (
            <Box w={{ base: 'max-content', md: '50%' }} mb={4}>
              <IconButton
                size="lg"
                aria-label="Search database"
                icon={<AddIcon cursor="pointer" onClick={() => onOpen()} />}
              />
            </Box>
          )}
        </Box>
        {selectedWithdrawAccount && (
          <Box
            w="100%"
            px={8}
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
          >
            <Text pr={4}>Selected Wallet :</Text>
            <Text color="primary">
              {selectedWithdrawAccount.wallet_address.slice(0, 4) +
                '********************' +
                selectedWithdrawAccount.wallet_address.slice(
                  selectedWithdrawAccount.wallet_address.length - 5,
                  selectedWithdrawAccount.wallet_address.length
                )}
            </Text>
          </Box>
        )}
        <Box display="flex" alignItems="flex-end" w="100%" p={8} gap={25}>
          <Box w={{ base: '100%', md: '50%' }}>
            <Input {...formProps.note} isTextArea maxH={200} />
          </Box>
        </Box>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          {wallet && parseFloat((watch('amount') || 0).toString()) > 0 && (
            <DestinationAmount
              amount={watch('amount')}
              symbol={wallet?.currency.symbol}
              fee={calculatedFee?.fee_amount}
              loading={calculatedFeeLoading}
              isCrypto
              isInsufficient={debounceResult > availableAmount}
            />
          )}
          <Button
            variant="filled"
            display="block"
            title={t('proceed')}
            type="submit"
            minW="100%"
            isDisabled={wallet && debounceResult > availableAmount}
            mt={{ base: 8, md: 8 }}
          />
        </VStack>
      </form>
      <AddCryptoWhitelist
        wallet={wallet?.id || ''}
        transaction_method={{
          id: trMethod?.id || '',
          key: trMethod?.key || '',
        }}
        onClose={onClose}
        isOpen={isOpen}
        onSuccess={() => refetchCryptoWhiteList()}
      />
      <ApproveCryptoModal
        data={{
          id: trMethod?.id || '',
          symbol: wallet?.currency.symbol || '',
          fee: calculatedFee?.fee_amount || 0,
          amount: watch('amount'),
          destination_amount: parseFloat(
            fixDecimal({
              value: (watch('amount') || 0) - (calculatedFee?.fee_amount || 0),
              fractionDigits: 5,
            })
          ),
        }}
        onButton={(code) => sendOtpHandler(code)}
        loading={submitLoading}
        onClose={closeApprove}
        isOpen={isOpenApprove}
      />
    </Box>
  );
};

export default CryptoTab;
