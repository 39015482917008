import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAccess } from 'hooks';

import { BankAccounts, Whitelist } from './components';

const FinanceTab: React.FC = () => {
  const access = useAccess();

  return (
    <Box
      w="100%"
      bgColor="dark"
      display="flex"
      flexDir={{ base: 'column', xl: 'row' }}
      alignItems="flex-start"
      justifyContent="space-between"
      my={8}
      p={4}
      gap={4}
    >
      {access.whiteListCryptoWithdrawalService && <Whitelist />}
      {access.bankAccountListPermission && <BankAccounts />}
    </Box>
  );
};

export default FinanceTab;
