import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { useProfile } from './queries';
import useLocalStorage from './useLocalStorage';

const usePrivate = ({
  redirect,
}: {
  // eslint-disable-next-line no-unused-vars
  redirect: (location: Location, navigate: NavigateFunction) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getItem } = useLocalStorage();
  const token = getItem('magix-token');

  const [fetchProfile, setFetchProfile] = React.useState<boolean>(false);

  const profileQuery = useProfile(fetchProfile);

  const redirectCall = () => redirect(location, navigate);

  React.useEffect(() => {
    if (!token && !location.pathname.includes('auth')) {
      redirectCall();
    }

    if (token) {
      setFetchProfile(true);
    } else {
      redirectCall();
    }
  }, []);

  return profileQuery;
};

export default usePrivate;
