import { HStack, VStack } from '@chakra-ui/react';
import { useRejectProposal, useSettleProposal } from 'hooks';
import { useTranslation } from 'localization';
import { Proposal } from 'types';

import { Button } from 'components';

type ApprovedProps = {
  proposalId: string;
  refetch: () => void;
  paymentMethod: Proposal['payment_method'];
};

const Approved: React.FC<ApprovedProps> = ({
  proposalId,
  refetch,
}: ApprovedProps) => {
  const { t } = useTranslation('component.requests.proposals.approvementForm');

  const { mutateAsync: reject, isLoading: rejectLoading } = useRejectProposal();
  const { mutateAsync: settle, isLoading: settleLoading } = useSettleProposal();

  return (
    <VStack w="100%">
      <HStack w="100%" justify="space-between" align="center" py={2}>
        <Button
          w="100%"
          title={t('approve')}
          onClick={() =>
            settle({ id: proposalId }).then(() => {
              refetch();
            })
          }
          isLoading={rejectLoading || settleLoading}
        />
        <Button
          w="100%"
          title={t('reject')}
          variant="ghost"
          onClick={() =>
            reject({ id: proposalId }).then(() => {
              refetch();
            })
          }
          isLoading={rejectLoading || settleLoading}
        />
      </HStack>
    </VStack>
  );
};

export default Approved;
