/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

const mixed = {
  default: '${label} is not valid',
  required: '${label} is required',
  oneOf: '${label} must be one of: ${values}',
  notOneOf: '${label} must not be one of: ${values}',
  notType: '${label} must be integer',
};
const string = {
  length: '${label} must be ${length} character',
  min: '${label} must be minimum ${min} character',
  max: '${label} must be maximum ${max} character',
  matches: '${label} must be matched with: "${regex}"',
  email: '${label} must be valid email address',
  url: '${label} must be a valid URL',
  uuid: '${label} must be a valid UUID',
  trim: 'must not be space in start or end of ${label}',
  lowercase: '${label} must be small letters',
  uppercase: '${label} must be capital letters',
};

const number = {
  min: '${label} must be minimum ${min} number',
  max: '${label} must be maximum ${max} number',
  lessThan: '${label} must be less than ${less}',
  moreThan: '${label} must be more than  ${more}',
  notEqual: '${label} cant be ${notEqual}',
  positive: '${label} must be positive',
  negative: '${label} must be negative',
  integer: '${label} must be number',
};

const date = {
  min: '${label} must be before ${min}',
  max: '${label} must be after ${max}',
};

const boolean = {};

const object = {
  noUnknown: '${label} have unknown value: ${unknown}',
};

const array = {
  min: '${label} must be minimum ${min}',
  max: '${label} must be maximum ${max}',
};

const translations = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};

yup.setLocale(translations);

export default yup;
