import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddWhiteListOtp, useTimer } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { TransactionMethod } from 'types';

import { Button, Input } from 'components';
import { yup } from 'utils';
import { walletERC20Regex, walletTRC20Regex } from 'utils/regex';

type PayloadType = {
  title?: string;
  wallet_address: string;
};

type AddCryptoType = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: PayloadType) => Promise<void>;
  trMethodKey: TransactionMethod['key'];
};

const AddCrypto: React.FC<AddCryptoType> = ({
  onSubmit,
  trMethodKey,
}: AddCryptoType) => {
  const { t } = useTranslation('component.withdraw.crypto.initModal');

  const [codeSent, setCondSent] = React.useState<boolean>(false);

  const { counter, setCounter } = useTimer(0);

  const trMethodRegex: Record<string, RegExp> = {
    'WITHDRAW_CRYPTO:TRC20': walletTRC20Regex,
    'WITHDRAW_CRYPTO:ERC20': walletERC20Regex,
  };

  const formFields = {
    yup: {
      title: yup.string().label(t('form.title')),
      wallet_address: yup
        .string()
        .matches(trMethodRegex[trMethodKey], t('notValidAddress'))
        .label(t('form.wallet_address'))
        .required(),
      code: yup.string().label(t('form.code')).required(),
    },
    attributes: {
      title: t('form.title'),
      wallet_address: t('form.wallet_address'),
      code: t('form.code'),
    },
  };
  const schema = yup.object().shape(formFields.yup);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, formFields.attributes);

  const { mutateAsync: requestToGetOtp, isLoading } = useAddWhiteListOtp();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="100%" align="flex-start" p={8}>
        <Box w="100%">
          <Input {...formProps.title} />
        </Box>
        <Box w="100%">
          <Input {...formProps.wallet_address} />
        </Box>
        <Box
          w="100%"
          display="flex"
          flexDir={{ base: 'column', lg: 'row' }}
          gap={4}
        >
          <Input
            {...formProps.code}
            type="number"
            min="0"
            placeholder="   -   -   -   -   -   -   "
            textAlign="center"
          />
          <Button
            variant="filled"
            display="block"
            title={
              codeSent
                ? counter > 0
                  ? '( ' + counter + ' )'
                  : t('resendCode')
                : t('sendCode')
            }
            isDisabled={counter > 0}
            minW="max-content"
            isLoading={isLoading}
            onClick={() =>
              requestToGetOtp([]).then(() => {
                setCondSent(true);
                setCounter(120);
              })
            }
            mt={8}
          />
        </Box>
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={t('proceed')}
          minW="100%"
          isLoading={isLoading}
          mt={8}
        />
      </VStack>
    </form>
  );
};
export default AddCrypto;
