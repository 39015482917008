import { Link } from 'react-router-dom';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Deposit } from 'assets/icons/deposit.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw.svg';
import { useAccess, useBalance } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Spinner } from 'components';
import { fixDecimal } from 'utils';

const Head: React.FC = () => {
  const { data: balance, isLoading, refetch } = useBalance();

  const { t } = useTranslation('component.wallet');
  const access = useAccess();

  return (
    <Box p={{ base: 4, md: 0 }}>
      <Box
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between' }}
        alignItems="center"
        py={{ base: 2, md: 0 }}
      >
        <VStack align="flex-start" py={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <HStack>
              <Text fontSize={'4xl'} fontWeight="500">
                {'$ ' + fixDecimal({ value: balance?.balance || 0 })}
              </Text>
            </HStack>
          )}
          <Text
            fontSize={'md'}
            fontWeight="400"
            display={{ base: 'none', md: 'inline' }}
          >
            {t('totalBalance')}
          </Text>
        </VStack>
        <HStack>
          {access.withdrawService && (
            <Link to={routes.withdraw.url}>
              <Withdraw cursor="pointer" />
            </Link>
          )}
          <Link to={routes.deposit.url}>
            <Deposit cursor="pointer" />
          </Link>
          <Refresh onClick={() => refetch()} cursor="pointer" />
        </HStack>
      </Box>
    </Box>
  );
};

export default Head;
