import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Verification, VerificationResponse } from 'types';

import { api } from 'utils';

const useVerification = (
  token: string
): UseMutationResult<VerificationResponse, ErrorResponse, Verification> => {
  return useMutation((data) =>
    api.post(url.verification, data, { headers: { 'csrf-token': token } })
  );
};
export default useVerification;
