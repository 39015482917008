import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ReactComponent as ExchangeMagix } from 'assets/icons/exchange_magix.svg';
import { ReactComponent as ExchangeMagixLogo } from 'assets/icons/exchange_magix_logo.svg';
import { useMQ, usePublic } from 'hooks';

const Unauthenticated: React.FC = () => {
  const [isSmallScreen] = useMQ();
  usePublic();

  return (
    <Box>
      <Box
        py={{ base: 12, md: 8 }}
        px={12}
        display="flex"
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        {isSmallScreen ? (
          <ExchangeMagixLogo
            cursor="pointer"
            onClick={() => window.location.replace('/')}
          />
        ) : (
          <ExchangeMagix
            cursor="pointer"
            onClick={() => window.location.replace('/')}
          />
        )}
      </Box>
      <Outlet />
    </Box>
  );
};

export default Unauthenticated;
