import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLDWithdrawConfirm, useLDWithdrawResendOtp, useTimer } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = {
  code: string;
};

type CompleteLdWithdrawProps = {
  transactionId: string;
  refetch: () => void;
};

const CompleteLdWithdraw: React.FC<CompleteLdWithdrawProps> = ({
  transactionId,
  refetch,
}) => {
  const { t } = useTranslation('component.ldRequests.transactions.settleForm');

  const schema = yup.object().shape({
    code: yup.string().label(t('code')).required(),
  });

  const { mutateAsync: settle, isLoading: settleLoading } =
    useLDWithdrawConfirm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    settle({ id: transactionId, data }).then(() => {
      reset();
      setValue('code', '');
      refetch();
    });
  };
  const { mutateAsync: resend, isLoading: resendLoading } =
    useLDWithdrawResendOtp();

  const formProps = useInputAttributes(register, errors, {
    code: t('code'),
  });

  const { counter, setCounter } = useTimer(0);

  return (
    <Box w="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack flexDir="column">
          <Input
            type="number"
            min={0}
            step={1}
            placeholder="   -   -   -   -   -   "
            textAlign="center"
            {...formProps.code}
          />
        </Stack>
        <Stack flexDir="row" align="center">
          <Button
            type="submit"
            title={t('settle')}
            isLoading={settleLoading || resendLoading}
            width="50%"
          />
          <Button
            title={counter > 0 ? counter.toString() : t('resend')}
            isDisabled={counter > 0}
            width="50%"
            variant="ghost"
            onClick={() => {
              resend({ transaction: transactionId }).then(() => {
                setCounter(120);
              });
            }}
            isLoading={settleLoading || resendLoading}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default CompleteLdWithdraw;
