import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateBankAccount, ErrorResponse } from 'types';

import { api } from 'utils';

const useCreateBankAccount = (): UseMutationResult<
  [],
  ErrorResponse,
  CreateBankAccount
> => {
  return useMutation((data) => api.post(url.createBankAccount, data));
};

export default useCreateBankAccount;
