import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateCryptoWhitelist, ErrorResponse } from 'types';

import { api } from 'utils';

const useCreateCryptoWhitelist = (): UseMutationResult<
  [],
  ErrorResponse,
  CreateCryptoWhitelist
> => {
  return useMutation((data) => api.post(url.createCryptoWhitelist, data));
};

export default useCreateCryptoWhitelist;
