import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import type { ErrorResponse, ListResponse, Pair, PairsPayload } from 'types';

const usePairs = ({
  enabled,
  query,
}: {
  enabled: boolean;
  query?: PairsPayload | undefined;
}): UseQueryResult<ListResponse<Pair>, ErrorResponse> => {
  const q =
    Boolean(query?.first_currency) && Boolean(query?.second_currency)
      ? `?first_currency=${query?.first_currency}&second_currency=${query?.second_currency}`
      : '';
  return useQuery([`${url.pairs}${q}`], {
    enabled,
  });
};
export default usePairs;
