import { Box } from '@chakra-ui/react';
import { ReactComponent as Deposit } from 'assets/icons/deposit.svg';
import { ReactComponent as SWAP } from 'assets/icons/exchange.svg';
import { ReactComponent as Exchange } from 'assets/icons/transfer-outline.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw.svg';
import { Transaction } from 'types';

const TransactionMethod: React.FC<{
  type: Transaction['transaction_method']['type'];
  isIntermediary: boolean;
}> = ({
  type,
  isIntermediary,
}: {
  type: Transaction['transaction_method']['type'];
  isIntermediary: boolean;
}) => {
  if (type === 'DEPOSIT') {
    if (isIntermediary) {
      return <Withdraw />;
    }
    return <Deposit />;
  }
  if (type === 'WITHDRAW') {
    if (isIntermediary) {
      return <Deposit />;
    }
    return <Withdraw />;
  }
  if (type === 'SWAP') {
    return <SWAP />;
  }
  return (
    <Box p={2}>
      <Exchange />
    </Box>
  );
};
export default TransactionMethod;
