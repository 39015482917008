import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Box, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProposalBankAccount, useProposalCashPayment } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { LDDefaultBankAccount, Proposal } from 'types';

import { Button, Input, LDTransactionMessage } from 'components';
import { yup } from 'utils';

type PayloadType = {
  account_id: string;
  bank_name: string;
  holder_name: string;
  IBAN?: string;
  description?: string;
};
type DepositSettledProps = {
  proposalId: string;
  paymentMethod: Proposal['payment_method'];
  refetch: () => void;
  defaultBankTransfer: LDDefaultBankAccount;
};

const DepositSettled: React.FC<DepositSettledProps> = ({
  proposalId,
  paymentMethod,
  refetch,
  defaultBankTransfer,
}: DepositSettledProps) => {
  const { t } = useTranslation('component.requests.settlementForm');

  const { mutateAsync: complete, isLoading: completeLoading } =
    useProposalCashPayment();

  if (paymentMethod === 'CASH') {
    return (
      <Stack w="100%">
        <LDTransactionMessage
          title={t('confirmAddress')}
          status="info"
          textProps={{ color: 'info', textAlign: 'center' }}
        />
        <Button
          w="100%"
          title={t('complete')}
          onClick={() =>
            complete({ id: proposalId }).then(() => {
              refetch();
            })
          }
          isLoading={completeLoading}
        />
      </Stack>
    );
  }

  const schema = yup.object().shape({
    account_id: yup.string().label(t('account_id')).required(),
    bank_name: yup.string().label(t('bank_name')).required(),
    holder_name: yup.string().label(t('holder_name')).required(),
    IBAN: yup.string().label(t('IBAN')),
    description: yup.string().label(t('description')),
  });

  const { mutateAsync: submit, isLoading: submitLoading } =
    useProposalBankAccount();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    submit({ id: proposalId, data }).then((res) => {
      if (res.id) {
        reset();
        refetch();
      }
    });
  };

  const formProps = useInputAttributes(register, errors, {
    IBAN: t('IBAN'),
    bank_name: t('bank_name'),
    holder_name: t('holder_name'),
    account_id: t('account_id'),
    description: t('description'),
  });

  return (
    <Stack w="100%">
      {defaultBankTransfer && (
        <Stack flexDir="column">
          <Stack
            borderRadius={5}
            border="1px dashed"
            borderColor="darkPrimary"
            p={4}
          >
            <Text fontWeight="bold">{t('defaultBankAccount')}</Text>
            <Stack
              flexDir={{ base: 'column', md: 'row' }}
              justify="space-between"
              align="center"
            >
              <Text color="gray">{t('bank_name')}</Text>
              <Text>{defaultBankTransfer?.bank_name}</Text>
            </Stack>
            <Stack
              flexDir={{ base: 'column', md: 'row' }}
              justify="space-between"
              align="center"
            >
              <Text color="gray">{t('holder_name')}</Text>
              <Text>{defaultBankTransfer?.holder_name}</Text>
            </Stack>
            <Stack
              flexDir={{ base: 'column', md: 'row' }}
              justify="space-between"
              align="center"
            >
              <Text color="gray">{t('account_id')}</Text>
              <Text>{defaultBankTransfer?.account_id}</Text>
            </Stack>
            {defaultBankTransfer?.IBAN && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                justify="space-between"
                align="center"
              >
                <Text color="gray">{t('IBAN')}</Text>
                <Text>{defaultBankTransfer?.IBAN}</Text>
              </Stack>
            )}
            {defaultBankTransfer?.description && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                justify="space-between"
                align="center"
              >
                <Text color="gray">{t('description')}</Text>
                <Text>{defaultBankTransfer?.description}</Text>
              </Stack>
            )}
            <Alert colorScheme="yellow">
              <Text color="warning" textAlign="center" w="100%">
                {t('useDefaultHint')}
              </Text>
            </Alert>
            <Button
              title={t('useDefault')}
              onClick={() => onSubmit(defaultBankTransfer)}
              isLoading={submitLoading}
            />
          </Stack>
        </Stack>
      )}
      <Stack w="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack flexDir="column">
            <Box w="100%">
              <Input {...formProps.holder_name} />
            </Box>
            <Box w="100%">
              <Input {...formProps.bank_name} />
            </Box>
            <Box w="100%">
              <Input {...formProps.account_id} />
            </Box>
            <Box w="100%">
              <Input {...formProps.IBAN} />
            </Box>
            <Box w="100%">
              <Input {...formProps.description} />
            </Box>
          </Stack>
          <Button
            type="submit"
            title={t('submit')}
            isLoading={submitLoading}
            width="100%"
            mt={6}
          />
        </form>
      </Stack>
    </Stack>
  );
};
export default DepositSettled;
