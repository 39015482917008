import {
  initReactI18next,
  useTranslation as translateFunction,
  UseTranslationResponse,
} from 'react-i18next';
import i18n, { KeyPrefix } from 'i18next';

import component from './component.json';
import layout from './layout.json';
import page from './page.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const useTranslation = (
  keyPrefix: KeyPrefix<string>
): UseTranslationResponse<'translation', undefined> => {
  return translateFunction('', { keyPrefix });
};

const resources = {
  en: {
    translation: {
      ...layout,
      ...page,
      ...component,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
