import { useMediaQuery } from '@chakra-ui/react';

const useMQ = (
  type: 'max-width' | 'min-width' = 'max-width',
  size: number = 768
) => {
  return useMediaQuery(`(${type}: ${size}px)`);
};

export default useMQ;
