import { createBrowserRouter, Navigate } from 'react-router-dom';
import {
  Dashboard,
  Deposit,
  Exchange,
  ForgotPassword,
  Login,
  NewPassword,
  NoMatch,
  Password,
  Requests,
  Settings,
  SignUp,
  Transfer,
  Verification,
  Wallets,
  Withdraw,
  XMDeposit,
} from 'pages';
import { routes } from 'statics';

import Authenticated from './Authenticated';
import Services from './Services';
import Unauthenticated from './Unauthenticated';

const router = createBrowserRouter([
  {
    path: '*',
    element: <NoMatch />,
  },
  {
    path: routes.auth.url,
    element: <Unauthenticated />,
    children: [
      {
        path: routes.auth.signUp.url,
        element: <SignUp />,
      },
      { path: routes.auth.password.url, element: <Password /> },
      { path: routes.auth.signIn.url, element: <Login /> },
      { path: routes.auth.verification.url, element: <Verification /> },
      { path: routes.auth.forgotPassword.url, element: <ForgotPassword /> },
      { path: routes.auth.newPassword.url, element: <NewPassword /> },
    ],
  },
  {
    path: '/',
    element: <Authenticated />,
    children: [
      {
        path: routes.dashboard.url,
        element: <Dashboard />,
      },
      {
        path: routes.wallets.url,
        element: <Wallets />,
      },
      {
        path: routes.transfer.url,
        element: <Transfer />,
      },
      {
        path: routes.deposit.url,
        element: <Deposit />,
      },
      {
        path: routes.withdraw.url,
        element: <Withdraw />,
      },
      {
        path: routes.exchange.url,
        element: <Exchange />,
      },
      {
        path: routes.requests.url,
        element: <Requests />,
      },
      {
        path: routes.settings.url,
        element: <Settings />,
      },
      {
        path: '/',
        element: <Navigate to={routes.dashboard.url} />,
      },
    ],
  },
  {
    path: routes.services.url,
    element: <Services />,
    children: [
      {
        path: routes.services.xm.deposit.url,
        element: <XMDeposit />,
      },
    ],
  },
]);

export default router;
