import { access } from 'utils';

import { useProfile } from './queries';

const useAccess = () => {
  const { data: profile } = useProfile();

  return access({ profile: profile });
};

export default useAccess;
