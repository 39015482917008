import { Link } from 'react-router-dom';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Deposit } from 'assets/icons/deposit.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw.svg';
import { useAccess } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

const Head: React.FC = () => {
  const { t } = useTranslation('component.requests');
  const access = useAccess();

  return (
    <Box p={{ base: 4, md: 0 }}>
      <Box
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between' }}
        alignItems="center"
        py={{ base: 2, md: 0 }}
      >
        <VStack align="flex-start" py={4}>
          <Text fontSize={'4xl'} fontWeight="500">
            {t('title')}
          </Text>
        </VStack>
        <HStack>
          {access.withdrawService && (
            <Link to={routes.withdraw.url}>
              <Withdraw cursor="pointer" />
            </Link>
          )}
          <Link to={routes.deposit.url}>
            <Deposit cursor="pointer" />
          </Link>
        </HStack>
      </Box>
    </Box>
  );
};

export default Head;
