import { Box, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';

type BankTransferHintProps = {
  maximum_credit: number;
  minimum_credit: number;
  maximum?: number;
  minimum?: number;
  percent?: number;
  symbol: string;
  type: 'withdraw-crypto' | 'withdraw-bank' | 'deposit-bank' | 'deposit-crypto';
};

const BankTransferHint: React.FC<BankTransferHintProps> = ({
  maximum_credit,
  minimum_credit,
  maximum,
  minimum,
  percent,
  symbol,
  type,
}: BankTransferHintProps) => {
  const { t } = useTranslation(`component.${type.split('-')[0]}`);

  const text = `${t('note')}${
    maximum_credit ? t('maxHint') + maximum_credit + symbol : ''
  }${maximum_credit && minimum_credit ? t('and') : ''}${
    minimum_credit ? t('minHint') + minimum_credit + symbol : ''
  }`;

  return (
    <Stack w="100%">
      <Box px={8} pb={4}>
        <Text color="body2">
          {t('wallet')}
          {symbol}
        </Text>
      </Box>
      <Box bgColor="lightPrimary" p={4} textAlign="center">
        <Text color="warning">{text}</Text>
        {type === 'deposit-bank' && (
          <Text color="warning">{t('holder_hint')}</Text>
        )}
        {Boolean(minimum) && (
          <Text color="warning">{t('minimum_fee') + minimum + symbol}</Text>
        )}
        {Boolean(percent) && (
          <Text color="warning">{t('percent') + percent + '%'}</Text>
        )}
        {Boolean(maximum) && (
          <Text color="warning">{t('maximum_fee') + maximum + symbol}</Text>
        )}
      </Box>
    </Stack>
  );
};
export default BankTransferHint;
