import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useApproveProposalByUser = (): UseMutationResult<
  [],
  ErrorResponse,
  {
    id: string;
    data: { payment_method: 'CASH' | 'BANK_TRANSFER'; address?: string };
  }
> => {
  return useMutation(({ id, data }) =>
    api.put(`${url.approveProposalByUser}/${id}/approve`, data)
  );
};
export default useApproveProposalByUser;
