import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useSettleProposal = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) => api.put(`${url.settleProposal}/${id}/settle`));
};
export default useSettleProposal;
