import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { routes } from 'statics';

type AuthSuggestionType = {
  include: ('sign-up' | 'sign-in' | 'forget-password')[];
};

const AuthSuggestion: React.FC<AuthSuggestionType> = ({
  include,
}: AuthSuggestionType) => {
  const { t } = useTranslation('component.auth.suggestion');

  return (
    <Box textAlign="center" pb={8}>
      {include.includes('forget-password') && (
        <Text fontSize="md" fontFamily="Satoshi" pt={5}>
          {t('forgotPassword')}
          <Link
            to={routes.auth.forgotPassword.url}
            style={{ display: 'inline-block' }}
          >
            <Text color="primary">{t('click')}</Text>
          </Link>
        </Text>
      )}
      {include.includes('sign-up') && (
        <Text fontSize="md" fontFamily="Satoshi" pt={5}>
          {t('doNotHaveAnAccount')}
          <Link to={routes.auth.signUp.url} style={{ display: 'inline-block' }}>
            <Text color="primary">{t('signUp')}</Text>
          </Link>
        </Text>
      )}
      {include.includes('sign-in') && (
        <Text fontSize="md" fontFamily="Satoshi" pt={5}>
          {t('haveAnAccount')}
          <Link to={routes.auth.signIn.url} style={{ display: 'inline-block' }}>
            <Text color="primary">{t('login')}</Text>
          </Link>
        </Text>
      )}
    </Box>
  );
};

export default AuthSuggestion;
