const useLocalStorage = () => {
  const setItem = (key: string, value: any) => {
    localStorage.setItem(key, value);
  };

  const getItem = (key: string): string | null => {
    return localStorage.getItem(key);
  };

  return { getItem, setItem };
};

export default useLocalStorage;
