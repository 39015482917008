import * as React from 'react';
import {
  Icon,
  IconButton as CkIconButton,
  IconButtonProps,
} from '@chakra-ui/react';

type IconButtonProptypes = {
  ariaLabel: string;
  icon: React.ElementType;
  size?: 'sm' | 'md' | 'lg';
} & Omit<IconButtonProps, 'icon' | 'aria-label' | 'size'>;

const IconButton: React.FC<IconButtonProptypes> = ({
  ariaLabel,
  icon,
  size = 'md',
  ...props
}) => {
  return (
    <CkIconButton
      size={size}
      _hover={{ bg: 'lightPrimary', borderRadius: 'lg' }}
      bg="none"
      icon={
        <Icon
          className="icon"
          as={icon}
          w={size === 'md' ? 10 : size === 'lg' ? 12 : 2.5}
          h={size === 'md' ? 10 : size === 'lg' ? 12 : 2.5}
        />
      }
      aria-label={ariaLabel}
      {...props}
    />
  );
};

export default IconButton;
