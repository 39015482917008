import React from 'react';
import { Box, Divider, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Checked } from 'assets/icons/circle-check.svg';
import { ReactComponent as NotChecked } from 'assets/icons/close.svg';

const LevelFeatures: React.FC<{
  levelManagement: {
    title: string;
    steps: {
      0: { color: string; icon: any };
      1: { color: string; icon: any };
      2: { color: string; icon: any };
      3: { color: string; icon: any };
    };
    features: { title: string; isActive: boolean; description: string }[];
  };
}> = ({ levelManagement }) => {
  return (
    <Box borderRadius={10} border="1px solid grey" p={4} mt={6} w="100%">
      <Text fontSize="3xl">{levelManagement.title}</Text>
      <Divider />
      <VStack w="100%" justify="center">
        {levelManagement.features.map((item) => {
          return (
            <Box key={item.title} w="100%" mt={1}>
              <Box
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text>{item.title}</Text>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w={5}
                >
                  {item.isActive ? <Checked /> : <NotChecked />}
                </Box>
              </Box>
              <Text textAlign="left" fontSize="xs" color="primary">
                {item.description}
              </Text>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default LevelFeatures;
