import React from 'react';

const useDebounce = (value: number, delay = 500): number => {
  const [debouncedValue, setDebouncedValue] = React.useState<number>(value);

  React.useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(
      () => {
        setDebouncedValue(value);
      },
      value ? delay : 0
    );

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
