import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, ServiceTransaction } from 'types';

const useServiceTransaction = ({
  id,
}: {
  id: string;
}): UseQueryResult<ServiceTransaction, ErrorResponse> => {
  return useQuery([`${url.serviceTransaction}/${id}`]);
};
export default useServiceTransaction;
