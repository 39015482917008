import { useForm } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApproveProposalByUser, useCancelProposalByUser } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PendingProps = {
  proposalId: string;
  addresses: { city: string; address: string }[];
  paymentMethods: ('BANK_TRANSFER' | 'CASH')[];
  refetch: () => void;
};

const Pending: React.FC<PendingProps> = ({
  proposalId,
  refetch,
  addresses,
  paymentMethods,
}: PendingProps) => {
  const { t } = useTranslation(
    'component.ldRequests.proposals.paymentMethodForm'
  );

  const { mutateAsync: approveProposal, isLoading: approveLoading } =
    useApproveProposalByUser();
  const { mutateAsync: cancelProposal, isLoading: cancelLoading } =
    useCancelProposalByUser();

  const schema = yup.object().shape({
    payment_method: yup
      .string()
      .oneOf(paymentMethods, t('error'))
      .label(t('payment_method'))
      .required(),
    city: yup
      .string()
      .label(t('city'))
      .when('payment_method', ([payment_method], schema) => {
        if (payment_method === 'CASH') {
          return schema.required();
        }
        return schema;
      }),
    address: yup
      .string()
      .label(t('address'))
      .when('payment_method', ([payment_method], schema) => {
        if (payment_method === 'CASH') {
          return schema.required();
        }
        return schema;
      }),
  });

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const formProps = useInputAttributes(register, errors, {
    payment_method: t('payment_method'),
    city: t('city'),
    address: t('address'),
  });

  const onSubmit = ({
    payment_method,
    address,
  }: {
    payment_method: 'CASH' | 'BANK_TRANSFER';
    address?: string;
  }) =>
    approveProposal({ data: { address, payment_method }, id: proposalId }).then(
      () => {
        refetch();
        reset();
      }
    );

  const cities: string[] = [...new Set(addresses.map((item) => item.city))];
  const addressList: { address: string }[] = addresses.filter(
    ({ city }) => city === watch('city')
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Stack gap={8}>
        <Stack w="100%">
          <Input
            {...formProps.payment_method}
            isSelectBox
            options={paymentMethods.map((method) => ({
              label: t(`${method.toLocaleLowerCase()}`),
              value: method,
            }))}
          />
        </Stack>
        {watch('payment_method') === 'CASH' && (
          <Stack w="100%">
            <Stack w="100%">
              <Input
                {...formProps.city}
                isSelectBox
                options={cities.map((city) => ({
                  label: city,
                  value: city,
                }))}
                onSelect={() => resetField('address')}
              />
            </Stack>
            <Stack w="100%">
              <Input
                {...formProps.address}
                isSelectBox
                options={addressList.map(({ address }) => ({
                  label: address,
                  value: address,
                }))}
              />
            </Stack>
          </Stack>
        )}
        <Stack align="center" justify="space-between" flexDir="row" gap={4}>
          <Button
            title={t('submit')}
            w="50%"
            type="submit"
            isLoading={approveLoading || cancelLoading}
          />
          <Button
            title={t('cancel')}
            w="50%"
            variant="ghost"
            onClick={() =>
              cancelProposal({ id: proposalId }).then(() => refetch())
            }
            isLoading={cancelLoading || approveLoading}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default Pending;
