import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { useCreateContact, useExternalUsersVerify } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { countryCode, yup } from 'utils';

type XMModalType = { onClose: () => void; isOpen: boolean };

const XMModal: React.FC<XMModalType> = ({ isOpen, onClose }: XMModalType) => {
  const { t } = useTranslation('component.dashboard');

  const [selectedCountry, setSelectedCountry] = React.useState<string>('');

  const closeHandler = () => onClose();
  const { mutateAsync: verify } = useExternalUsersVerify();
  const { mutateAsync: createContact, isLoading: createLoading } =
    useCreateContact();

  const schema = yup.object().shape({
    country: yup.string().label('country').required(),
    identity: yup.string().label('identity').required(),
  });

  const selectedFlag = countryCode.find(
    (item) => item.value === selectedCountry
  )?.flagSvg;

  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, {
    country: t('entity.country'),
    identity: t('entity.identity'),
  });

  const acceptHandler = () => {
    verify({ system_identifier: 'XM' }).then(() => {
      closeHandler();
    });
  };

  const onSubmit = async (data: { identity: string; country: string }) => {
    createContact({
      identity: data.country + data.identity.replace(data.country || '', ''),
      type: 'MOBILE',
    }).then(() => {
      acceptHandler();
    });
  };

  return (
    <Modal
      onClose={closeHandler}
      size={{ base: 'xl', md: 'md' }}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader shadow="2xl" display="flex" justifyContent="center">
          <WarningIcon />
        </ModalHeader>
        <ModalBody p={5} fontSize="md">
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack w="100%" py={8}>
              <Box w="100%">
                <Text w="100%" textAlign="left" pb={4}>
                  {t('XMDesc')}
                </Text>
                <Input
                  {...formProps.country}
                  isSelectBox
                  options={countryCode}
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    resetField('identity');
                  }}
                  leftValue={
                    selectedFlag ? (
                      <img
                        src={selectedFlag}
                        alt="-"
                        width="20px"
                        height="20px"
                      />
                    ) : (
                      ''
                    )
                  }
                />
              </Box>
              <Box w="100%">
                <Input {...formProps.identity} leftValue={selectedCountry} />
              </Box>
            </VStack>
            <Box>{t('XMAccess')}</Box>
            <Box
              w="100%"
              display="flex"
              justifyContent="flex-end"
              gap={4}
              mt={10}
            >
              <Button
                variant="filled"
                title="Submit"
                type="submit"
                isLoading={createLoading}
              />
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default XMModal;
