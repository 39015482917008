import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useGetSignUpServiceEmail = () => {
  const [searchParams] = useSearchParams();

  const [externalUserData, setExternalUserData] = React.useState<{
    client_email: string | undefined;
    system_identifier: string | undefined;
  }>({
    client_email: undefined,
    system_identifier: undefined,
  });

  React.useEffect(() => {
    if (
      searchParams.get('client_email') &&
      searchParams.get('system_identifier')
    ) {
      setExternalUserData({
        client_email: searchParams.get('client_email') || undefined,
        system_identifier: searchParams.get('system_identifier') || undefined,
      });
    }
  }, []);

  return externalUserData;
};

export default useGetSignUpServiceEmail;
