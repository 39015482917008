import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ReactComponent as ExchangeMagixLogo } from 'assets/icons/exchange_magix_logo.svg';
import { routes } from 'statics';

const NoMatch: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxH="100vh"
      minH="100vh"
      onClick={() => navigate(routes.dashboard.url)}
    >
      <ExchangeMagixLogo />
    </Box>
  );
};
export default NoMatch;
