import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Wallet } from 'types';

const useWallet = ({
  id,
}: {
  id: string | null;
}): UseQueryResult<Wallet, ErrorResponse> => {
  return useQuery([url.wallet, id], { enabled: Boolean(id) });
};
export default useWallet;
