import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useLDWithdrawResendOtp = (): UseMutationResult<
  [] | any,
  ErrorResponse,
  { transaction: string }
> => {
  return useMutation((data) => api.post(url.ldWithdrawResendOtp, data));
};

export default useLDWithdrawResendOtp;
