import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactComponent as Error } from 'assets/icons/errorIcon.svg';
import dayjs from 'dayjs';
import { useDeleteSession, useSessions } from 'hooks';
import { useTranslation } from 'localization';
import { Session } from 'types';

import { Button } from 'components';

const Sessions: React.FC = () => {
  const { t } = useTranslation('component.settings.security');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sessionId, setSessionId] = React.useState<string>('');

  const { data: sessions, refetch: refreshSessions } = useSessions();
  const { mutateAsync: deleteSession } = useDeleteSession();

  const extractBrowserName = (userAgent: string) => {
    const match = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera)\/[\d.]+/);
    return match ? match[1] : 'Unknown';
  };

  const closeHandler = () => {
    onClose();
    setSessionId('');
    refreshSessions();
  };

  const columnHelper = createColumnHelper<Session>();

  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => extractBrowserName(info.getValue()),
      enableHiding: false,
    }),
    columnHelper.accessor('user_agent', {
      header: () => 'User agent',
      cell: (info) => extractBrowserName(info.getValue()),
    }),
    columnHelper.accessor('user_agent', {
      header: () => 'System',
      cell: (info) => info.getValue().split('(')[1].split(';')[0],
    }),
    columnHelper.accessor('ip', {
      header: () => 'IP',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('updated_at', {
      header: () => 'Update at',
      cell: (info) =>
        `${dayjs(info.getValue()).format('HH:mm')} | ${dayjs(
          info.getValue()
        ).format('YYYY-MM-DD')}`,
    }),
  ];

  const table = useReactTable({
    data: sessions?.list || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box w="100%" bgColor="dark" my={8} p={4} gap={4}>
      <Text fontSize={{ base: 'md', lg: '2xl' }} fontWeight="bold">
        {t('deviceManagement')}
      </Text>
      <Box w="100%" overflowX="auto">
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td fontSize="sm" key={cell.id}>
                      <Stack flexDir="row" align="center">
                        <Text w="max-content">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Text>
                        {cell.column.id === 'updated_at' && (
                          <Box
                            cursor="pointer"
                            onClick={() => {
                              setSessionId(row.getValue('id'));
                              onOpen();
                            }}
                            marginLeft="auto"
                          >
                            <Error />
                          </Box>
                        )}
                      </Stack>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Modal onClose={() => closeHandler} size="md" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalBody p={5} overflowY="auto">
            <Box w="100%" h={40}>
              <Text fontSize="2xl">Are you want to close this session ?</Text>
              {sessions?.current_session_id === sessionId && (
                <Text fontSize="md" color="warning">
                  If you close this session to logged out
                </Text>
              )}
            </Box>
            <Box w="100%" textAlign="right">
              <Button
                variant="ghost"
                onClick={() => onClose()}
                title={t('cancel')}
                mx={2}
              />
              <Button
                onClick={() =>
                  deleteSession({ id: sessionId }).then(() => {
                    closeHandler();
                  })
                }
                variant="filled"
                title={t('closeSession')}
                mx={2}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Sessions;
