import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input, PasswordStrength } from 'components';
import { passwordRegex, yup } from 'utils';

type PayloadType = { password: string };
/* eslint-disable no-unused-vars */
type FormType = {
  onButton: (data: PayloadType) => void;
  loading: boolean;
};
const Form: React.FC<FormType> = ({ onButton, loading }: FormType) => {
  const { t } = useTranslation('component.auth.newPassword.form');

  const schema = yup.object().shape({
    password: yup.string().label('password').matches(passwordRegex).required(),
    confirm_password: yup
      .string()
      .label('confirm_password')
      .oneOf([yup.ref('password')], t('same_password'))
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = async (data: PayloadType & { confirm_password: string }) => {
    onButton({ password: data.password });
  };

  const formProps = useInputAttributes(
    register,
    errors,
    {
      password: t('password'),
      confirm_password: t('confirm_password'),
    },
    ['password']
  );

  return (
    <Box
      px={{ base: 2, sm: 0 }}
      w={{ base: '100%', sm: 312 }}
      mt={{ base: 2, md: 20 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={6}>
          <Input type="password" {...formProps.password} />
        </Box>
        <Box>
          <Input type="password" {...formProps.confirm_password} />
        </Box>
        <PasswordStrength password={watch('password')} />
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={t('submit')}
          isLoading={loading}
          minW="100%"
          mt={{ base: 8, md: 8 }}
        />
      </form>
    </Box>
  );
};

export default Form;
