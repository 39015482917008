import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useBalance } from 'hooks';
import { Wallets } from 'types';

import { walletColors } from 'utils';

ChartJS.register(ArcElement, Tooltip, Legend);

type WalletsListType = {
  wallets: Wallets;
  balance: string;
};

const DoughnutChart: React.FC<WalletsListType> = ({
  wallets,
  balance,
}: WalletsListType) => {
  const { data: balanceWallets } = useBalance();

  const labels = wallets.map((wallet) => wallet.currency.symbol);
  const amounts = wallets.map(
    (wallet) =>
      balanceWallets?.walletsWithBalanceByUsdt.find(
        (item) => item.id === wallet.id
      )?.resultAmount || 0
  );
  const colors = wallets.map((wallet) => walletColors[wallet.currency.symbol]);

  const emptyDoughnut = {
    id: 'emptyDoughnut',
    afterDraw(chart: any) {
      const { datasets } = chart.data;
      if (!(datasets[0].data?.length > 0)) {
        const {
          chartArea: { left, top, right, bottom },
          ctx,
        } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = 25;
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.arc(centerX, centerY, r - 50 || 0, 0, 2 * Math.PI);
        ctx.stroke();
      }
    },
  };

  const innerLabel = {
    id: 'innerLabel',
    afterDatasetDraw(chart: any, args: any) {
      const { ctx } = chart;
      const meta = args.meta;
      const xCoor = meta.data[0].x;
      const yCoor = meta.data[0].y + 10;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.font = '18px Satoshi';
      ctx.fillStyle = 'white';
      ctx.fillText(balance, xCoor, yCoor);
      ctx.restore();
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: '',
        data: amounts,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderColor: colors,
        borderWidth: 2,
        hoverOffset: 20,
        cutout: '75%',
        responsive: true,
        maintainAspectRatio: true,
        showScale: true,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        plugins: {
          title: {
            display: true,
            text: 'Chart',
            font: {
              size: 16,
            },
          },
          legend: {
            display: false,
            labels: {
              font: {
                family: 'Satoshi medium',
                size: 16,
              },
            },
          },

          tooltip: {
            enabled: true,
            usePointStyle: true,
            callbacks: {
              title: (tooltipItem) => {
                return tooltipItem[0].formattedValue + '$';
              },
              label: (tooltipItem) => {
                return tooltipItem.label;
              },
            },
          },
        },
        radius: '70%',
      }}
      plugins={[innerLabel, emptyDoughnut]}
      style={{ position: 'relative' }}
    />
  );
};
export default DoughnutChart;
