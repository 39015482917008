import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useCancelServiceWithdraw = (): UseMutationResult<
  any,
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }: { id: string }) =>
    api.put(`${url.cancelServiceWithdraw}/${id}/reject`, {})
  );
};
export default useCancelServiceWithdraw;
