import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Exchange, ExchangeResponse } from 'types';

import { api } from 'utils';

const useExchange = (): UseMutationResult<
  ExchangeResponse,
  ErrorResponse,
  Exchange
> => {
  return useMutation((data) => api.post(url.exchange, data));
};
export default useExchange;
