import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, LDUsers } from 'types';

const useLDUsers = ({
  queries,
  enabled,
}: {
  queries: {
    wallet?: string;
  };
  enabled: boolean;
}): UseQueryResult<LDUsers, ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }
  return useQuery([`${url.depositLdUsers}${queryParams}`], { enabled });
};
export default useLDUsers;
