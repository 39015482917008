import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInitWithdrawProposal } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { routes } from 'statics';
import { LDUser, LDUsers } from 'types';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = {
  amount: number;
  pay_with: string;
  note: string;
};

type InitFormProps = {
  currencyList: {
    label: string;
    value: string;
  }[];
  ldUsers: LDUsers;
  walletId: string;
  activeLD: null | LDUser;
};

const InitForm: React.FC<InitFormProps> = ({
  currencyList,
  ldUsers,
  walletId,
  activeLD,
}: InitFormProps) => {
  const { t } = useTranslation('component.withdraw.ld');
  const navigate = useNavigate();

  const { mutateAsync: submit, isLoading: submitLoading } =
    useInitWithdrawProposal();

  const onSubmit = async (data: PayloadType) => {
    if (ldUsers) {
      submit({
        ...data,
        ld_identifier_number: activeLD ? activeLD.identifier_number : 0,
        source_wallet: walletId,
        transaction_method_category: 'WITHDRAW_LD',
      }).then((res) => {
        if (res.id) {
          navigate(`${routes.wallets.url}?tab=withdraw`);
        }
      });
    }
  };

  const schema = yup.object().shape({
    pay_with: yup.string().label(t('form.pay_with')).required(),
    amount: yup.number().label(t('form.amount')).required(),
    note: yup.string().label(t('form.note')).required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const submitForm = async (data: PayloadType) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  const formProps = useInputAttributes(register, errors, {
    pay_with: t('form.pay_with'),
    amount: t('form.amount'),
    note: t('form.note'),
  });

  React.useEffect(() => {
    if (currencyList && currencyList.length > 0) {
      setValue('pay_with', currencyList[0].value);
    }
  }, [currencyList]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box w="100%">
        <Input {...formProps.pay_with} isSelectBox options={currencyList} />
      </Box>
      <Box w="100%">
        <Input {...formProps.note} isTextArea maxH={200} />
      </Box>
      <Divider />
      <VStack w="100%">
        <Text w="100%" pt={2} textAlign="left">
          {t('enterAmount')}
        </Text>
        <Box w="100%" pos="relative">
          <Input
            {...formProps.amount}
            label=""
            type="number"
            min="0"
            step="0.01"
            bgColor="lightPrimary"
            border="none"
            borderRadius={5}
            py={10}
            fontSize="4xl"
            fontWeight="500"
            _placeholder={{ color: 'grey' }}
          />
          <Text fontSize="sm" pos="absolute" top={2} right={2}>
            {watch('pay_with')}
          </Text>
        </Box>
      </VStack>
      <Button
        type="submit"
        variant="filled"
        display="block"
        title={t('confirm')}
        minW="100%"
        mt={2}
        isLoading={submitLoading}
      />
    </form>
  );
};
export default InitForm;
