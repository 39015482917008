import React from 'react';
import { Stack, VStack } from '@chakra-ui/react';
import { useProposalsList, useTransactions } from 'hooks';

import { Proposals, Transactions } from './components';

const LDDeposits: React.FC = () => {
  const {
    data: transactions,
    isLoading: loadingTransactions,
    refetch: refreshTransactions,
  } = useTransactions({
    queries: { 'transaction_method.category': 'DEPOSIT_LD' },
  });
  const {
    data: proposals,
    refetch: refreshProposals,
    isLoading: loadingProposals,
  } = useProposalsList({
    transaction_method_category: 'DEPOSIT_LD',
  });

  return (
    <Stack my={4} w="100%">
      <VStack
        display="flex"
        w="100%"
        py={8}
        px={4}
        gap={25}
        bgColor="dark"
        align="flex-start"
      >
        <Transactions
          transactions={transactions?.list || []}
          loading={loadingTransactions}
          refetch={refreshTransactions}
        />
        <Proposals
          proposals={proposals?.list || []}
          loading={loadingProposals}
          refetch={refreshProposals}
        />
      </VStack>
    </Stack>
  );
};

export default LDDeposits;
