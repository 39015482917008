import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, SignUpEmail } from 'types';

import { api } from 'utils';

const useSignUpEmail = (): UseMutationResult<SignUpEmail, ErrorResponse> => {
  return useMutation((data) => api.post(url.sendEmail, data));
};
export default useSignUpEmail;
