import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Wallets } from 'types';

import { api } from 'utils';

const useSwapAvailableDestinations = (): UseMutationResult<
  Wallets,
  ErrorResponse,
  { source_wallet: string }
> => {
  return useMutation((data) =>
    api.post(
      `${url.swapAvailableDestination}?source_wallet=${data.source_wallet}`
    )
  );
};
export default useSwapAvailableDestinations;
