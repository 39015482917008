import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { useAccess, useLDUsers, useWallet, useWithdrawTabs } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Spinner, Tabs } from 'components';

import { BankTransferTab, CryptoTab, Head, LDTab } from './components';

const Withdraw: React.FC = () => {
  const { t } = useTranslation('page.withdraw');
  const access = useAccess();

  const [searchparams] = useSearchParams();

  // Check access
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!access.withdrawService) {
      navigate(routes.dashboard.url);
    }
  }, []);

  const {
    data: wallet,
    isLoading: isLoadingWallet,
    isFetching: isFetchingWallet,
  } = useWallet({
    id: searchparams.get('wallet'),
  });

  const { data: LDs } = useLDUsers({
    queries: {
      wallet: searchparams.get('wallet') || '',
    },
    enabled: access.withdrawLDUsersListPermission,
  });

  const allowed_tabs = {
    '': [],
    FIAT: [
      {
        label: t('tabs.bankTr'),
        content: <BankTransferTab wallet={wallet} />,
        isActive: access.withdrawBankService,
      },
      {
        label: t('tabs.ld'),
        content: <LDTab lds={LDs || []} />,
        isActive:
          access.withdrawLDUsersListPermission &&
          access.withdrawLDCreatePermission &&
          LDs &&
          LDs?.length > 0,
      },
    ],
    CRYPTO: [
      {
        label: t('tabs.crypto'),
        content: <CryptoTab wallet={wallet} />,
        isActive: access.withdrawCryptoCreatePermission,
      },
      {
        label: t('tabs.ld'),
        content: <LDTab lds={LDs || []} />,
        isActive:
          access.withdrawLDUsersListPermission &&
          access.withdrawLDCreatePermission &&
          LDs &&
          LDs?.length > 0,
      },
    ],
  };

  const { activeTabIndex, changeActiveTab } = useWithdrawTabs();

  React.useEffect(() => {
    if (searchparams.get('wallet')) {
      changeActiveTab({ index: 0, type: wallet?.type });
    }
  }, [searchparams.get('wallet')]);

  return (
    <Box w="100%" pt={{ base: 2, lg: 8 }}>
      <Head />
      <Box my={{ base: 0, lg: 4 }}>
        <Tabs
          isLazy
          index={activeTabIndex({ type: wallet?.type })}
          onChange={(activeIndex) =>
            changeActiveTab({ index: activeIndex, type: wallet?.type })
          }
          tabData={allowed_tabs[wallet?.type || '']}
        />
        {isLoadingWallet && isFetchingWallet && (
          <Stack w="100%" justify="center" align="center" my={10}>
            <Spinner size="md" />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default Withdraw;
