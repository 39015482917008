import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateDepositProposal, ErrorResponse } from 'types';

import { api } from 'utils';

const useInitDepositProposal = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  CreateDepositProposal
> => {
  return useMutation((data) => api.post(url.initDepositProposal, data));
};

export default useInitDepositProposal;
