import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';

import { fixDecimal } from 'utils';

type CurrentBalanceProps = {
  amount: number;
  symbol: string;
  type?: 'CRYPTO' | 'FIAT';
};

const CurrentBalance: React.FC<CurrentBalanceProps> = ({
  amount,
  symbol,
  type,
}: CurrentBalanceProps) => {
  const { t } = useTranslation('component.withdraw');

  return (
    <Box w={{ base: '100%', md: '50%' }}>
      <Box
        display="flex"
        flexDir={{ base: 'column', sm: 'row' }}
        alignItems="center"
      >
        <Text fontSize={{ base: 'md', sm: 'lg' }} color="body2" mr={4}>
          {t('currBalance')}
        </Text>
        <Text fontSize={{ base: 'lg', sm: '2xl' }}>
          {`${fixDecimal({
            value: amount,
            fractionDigits: type === 'CRYPTO' ? 5 : 2,
          })} ${symbol}`}
        </Text>
      </Box>
    </Box>
  );
};
export default CurrentBalance;
