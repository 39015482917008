import React from 'react';
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Pending } from 'assets/icons/pendingIcon.svg';
import { ReactComponent as Success } from 'assets/icons/successIcon.svg';
import { useCryptoWhiteList } from 'hooks';
import { useTranslation } from 'localization';

import { CopyClipBoard } from 'components';

const Whitelist: React.FC = () => {
  const { data: whiteLists } = useCryptoWhiteList({ queries: {} });
  const { t } = useTranslation('component.settings.finance');

  return (
    <Box
      borderRadius={10}
      border="1px solid grey"
      p={4}
      w={{ base: '100%', xl: '50%' }}
    >
      <Text fontSize="xl" pb={4}>
        {t('savedWallets')}
      </Text>
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        {whiteLists?.list.map((account, index) => {
          return (
            <React.Fragment key={account.id}>
              <VStack w="100%" py={4}>
                <HStack w="100%" align="center" justify="space-between">
                  <Text>{account.title}</Text>
                  {account.status === 'VERIFIED' ? (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text color="primary" fontSize="sm">
                        {t('verified')}
                      </Text>
                      <Success />
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text color="warning" fontSize="sm">
                        {t('pending')}
                      </Text>
                      <Pending />
                    </Box>
                  )}
                </HStack>
                <Box
                  w="100%"
                  display="flex"
                  flexDir={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  justifyContent="space-between"
                >
                  <Text color="body2">{t('walletAddress')}</Text>
                  <CopyClipBoard
                    initialValue={account.wallet_address}
                    showCharacterLength={24}
                  />
                </Box>
              </VStack>
              {whiteLists.list.length - 1 > index && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default Whitelist;
