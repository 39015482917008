import { theme } from '@chakra-ui/react';

export const colors = {
  ...theme.colors,
  primary: '#3ECF8E',
  lightPrimary: '#1D2E36',
  darkPrimary: '#299D69', //hover
  secondary: '#051721', //body-dark-mode
  secondary2: '#E5E5EB', //dactive-disable
  body: '#FCFCFC',
  body2: '#757575', //sub-text-icons
  warning: '#FF964D',
  success: '#2BAC47',
  error: '#FF3E3A',
  lightError: '#ff9999',
  dark: '#171D20',
  lightBg: '#FBEFEF',
  invisibleLabel: '#10002E',
  info: '#17a2b8',
  sumsub: '#20252C',
};
