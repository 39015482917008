import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { useTranslation } from 'localization';

const CustomerAgreement: React.FC = () => {
  const { t } = useTranslation('component.auth.signUp');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display="flex" alignItems="center">
      <Text display="block" pr={2}>
        {t('agreement')}
      </Text>
      <Text display="block" color="primary" onClick={onOpen}>
        {t('customerAgreement')}
      </Text>
      <Modal
        onClose={onClose}
        size={{ base: 'xl', md: '6xl' }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxH="90vh" mx={4} pos="relative">
          <ModalHeader shadow="xl" pos="sticky" top={0} bgColor="gray.700">
            <ModalCloseButton />
            <Box
              display="flex"
              flexDir={{ base: 'column', md: 'row' }}
              alignItems="center"
              justifyContent="flex-start"
              gap={10}
              px={{ base: 4, md: 20 }}
            >
              <HomeIcon />
              <Text fontSize={{ base: '2xl', md: '5xl' }}>
                {t('termCondition')}
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody
            py={{ base: 10, md: 20 }}
            px={{ base: 4, md: 20 }}
            overflowY="auto"
          >
            <Text fontWeight="500" mb={5}>
              Exchange Magix
            </Text>
            <Text fontWeight="400" mb={10}>
              United Loans Pty Ltd 141 657 650 (we, us, our) is owner and
              operator of Exchangemagix.com, all of its sub-domains (the Site),
              the “Exchange Magix” iOS and Android mobile applications (the
              App), and other various third party social media and messaging
              sites, channels, pages, group sand forums (collectively, our
              Services).
            </Text>
            <Text fontWeight="500" mb={5}>
              Terms you accept for using Exchangemagix.com
            </Text>
            <Text fontWeight="400" mb={5}>
              You are permitted to access and use our Services under the
              condition of you agreeing to the terms and conditions set out
              below Terms. Please read these Terms carefully.
            </Text>
            <Text fontWeight="400" mb={10}>
              After clicking an “accept” button, opening an account, or
              accessing or using any part of our Services, you accept, or are
              deemed to have accepted (as the case may be), all of these Terms,
              as varied, modified or amended by us from time to time. You agree
              that these Terms are legally binding in the same manner as any
              written contract with your signature or seal. If you do not agree
              with the mentioned Terms, you must not open an account and must
              not use any of our Services. The right to modify or add and remove
              any part of our services, Terms and Conditions or Privacy Policy
              and any other material and information in this website at any time
              to be amended, changed or discontinued is reserved with us,
              without liability or further notice to you. Your continued use of
              our Services will imply an automatic acceptance of any change or
              modification and deletion done by us.
            </Text>
            <Text fontWeight="500" mb={5}>
              Agreement of Use
            </Text>
            <Text fontWeight="400" mb={5}>
              You agree that you are only authorized to access and use our
              Services for your own personal use and for the activities of:
              accessing and using our Services or any other reason which we
              inform you about.
              <br />
              Unless otherwise agreed and approved by us, you must not use our
              Services: to provide financial advice or any other form of advice
              or otherwise regarding any share, security, or financial product
              or instrument, or an offer, solicitation, recommendation, or
              invitation, to buy, sell or deal in any way with any of those
              things; Or for any illegal intention, purpose or activity,
              including but not limited to any act which causes, or may be
              likely to cause, physical or financial harm to any person.
            </Text>
            <Text fontWeight="400" mb={10}>
              Your access and use of our Services are subject to these Terms and
              any other terms and conditions of use that appear on our Services
              (including third party terms of use, such as those adopted by
              social media websites and platforms) and any click and accept end
              user license terms, together with privacy policy terms and
              acceptable use standards.
            </Text>
            <Text fontWeight="500" mb={5}>
              We are Only Facilitator (Not Financial Advisor)
            </Text>
            <Text fontWeight="400" mb={5}>
              You understand and agree that we are not a regulated exchange and
              we do not hold or operate under an Australian financial services
              licence or any other licence. We are registered with AUSTRAC as a
              digital currency exchange provider under the Anti-Money Laundering
              and Counter-Terrorism Financing Act 2006 (Cth). All materials,
              including text, images, audio recordings, video recordings, and
              other information and content, including but not limited to,
              market or trading information, tools, indicators, and materials
              (Content) hosted or made available on or through our Services is
              provided: for general information reasons only; or Without any
              intention and regard whatsoever to the personal circumstances of
              any person. Any user-created Content which is hosted or made
              available on or through our Services is provided for, and on
              behalf of, the relevant user who created or supplied such Content.
              No Content hosted or made available on or through our Services is
              intended to constitute advice (financial or otherwise) regarding
              any share, security, or financial product or instrument, or an
              offer, solicitation, recommendation, or invitation, to buy, sell
              or deal in any way with any of those things. All statements,
              representations, estimations, projections, or forecasts made in or
              through our Services by any person (whether relating to the
              present or the future) are expressions of opinion only, and do not
              necessarily represent our opinion or have our endorsement.
            </Text>
            <Text fontWeight="400" mb={10}>
              Unless otherwise expressly stated by us, we, and any other person
              using our Services (whether as a user, administrator, moderator,
              or other user type), are not: professional or qualified advisors
              (such as lawyers or accountants); Or financial advisors or
              financial service providers; Or financial services license
              holders; or professional or qualified traders, investment
              managers, or fund managers; or brokers or agents; or securities
              advisors or practitioners; or Software developers, engineers,
              coders, or technical experts. Before acting on, or relying upon,
              any Content hosted or made available on or through our Services,
              we strongly recommend that you: undertake your own investigations
              and enquiries; and Seek independent legal, accounting, tax,
              financial, business, technical and other professional advice from
              an appropriately qualified professional advisor.
            </Text>
            <Text fontWeight="500" mb={5}>
              Services We Provide
            </Text>
            <Text fontWeight="400" mb={5}>
              We provide service to our users to access the matching of offers
              to buy or sell digital currencies and cryptocurrencies to offers
              made by other users to sell their cryptocurrencies, in order to
              facilitate the exchange of: fiat currency for cryptocurrency
              tokens; cryptocurrency tokens for fiat currency; or cryptocurrency
              tokens for other cryptocurrency tokens.
            </Text>
            <Text fontWeight="400" mb={5}>
              You acknowledge and agree that we are only a facilitator on your
              behalf and our sole purpose is to provide infrastructure for
              executing these transactions, and not as principal on our own
              behalf. you agree, authorize, direct and consent to us doing the
              following: communicating any offer submitted by you through our
              Services to buy or sell cryptocurrency tokens (Offer) to other
              users of our Services; matching your Offers with opposing Offers
              of other users (Counterparties); settling any resulting
              transactions within a reasonable time by doing one or both of the
              following things (as determined by us in our discretion):
              transferring fiat currency or cryptocurrency tokens held to your
              account to the relevant Counterparties; and/or applying a debit
              against your account, and applying a corresponding credit against
              the relevant Counterparties’ account/s, for fiat currency or
              cryptocurrency tokens, after deducting any fees due and owing by
              you (refer to the section below relating to Fees).
            </Text>
            <Text fontWeight="400" mb={5}>
              All Offers, once submitted, are irrevocable and may only be
              cancelled if we provide the ability to do so on our Services.
              Although we will generally match opposing Offers in the order in
              which we receive them, we do not guarantee that this will occur.
              For example, large Offers may, due to insufficient demand, be
              matched over time. We may, in our absolute discretion, regulate:
              the number and type of Offers that you may submit at any one time;
              your ability to amend or cancel Offers once submitted; and the
              parameters within which Offers may be submitted, such as price
              increments.
            </Text>
            <Text fontWeight="400" mb={10}>
              We reserve the right to cancel any Offer submitted by you, in
              whole or in part, where we consider, in our sole absolute
              discretion, that the Offer: has been submitted by you in breach of
              these Terms;
              <br />
              has the effect, or may be likely to have: the effect, of unfairly
              manipulating any market; or An adverse impact on our Services, or
              our reputation or goodwill.
            </Text>
            <Text fontWeight="500" mb={5}>
              Applications (iOS/Android)
            </Text>
            <Text fontWeight="400" mb={10}>
              Your download and installation of the iOS Application is subject
              to the Apple App Store terms and conditions (for Apple made
              devices) and Google Play Store terms and conditions (for Android
              devices). While we endeavour to make sure that the App supports
              all modern mobile phone devices, we do not warrant or guarantee
              that the App will be free of bugs, or perform as expectation, on
              all devices. In exchange for you agreeing to and accepting these
              Terms (or being deemed to do so), we grant you a personal,
              non-exclusive, non-assignable license to use and access the App
              (with no right to sublicense). Subject to law, we make no
              representations and give no warranties or guarantees to you that
              the App is suitable for any particular use or purpose.
            </Text>
            <Text fontWeight="500" mb={5}>
              Trading
            </Text>
            <Text fontWeight="500" mb={5}>
              Deposits and Withdrawals information
            </Text>
            <Text fontWeight="400" mb={10}>
              We are not obliged to accept deposits from any person, and reserve
              the right not to deal with any person, for any reason. All
              transactions of deposits and withdrawals of fiat currency or
              cryptocurrency tokens must be undertaken strictly in line with our
              specifications and requirements at all time. You understand and
              agree that, if you fail to comply with our specifications and
              requirements, we are not responsible for any loss suffered by you
              as a result. Your ability to deposit or withdraw fiat currency or
              cryptocurrency tokens may be regulated under Australian law, such
              as Anti-Money Laundering and Counter-Terrorism Financing Act 2006
              (CTh). You acknowledge and agree that we may, in our sole absolute
              discretion, do all acts and things which are necessary or
              desirable to comply with the law, including but not limited to:
              blocking, cancelling, or reversing transactions; or freezing
              funds, or preventing access to them by you, or any other person;
              or notifying the authorities and complying with their requests and
              requirements. You agree that we may determine, in our sole
              absolute discretion, the systems, methods, processes and
              procedures, by which we receive, hold, or disburse fiat currency
              or cryptocurrency tokens, including but not limited to: for fiat
              currency, whether funds are segregated or pooled; Or for
              cryptocurrency tokens, if, when and how hot or cold wallets are
              used or accessed. You agree, authorize, direct and consent to us
              holding to your account all fiat currency and cryptocurrency
              tokens you send or transfer to us. You further acknowledge and
              agree that: we hold the legal title to all fiat currency and
              cryptocurrency tokens which we hold to your account; Or under the
              law, your right to recover any fiat currency or cryptocurrency
              tokens which we hold to your account is an unsecured debt owed by
              us to you; or If we become insolvent, you may be entitled to make
              a claim for the recovery of that debt, but there is no guarantee
              that you will be successful in recovering any part of that debt.
              You waive any right to any interest paid by our financial
              institution on any fiat currency held by us to your account. We
              are entitled to retain all such interest, if any. Despite any
              other provision of these Terms, you acknowledge and agree that we
              may, in our sole absolute discretion, take any action which we
              consider necessary or desirable to protect or secure the fiat
              currency or cryptocurrency tokens which we hold to the account of
              our users, or otherwise serve the interests of our users and other
              stakeholders. Please note for bank transfers, Sender’s bank
              account name must be same as account holder name in Exchange
              Magix, otherwise Exchange Magix reserves the right to reject
              incoming funds.
            </Text>
            <Text fontWeight="500" mb={5}>
              Trading Account Opening
            </Text>
            <Text fontWeight="400" mb={10}>
              In order to access and use our Services, you must: create an
              account on our Site; and verify your account by following our
              know-your-customer (KYC) and anti-money laundering (AML)
              procedures and providing such information and documents which we
              require. We may use, or you maybe require to use, a third party to
              verify your identity, in which case, you acknowledge and agree as
              follows: the account verification process is subject to the terms
              of service and other requirements of the third party service
              provider; and in order to verify your identity, we, or the third
              party service provider, may disclose and securely transmit your
              personal information to: government authorities; credit agencies;
              politically exposed person database service providers, or other
              watch list service providers; and/or other third party data or
              identity verification service providers. You consent to us
              collecting and disclosing your personal information for the above
              purposes. We may, at any time, require you to undertake further
              KYC or AML procedures, and provide further information and
              documents, in order to maintain your account verification. When
              creating an account, you acknowledge and agree that: You will not
              provide any false personal information and will keep your personal
              information accurate and up to date; You will only create one
              account; You will not allow anyone else to access your account;
              You will not create a new account if your account is disabled; We
              may alter any part of your user account if we receive a legitimate
              complaint, or otherwise form the view (in our sole discretion)
              that it is inappropriate or offensive; and if you create an
              account using your user credentials from a third party site (such
              as Facebook or Google), your use of such credentials is subject to
              the relevant third party site’s terms of use and privacy policy,
              and you consent to us collecting such user credentials for the
              purpose of creating your account; and We have the right to
              terminate your access to our Services, at any time, in our sole
              discretion, without prior notice to you.
            </Text>
            <Text fontWeight="500" mb={5}>
              Your Conduct
            </Text>
            <Text fontWeight="400" mb={10}>
              You are responsible for your use of our Services and for any use
              of our Services made using your account. You agree not to access,
              copy, or otherwise use our Services including our intellectual
              property and trademarks, except as authorised by these Terms of
              Use or as otherwise authorised in writing by us. In circumstances
              where we reasonably suspect that you have used our Services to
              participate in market manipulation (such as pump and dump
              schemes), regardless of whether prohibited by law, we reserve the
              right to close your account. We accept no liability for any losses
              associated with, or any suspension or closure of your account, in
              connection with suspected market manipulation.
              <br />
              When using our Services, you agree that you will not: (a) act in a
              way which is otherwise than courteous and polite; (b) breach any
              policy on the Platform or any law which may be applicable to the
              use of the Platform; (c) breach, or cause us to breach, any law,
              regulation, rule, code or other legal obligation; (f) use any
              automated systems including “robots” and “spiders”, other than
              those permitted by us directly to you in writing; (g) interfere
              with or compromise our Site’s, or App’s, integrity or decipher any
              server transmissions; (h) impose an unreasonably large load on our
              infrastructure; (i) upload viruses, worms, or invalid data to our
              Site, or App; (j) collect or retain any personally identifiable
              information contained on our Site, or App; (k) access our services
              by any means other than as authorised in these Terms of Use,
              including virtual private networks which are expressly forbidden;
              (l) stalk, harass, bully or harm others; or (m) impersonate any
              person or entity.
            </Text>
            <Text fontWeight="500" mb={5}>
              Trading Account permanent Deletion/Termination
            </Text>
            <Text fontWeight="400" mb={10}>
              You can delete your account at any time by sending us email at
              info@exchangemagix.com
              <br />
              we reserve the right to delete your account/s on our Services at
              any time and for any reason, including but not limited to: if your
              account is inactive for a period of six (6) months or more; or if
              you breach these Terms, or any other relevant third party terms
              and conditions, or we suspect that you have breached them; or If
              you engage in any illegal, offensive, defamatory, or obscene
              conduct or behaviour.
            </Text>

            <Text fontWeight="500" mb={5}>
              Risks
            </Text>
            <Text fontWeight="400" mb={5}>
              Investing, speculating, holding, or trading (Trading) in
              cryptocurrencies, digital assets, digital currencies or
              cryptocurrency tokens (terms which we use interchangeably in these
              Terms) involves high risk and may end up in you losing all of your
              money. Purchasing, trading or holding and selling cryptocurrencies
              involves the use of advanced and sophisticated computer hardware
              and software, the correct use of which requires a significant
              level of understanding of the underlying technologies and
              innovations. As such hardware and software is considered in its
              infancy, using it comes with considerable risks, including but not
              limited to: user error; or hardware or software failure or
              malfunction; or the risk of cybercrime, hacking, or fraud. Many
              cryptocurrency coins, tokens, and digital assets remain under
              development by their promotors or people who created them, have
              not been implemented or deployed, and are not in real-world use.
              The information published publicly or otherwise made available by
              the promoters of many cryptocurrency coins, tokens, and digital
              assets is of a technical nature and requires a high level of
              knowledge and understanding of distributed ledger technology,
              software development, traditional banking systems, investment
              scheme regulation, securities regulation, and various other laws
              and regulations. The matters, technologies and innovations
              published publicly or otherwise made available by the promoters of
              many cryptocurrency coins, tokens, and digital assets: may not
              have been subject to any evaluation or verification by independent
              qualified professionals (such as legal, financial, taxation,
              information technology, or engineering professionals); or may
              require regulatory or other approvals or consents from
              governmental and non-governmental bodies and privately held
              entities to be lawfully used or implemented; and or May be subject
              to agreements, licensing, and other arrangements with unknown
              third parties, the terms of which are not publicly disclosed.
            </Text>
            <Text fontWeight="400" mb={5}>
              Our Services may involve trading tools, indicators, materials, and
              configurations (Trading Tools) which you may use. The factors,
              variables and circumstances which are accounted for by the Trading
              Tools are limited to the variables, inputs and specifications of
              each Trading Tool (as applicable). There are various factors,
              variables and circumstances which may affect, positively or
              negatively, the result of any Trading activity, or the use of any
              Trading Tool, including but not limited to: market conditions and
              sentiment, including market volatility (price fluctuations) and
              liquidity (the availability of buyers and sellers in the market);
              or correct technical implementation of Trading Tools (including,
              but not limited to, mathematically, conceptually, and
              strategically); or changes in laws and regulations applying to
              cryptocurrencies; or other macro-economic factors; and or Bad
              actors, such as market manipulators, insider traders and
              “pump-and-dump” syndicates.
            </Text>
            <Box px={10}>
              <Text fontWeight="400" mb={10}>
                By using our Services, or any Trading Tool, you acknowledge and
                agree that: we do not set the price of any cryptocurrency token
                which is tradeable on our Services; Or the prices or rates of
                exchange for cryptocurrency tokens available on our Services may
                differ significantly from the prices or rates of exchange
                available on other cryptocurrency exchanges or exchange
                services; Or we do no warrant or guarantee that you will
                generate any financial return over any time period; Or we do not
                warrant or guarantee that any Trading Tool is suitable for the
                purpose for which it is intended, or for any other purpose
                whatsoever; and You risk losing all of your money, for which you
                will be solely responsible. You agree that before acting on, or
                relying upon, any materials hosted or made available through our
                Services, you have undertaken your own investigations and
                enquiries, and sought, as you feel is necessary, any independent
                legal, accounting, tax, financial, business, technical and other
                professional advice from an appropriately qualified professional
                advisor. We are not responsible for any loss or damage caused,
                directly or indirectly, by any events, actions or omissions
                beyond our reasonable control including, without limitation,
                loss or damage resulting, directly or indirectly, from any
                delays or inaccuracies in the transmission of orders or
                information due to breakdown or failure of transmission or
                communication facilities, or electrical power outage.
              </Text>
            </Box>
            <Text fontWeight="500" mb={5}>
              Force Majeure
            </Text>
            <Text fontWeight="400" mb={10}>
              In this clause, Force Majeure means a circumstance beyond our
              reasonable control which occurs without our fault or negligence,
              and includes without limitation an act of God, inevitable
              accident, storm, flood, fire, earthquake, peril of navigation,
              epidemic, pandemic, quarantine restrictions, strike, lock-out,
              boycott or other industrial dispute, hostility, war (declared or
              undeclared), riot, insurrection, act of terrorism, executive or
              administrative order or act of either general or particular
              application of a government (whether de jure or de facto) or of
              any official purporting to act under the authority of such a
              government, prohibition or restriction by domestic or foreign
              laws, regulations or policies and quarantine or customs
              restrictions. Any of our obligations to you will be suspended
              during the time and to the extent that we are prevented from or
              delayed in complying with that obligation due to a Force Majeure.
              We exclude all liability, loss or claims to the extent that they
              were caused or contributed to by a Force Majeure.
            </Text>
            <Text fontWeight="500" mb={5}>
              Payments/Transfer Information
            </Text>
            <Text fontWeight="400" mb={10}>
              Where fiat currency payments or transfers are handled by Payment
              Service Providers (PSP’s), they are subject to PSP’s terms and
              conditions. We accept no responsibility or liability in relation
              to payments processed by PSP’s. Any issues which you experience in
              making payments to us may be raised with us, however, we reserve
              the right to require you to liaise with the PSP directly. All
              cryptocurrency payments or transfers are made by you at your own
              risk and expense. We accept no responsibility or liability in
              relation to: any delayed or failed payment due to congestion on
              any crypto currency network; or any failed or mistaken payments
              due to human error, such as entering the incorrect recipient
              wallet address; or any fluctuations in the market price of any
              crypto currency used to pay our fees.
            </Text>
            <Text fontWeight="500" mb={5}>
              Protection/Security
            </Text>
            <Text fontWeight="400" mb={10}>
              You must ensure that all login IDs and passwords required to
              access our Services are kept secure and confidential. We may
              support multi-factor authentication in order to access our
              Services, such as by requiring you to input one-time-use codes
              sent by SMS to the mobile number associated with your account. You
              must immediately notify us of any unauthorized use of your login
              IDs or passwords or any other breach of security. Where there is
              unauthorized access or a breach of security, we will take
              appropriate steps to rectify the unauthorized use or breach of
              security, including by, without limitation, cancelling and
              resetting the relevant login IDs and passwords, and you must take
              all other actions that we reasonably deem necessary or desirable
              to maintain or enhance the security of our computing systems and
              networks and your access to our Services. We will use all security
              to ensure that we provide a secure environment for any data stored
              or hosted on our systems, including by encrypting your data. We
              will not be liable for any damage, loss, cost, liability, expense,
              injury or death of any kind and however arising including
              penalties, fines and interest whether prospective or contingent
              and any amounts that for the time being are not ascertained or
              ascertainable (Loss) suffered by you or any third party should our
              security measures (or those of any of our service providers) be
              overcome or breached. We maintain complete appropriate technical
              and operational measures, internal controls, and data security
              routines intended to protect your data against accidental loss or
              change, unauthorized disclosure or access, or unlawful
              destruction. We are not responsible for the security of any data
              stored on any personal computer, laptop, mobile or other device
              owned or controlled by you.
            </Text>
            <Text fontWeight="500" mb={5}>
              Refund/Chargebacks
            </Text>
            <Text fontWeight="400" mb={10}>
              To the greatest extent permitted by law, subject to anything in
              these Terms to the contrary, all fees and other monies payable by
              you to us are non-refundable immediately upon payment by you. To
              the extent that we are liable under the law for any defect or
              issue, our liability is limited to the amount paid by you. We may,
              in our discretion, offer partial or full refunds where we consider
              appropriate to do so. If you initiate a transfer recall with your
              financial institution, or a chargeback on your credit or debit
              card, or take any similar action, you must pay us an
              administration fee of AUD $50.00 on demand. You acknowledge, agree
              and consent to us setting off any such fees against any monies
              which we may owe to you from time to time.
            </Text>
            <Text fontWeight="500" mb={5}>
              Charges and Fees
            </Text>
            <Text fontWeight="400" mb={10}>
              Please check our fee schedule for complete view of our fees. All
              fees are, unless otherwise expressly stated by us: for fees
              described in fiat currency, in Australian dollars ($AUD); for fees
              described in crypto currency, in Tether (USDT); and Inclusive of
              goods and services tax and other value-added tax (VAT).
            </Text>
            <Text fontWeight="500" mb={5}>
              Contents and Copyrights
            </Text>
            <Text fontWeight="400" mb={10}>
              All contents represented in this website or any other application
              under Exchange Magix is made by us. You may only retain copies of
              information if obtained incidentally to your viewing and kept for
              your own personal reference.
            </Text>
            <Box px={10}>
              <Text fontWeight="500" mb={5}>
                Confidentiality
              </Text>
              <Text fontWeight="400" mb={10}>
                You agree to keep confidential all Data and Content hosted or
                made available on or through our Services (including Content
                generated by other users). If you receive or encounter any
                information which a reasonable person would consider to be
                confidential in nature, you agree to contact us immediately at
                info@exchangemagix.com
              </Text>
              <Text fontWeight="500" mb={5}>
                Disclaimer
              </Text>
              <Text fontWeight="400" mb={5}>
                Our services are delivered on an “as is” and “as available”
                basis, subject to all latent and patent errors (if any). Based
                on this, we do not warrant that our services will be error-free
                or uninterrupted. Whilst we do our 100% to provide best service,
                to the greatest extent permitted by law: under no conditions do
                we make any representations, and we do not give any warranties
                or make any guarantees, either express or implied, including,
                without limitation, warranties of title or implied warranties of
                merchantability or fitness for a particular purpose, including
                but not limited to financial investment purposes, whether or not
                such use or purpose is described by you to us or our
                representatives prior to these terms taking effect; and any
                condition, guarantee, or warranty which would otherwise be
                implied into these terms and conditions is excluded.
              </Text>
              <Text fontWeight="400" mb={5}>
                All due care is taken in ensuring that our services are free of
                any virus, worm, Trojan horse and/or malware, however we are not
                responsible for any damage to your computer system or device
                which arises in connection with your use of our services or any
                third party site. We undertake to take all due care with any
                information which you may provide when accessing our services
                and to preserve such information in a secure manner in
                accordance with our privacy policy. We, however, do not warrant
                and cannot ensure the security of any information which you may
                provide and information you transmit to our services is entirely
                at your own risk.
              </Text>
              <Text fontWeight="400" mb={5}>
                We provide no warranty as to the accuracy or currency of the
                account information or other information or data uploaded to our
                services by any other person. Our services may dynamically
                source content (such as price, volume, depth and other market
                data) from third party sites and services using application
                programming interfaces (API’s) and other technologies. We give
                no warranties and make no guarantees as to the accuracy or
                currency of any such data, the responsibility for which at all
                times remains with the relevant third party sites and services.
                From time to time, we may host third party content on our
                services such as advertisements and endorsements. Responsibility
                for the content of such material rests with those third parties
                and we are not responsible for any errors or omissions in such
                material or any damage caused either directly or indirectly by
                them.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CustomerAgreement;
