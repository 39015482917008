import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  ErrorResponse,
  ListResponse,
  TransactionMethodKey,
  Wallet,
} from 'types';

const useWallets = ({
  queries,
}: {
  queries: {
    transaction_method_key?: TransactionMethodKey;
    type?: Wallet['type'];
  };
}): UseQueryResult<ListResponse<Wallet>, ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.wallets}${queryParams}&status=ACTIVE`]);
};
export default useWallets;
