import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { ReactComponent as DownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg';
import { useTranslation } from 'localization';

import { Button } from 'components';

type ApproveModalType = {
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
  data: { amount: number; fee: number };
  onButton: () => void;
};

const ApproveModal: React.FC<ApproveModalType> = ({
  onClose,
  isOpen,
  data,
  loading,
  onButton,
}: ApproveModalType) => {
  const { t } = useTranslation('component.transfer.modal');

  const feeAmount = (data.amount * data.fee) / 100;

  return (
    <Box display="flex" alignItems="center">
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalBody py={10} px={4} overflowY="auto">
            <VStack align="center" justify="space-between" h="100%">
              <VStack w="100%" align="center" justify="center">
                <TransferIcon />
                <Text fontSize="4xl" fontWeight="500" color="gray" py={2}>
                  $ {data.amount}
                </Text>
                <DownIcon />
                <Text fontSize="4xl" fontWeight="500">
                  $ {data.amount - feeAmount}
                </Text>
              </VStack>
              <TableContainer w="100%" overflowX="auto">
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td textAlign="left">{t('fee')}</Td>
                      <Td textAlign="right">
                        {data.fee ? '% ' + data.fee : '0'}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Button
                w="100%"
                variant="filled"
                title={t('confirm')}
                isLoading={loading}
                onClick={() => onButton()}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ApproveModal;
