import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useDeleteSession = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) => api.delete(`${url.deleteSession}/${id}`));
};

export default useDeleteSession;
