import { Link } from 'react-router-dom';
import { Box, HStack, Text } from '@chakra-ui/react';
import { ReactComponent as Deposit } from 'assets/icons/deposit.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw.svg';
import { useAccess } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

const Head: React.FC = () => {
  const { t } = useTranslation('component.transfer');
  const access = useAccess();

  return (
    <Box p={{ base: 4, md: 0 }}>
      <HStack
        justify={{ base: 'center', md: 'space-between' }}
        py={{ base: 2, md: 0 }}
      >
        <Text
          fontSize={'5xl'}
          fontWeight="500"
          display={{ base: 'none', md: 'inline' }}
        >
          {t('transfer')}
        </Text>
        <HStack>
          {access.withdrawService && (
            <Link to={routes.withdraw.url}>
              <Withdraw cursor="pointer" />
            </Link>
          )}
          <Link to={routes.deposit.url}>
            <Deposit cursor="pointer" />
          </Link>
          <Refresh />
        </HStack>
      </HStack>
    </Box>
  );
};

export default Head;
