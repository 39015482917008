import { Stack } from '@chakra-ui/react';
import { Profile } from 'types';

import QuickAccess from '../QuickAccess';
import RecentTransactions from '../RecentTransactions';
import SumSub from '../SumSub';
import WalletOverview from '../WalletOverview';
import YoutubeFrame from '../YoutubeFrame';

type UserDashboardType = {
  profile: Profile;
};

const UserDashboard: React.FC<UserDashboardType> = ({
  profile,
}: UserDashboardType) => {
  return (
    <Stack gap={4} w="100%">
      {profile.role.title === 'LEVEL0' && <SumSub />}
      {profile.role.title !== 'LEVEL0' && (
        <Stack gap={4} w="100%">
          <WalletOverview />
          <YoutubeFrame profile={profile} />
          <QuickAccess />
          <RecentTransactions />
        </Stack>
      )}
    </Stack>
  );
};

export default UserDashboard;
