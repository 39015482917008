import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useApproveTransfer,
  useProfile,
  useRouterState,
  useTransfer,
  useWallets,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, CopyClipBoard, Input } from 'components';
import { yup } from 'utils';

import ApproveModal from '../ApproveModal';

type PayloadType = {
  source_wallet: string;
  destination_identifier_number: number;
  amount: number;
};

const UserToUser: React.FC = () => {
  const { t } = useTranslation('component.transfer');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [approveModalData, setApproveModalData] = React.useState<{
    id: string;
    amount: number;
    fee: number;
  }>({
    id: '',
    amount: 0,
    fee: 0,
  });

  const { data: wallets, isSuccess } = useWallets({
    queries: {
      transaction_method_key: 'TRANSFER_USER:DEFAULT',
    },
  });
  const { data: profile } = useProfile();
  const { mutateAsync: submit, isLoading: submitLoading } = useTransfer();
  const { mutateAsync: approveTransaction, isLoading: approveLoading } =
    useApproveTransfer(approveModalData.id);

  const schema = yup.object().shape({
    source_wallet: yup.string().label(t('form.source_wallet')).required(),
    destination_identifier_number: yup
      .number()
      .label(t('form.destination_identifier_number'))
      .required(),
    amount: yup.number().label(t('form.amount')).required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    submit(data).then((res) => {
      if (res.id) {
        setApproveModalData({
          id: res.id,
          amount: res.amount,
          fee: res.transaction_method.fee,
        });
        onOpen();
      }
    });
  };

  const formProps = useInputAttributes(register, errors, {
    source_wallet: t('form.source_wallet'),
    destination_identifier_number: t('form.destination_identifier_number'),
    amount: t('form.amount'),
  });

  const walletList = wallets?.list?.map((wallet) => ({
    label: wallet.currency.title,
    icon: wallet.currency.icon,
    value: wallet.id,
  }));

  useRouterState({
    onChange: (n, location) => {
      if (location.state && location.state.wallet_id) {
        setValue('source_wallet', location.state.wallet_id);
      }
    },
    dependencies: [isSuccess === true],
  });

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          <Box w="100%" mb={6}>
            <Input
              {...formProps.source_wallet}
              isSelectBox
              options={walletList}
            />
          </Box>
          <Text fontSize="2xl" fontWeight="500">
            {t('destinationAccount')}
          </Text>
          <Box w="100%" mb={6}>
            <Input {...formProps.destination_identifier_number} />
          </Box>
          {profile?.identifier_number && (
            <CopyClipBoard
              initialValue={profile?.identifier_number.toString()}
            />
          )}
        </VStack>
        <Box bgColor="lightPrimary" p={4} textAlign="center">
          <Text color="warning">{t('hint')}</Text>
          <Text color="warning">{t('hint2')}</Text>
        </Box>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          <Box w="100%">
            <Input
              {...formProps.amount}
              bgColor="lightPrimary"
              border="none"
              borderRadius={5}
              py={10}
              px={10}
              type="number"
              step="0.01"
              min="0"
              fontSize="2xl"
              fontWeight="500"
              _placeholder={{ color: 'grey' }}
            />
          </Box>
          <Button
            type="submit"
            isLoading={submitLoading}
            variant="filled"
            display="block"
            title={t('submit')}
            minW="100%"
            mt={{ base: 8, md: 8 }}
          />
        </VStack>
      </form>
      <ApproveModal
        isOpen={isOpen}
        onClose={onClose}
        data={approveModalData}
        loading={approveLoading}
        onButton={() => {
          approveTransaction([]).then(() => {
            reset();
            onClose();
          });
        }}
      />
    </Box>
  );
};

export default UserToUser;
