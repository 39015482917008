import React from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Uploader } from 'components';
import { yup } from 'utils';

const CorporateFrom: React.FC = () => {
  const { t } = useTranslation('component.settings.verification');

  const [selectedFile, setSelectedFile] = React.useState<
    Array<File & { preview: string }>
  >([]);

  const schema = yup.object().shape({
    file: yup.string().label(t('form.file')).required(),
  });

  // const { mutateAsync: submit, isLoading: submitLoading } =
  // use();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const onSubmit = async (data: { file: string }) => {
    // submit(data).then((res) => {
    //   if (res.id) {
    //     reset();
    //     setValue('file', '');
    //     setSelectedFile([]);
    //   }
    // });
  };

  const formProps = useInputAttributes(register, errors, {
    file: t('form.file'),
  });

  return (
    <Box mt={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Uploader
          {...formProps.file}
          label=""
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          getValue={(value) => setValue('file', value)}
        />
      </form>
    </Box>
  );
};

export default CorporateFrom;
