import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useBankAccounts,
  useDebounce,
  useFee,
  useFeeCalculation,
  useTransactionMethods,
  useWithdrawBankTransfer,
  useWithdrawBankTransferApprove,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { CreateWithdrawBankTransferResponse, Wallet } from 'types';

import {
  BankTransferHint,
  Button,
  Input,
  PercentSelect,
  Spinner,
} from 'components';
import { fixDecimal, yup } from 'utils';

import ApproveModal from '../ApproveModal';
import CurrentBalance from '../CurrentBalance';
import DestinationAmount from '../DestinationAmount';

type PayloadType = {
  transaction_method: string;
  destination_bank_account: string;
  amount: number;
};

type BankTransferTabProps = { wallet?: Wallet };

const BankTransferTab: React.FC<BankTransferTabProps> = ({ wallet }) => {
  const { t } = useTranslation('component.withdraw');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchparams, setSearchParams] = useSearchParams();

  const [withdrawDetail, setWithdrawDetail] =
    React.useState<CreateWithdrawBankTransferResponse | null>(null);
  const { mutateAsync: submit, isLoading: submitLoading } =
    useWithdrawBankTransfer();
  const { mutateAsync: approve, isLoading: approveLoading } =
    useWithdrawBankTransferApprove();

  const availableAmount = (wallet?.amount || 0) - (wallet?.frozen_amount || 0);

  // Manage TR methods in 3 code block
  const {
    data: transactionMethods,
    isSuccess: isSuccessTrMethods,
    isRefetching,
  } = useTransactionMethods({
    queries: wallet
      ? {
          allowed_currencies: wallet?.currency.id,
          category: 'WITHDRAW_BANK_TRANSFER',
        }
      : {},
    enabled: Boolean(wallet),
  });

  // Manage form in 4 code block
  const schema = yup.object().shape({
    transaction_method: yup
      .string()
      .label(t('form.transaction_method'))
      .required(),
    destination_bank_account: yup
      .string()
      .label(t('form.destination_bank_account'))
      .required(),
    amount: yup
      .number()
      // TODO: circular dependency
      // .min(fee?.minimum_credit || 0)
      // .max(fee?.maximum_credit || 100000)
      .label(t('form.amount'))
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, {
    transaction_method: t('form.transaction_method'),
    destination_bank_account: t('form.destination_bank_account'),
    amount: t('form.amount'),
  });

  const onSubmit = async (data: PayloadType) => {
    submit({ ...data, source_wallet: wallet?.id || '' }).then((res) => {
      if (res.id) {
        setWithdrawDetail(res);
        onOpen();
      }
    });
  };

  const transactionMethodList =
    transactionMethods?.list?.map((transactionMethod) => ({
      label: transactionMethod.title,
      value: transactionMethod.id,
      key: transactionMethod.key,
    })) || [];

  React.useEffect(() => {
    if (isSuccessTrMethods && transactionMethodList.length === 1) {
      setValue('transaction_method', transactionMethodList[0].value);
    }
    // handles When we need to get fee
    setSearchParams(searchparams);
  }, [isSuccessTrMethods, isRefetching]);

  // Set active transaction method
  const trMethod =
    transactionMethods?.list.find(
      (item) => item.id === watch('transaction_method')
    ) ||
    transactionMethods?.list[0] ||
    null;

  // Manage fee in a code block
  const {
    data: fee,
    isFetching: isFetchingFee,
    isLoading: isLoadingFee,
  } = useFee({
    queries: {
      currency: wallet?.currency.id || '',
      transaction_method: trMethod?.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  // Manage bank accounts in 2 code block
  const { data: bankAccounts } = useBankAccounts({
    queries: trMethod ? { wallet: wallet?.id || '' } : {},
    enabled: Boolean(trMethod),
  });

  const bankAccountsList =
    bankAccounts?.list?.map((bankAcc) => ({
      label: bankAcc.title,
      value: bankAcc.id,
    })) || [];

  // Manage fee in 3 code block
  const {
    data: calculatedFee,
    mutateAsync: getCalculatedFee,
    isLoading: calculatedFeeLoading,
  } = useFeeCalculation();

  const debounceResult = useDebounce(
    parseFloat((watch('amount') || 0).toString()),
    500
  );

  React.useEffect(() => {
    if (
      fee &&
      fee.id &&
      debounceResult &&
      wallet &&
      availableAmount >= debounceResult
    ) {
      getCalculatedFee({
        data: { amount: debounceResult },
        id: fee.id,
      });
    }
  }, [fee, debounceResult]);

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          p={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.transaction_method}
              isSelectBox
              options={transactionMethodList}
              isDisabled={!wallet?.id || transactionMethodList.length === 0}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDir={{ base: 'column-reverse', md: 'row' }}
          alignItems="center"
          w="100%"
          pb={8}
          px={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }} pos="relative">
            <Input
              {...formProps.amount}
              bgColor="lightPrimary"
              border="none"
              borderRadius={5}
              py={10}
              px={10}
              type="number"
              min="0"
              step="0.01"
              fontSize="2xl"
              fontWeight="500"
              _placeholder={{ color: 'grey' }}
            />
            {wallet && (
              <Text pos="absolute" top={10} right={2} fontWeight="bold">
                {wallet?.currency.symbol}
              </Text>
            )}
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              w="100%"
            >
              <PercentSelect
                list={[25, 50, 75, 100]}
                onPercent={(item) =>
                  wallet
                    ? setValue(
                        'amount',
                        parseFloat(
                          fixDecimal({
                            value: (availableAmount * item) / 100,
                          })
                        )
                      )
                    : ''
                }
              />
            </Box>
          </Box>
          {wallet && (
            <CurrentBalance
              amount={availableAmount}
              symbol={wallet?.currency.symbol}
              type={wallet?.type}
            />
          )}
        </Box>
        {isLoadingFee && isFetchingFee && (
          <Stack w="100%" align="center" justify="center" py={10}>
            <Spinner size="md" />
          </Stack>
        )}
        {fee && wallet && (
          <BankTransferHint
            maximum_credit={fee.maximum_credit}
            minimum_credit={fee.minimum_credit}
            maximum={fee.maximum}
            minimum={fee.minimum}
            percent={fee.percent}
            symbol={wallet.currency.symbol}
            type="withdraw-bank"
          />
        )}
        <Box display="flex" alignItems="flex-end" w="100%" p={8} gap={25}>
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.destination_bank_account}
              isSelectBox
              options={bankAccountsList}
            />
          </Box>
        </Box>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          {parseFloat((watch('amount') || 0).toString()) > 0 && wallet && (
            <DestinationAmount
              amount={watch('amount')}
              symbol={wallet?.currency.symbol}
              fee={calculatedFee?.fee_amount}
              loading={calculatedFeeLoading}
              isInsufficient={debounceResult > availableAmount}
            />
          )}
          <Button
            type="submit"
            variant="filled"
            display="block"
            title={t('proceed')}
            minW="100%"
            isDisabled={
              calculatedFeeLoading ||
              (wallet && debounceResult > availableAmount)
            }
            isLoading={submitLoading}
            mt={{ base: 8, md: 8 }}
          />
        </VStack>
      </form>
      <ApproveModal
        data={{
          id: trMethod?.id || '',
          symbol: wallet?.currency.symbol || '',
          fee: calculatedFee?.fee_amount || 0,
          amount: watch('amount'),
          destination_amount: parseFloat(
            fixDecimal({
              value: (watch('amount') || 0) - (calculatedFee?.fee_amount || 0),
            })
          ),
        }}
        onClose={onClose}
        isOpen={isOpen}
        loading={approveLoading}
        onButton={(code) => {
          approve({ id: withdrawDetail?.id || '', data: { code: code } });
          onClose();
          reset();
        }}
      />
    </Box>
  );
};

export default BankTransferTab;
