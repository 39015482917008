import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useProposalRate = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string; data: { rate: number } }
> => {
  return useMutation(({ id, data }) =>
    api.put(`${url.proposalRate}/${id}/rate`, data)
  );
};
export default useProposalRate;
