export const Button = {
  baseStyle: {
    borderRadius: 'lg',
    fontWeight: '400',
    fontSize: 'md',
  },
  sizes: {},
  variants: {
    ghost: {
      color: 'primary',
      borderRadius: '5px',
      px: '4',
      py: '3',
      _hover: {
        bg: 'lightPrimary',
      },
    },
    filled: {
      bgColor: 'primary',
      color: 'body',
      shadow: 'sm',
      px: '4',
      py: '3',
      _hover: {
        bg: 'darkPrimary',
      },
    },
    outline: {
      border: '1px solid',
      borderColor: 'body2',
      bgColor: 'body',
      color: 'body2',
      px: '8',
      py: '3',
      _hover: {
        color: 'secondary',
      },
    },
    wide: {
      bgColor: 'primary',
      color: 'body',
      shadow: 'sm',
      px: '137',
      py: '3',
      _hover: {
        bg: 'darkPrimary',
      },
    },
  },
};
