import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { ReactComponent as MagixGroupLogo } from 'assets/icons/magixGroupLogo.svg';

const MagixService: React.FC = () => {
  return (
    <Box
      bg="secondary"
      width="full"
      height="100%"
      minH="100vh"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="flex-start"
      p={4}
      gap={6}
    >
      <Box py={4}>
        <MagixGroupLogo />
      </Box>
      <Stack align="center" width="full">
        <Outlet />
      </Stack>
    </Box>
  );
};
export default MagixService;
