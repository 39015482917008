import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAccess, useRequestsTabs } from 'hooks';
import { useTranslation } from 'localization';

import { Tabs } from 'components';

import { Deposits, Head, Withdraws } from './components';

const Requests: React.FC = () => {
  const { t } = useTranslation('page.requests');

  const access = useAccess();

  const { active, setActive } = useRequestsTabs();

  return (
    <Box w="100%">
      <Head />
      <Box my={{ base: 0, lg: 4 }}>
        <Tabs
          isLazy
          index={active}
          onChange={(activeIndex) => setActive(activeIndex)}
          tabData={[
            {
              label: t('tabs.deposits'),
              content: <Deposits />,
              isActive: access.depositLDRequestListPermission,
            },
            {
              label: t('tabs.withdraws'),
              content: <Withdraws />,
              isActive: access.withdrawLDRequestsListPermission,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Requests;
