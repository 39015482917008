import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useCreateCryptoWhitelist } from 'hooks';
import { useTranslation } from 'localization';
import { TransactionMethodKey } from 'types';

import { AddCrypto } from './components';

type PayloadType = {
  title?: string;
  wallet_address: string;
};

type AddCryptoWhitelistType = {
  wallet: string;
  transaction_method: { id: string; key: TransactionMethodKey | '' };
  onClose: () => void;
  onSuccess: () => void;
  isOpen: boolean;
};

const AddCryptoWhitelist: React.FC<AddCryptoWhitelistType> = ({
  wallet,
  onClose,
  onSuccess,
  isOpen,
  transaction_method,
}: AddCryptoWhitelistType) => {
  const { t } = useTranslation('component.withdraw.crypto.initModal');

  const { mutateAsync: submit } = useCreateCryptoWhitelist();

  const onSubmit = async (data: PayloadType) => {
    submit({ ...data, wallet, transaction_method: transaction_method.id }).then(
      () => {
        onSuccess();
        onClose();
      }
    );
  };

  return (
    <Box>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              {t('modalTitle')}
            </Text>
          </ModalHeader>
          <ModalBody px={4} overflowY="auto">
            <AddCrypto
              onSubmit={async (data) => onSubmit(data)}
              trMethodKey={transaction_method.key}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddCryptoWhitelist;
