import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  CreateWithdrawBankTransfer,
  CreateWithdrawBankTransferResponse,
  ErrorResponse,
} from 'types';

import { api } from 'utils';

const useWithdrawBankTransfer = (): UseMutationResult<
  CreateWithdrawBankTransferResponse,
  ErrorResponse,
  CreateWithdrawBankTransfer
> => {
  return useMutation((data) => api.post(url.withdrawBankTransfer, data));
};

export default useWithdrawBankTransfer;
