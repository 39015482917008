/* eslint-disable react/prop-types */
import QRCode from 'react-qr-code';
import { Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Metadata } from 'types';

import { CopyClipBoard } from 'components';

type BankMetadataProps = {
  metadata: Metadata;
};

const BankMetadata: React.FC<BankMetadataProps> = ({ metadata }) => {
  const { t } = useTranslation('component.deposit');
  return (
    <Stack px={8} py={4}>
      <Stack
        p={4}
        borderRadius={8}
        border="1px dashed"
        borderColor="darkPrimary"
        bgColor="lightPrimary"
      >
        <Stack
          flexDir={{ base: 'column', xl: 'row' }}
          w="100%"
          justify="space-between"
          align={{ base: 'flex-start', xl: 'center' }}
        >
          <Stack justify="space-between">
            {metadata?.holder_name && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('holderName')} : `}</Text>
                <Text>{metadata?.holder_name}</Text>
              </Stack>
            )}
            {metadata?.phone && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('phone')} : `}</Text>
                <CopyClipBoard
                  initialValue={metadata?.phone || ''}
                  showCharacterLength={24}
                  phoneFormatter={true}
                />
              </Stack>
            )}
            {metadata?.account_id && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('account_id')} : `}</Text>
                <CopyClipBoard
                  initialValue={metadata?.account_id || ''}
                  showCharacterLength={24}
                />
              </Stack>
            )}
            {metadata?.bank_name && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('bank_name')} : `}</Text>
                <Text>{metadata?.bank_name || ''}</Text>
              </Stack>
            )}
            {metadata?.SWIFT && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('SWIFT')} : `}</Text>
                <CopyClipBoard
                  initialValue={metadata?.SWIFT || ''}
                  showCharacterLength={24}
                />
              </Stack>
            )}
            {metadata?.country && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('country')} : `}</Text>
                <Text>{metadata?.country || ''}</Text>
              </Stack>
            )}
            {metadata?.city && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('city')} : `}</Text>
                <Text>{metadata?.city || ''}</Text>
              </Stack>
            )}
            {metadata?.address && (
              <Stack
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
                minH="42px"
              >
                <Text color="gray">{`${t('address')} : `}</Text>
                <Text>{metadata?.address || ''}</Text>
              </Stack>
            )}
          </Stack>
          {metadata?.qr_code && (
            <Stack
              w={{ base: '100%', xl: 'unset' }}
              align="center"
              pt={{ base: 4, md: 0 }}
            >
              <QRCode
                size={256}
                style={{
                  height: 'auto',
                  maxWidth: '140px',
                  width: '140px',
                }}
                value={metadata.qr_code || ''}
                viewBox={'0 0 256 256'}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BankMetadata;
