import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CryptoWhitelist, ErrorResponse, ListResponse } from 'types';

const useCryptoWhiteList = ({
  queries,
  enabled,
}: {
  queries: {
    transaction_method?: string;
    wallet?: string;
  };
  enabled?: boolean;
}): UseQueryResult<ListResponse<CryptoWhitelist>, ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.cryptoWhitelist}${queryParams}`], {
    enabled: enabled,
  });
};
export default useCryptoWhiteList;
