import React from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
  TabsProps,
} from '@chakra-ui/react';

export type TabsPropTypes = Omit<TabsProps, 'children'> & {
  tabData: Array<{
    label: string;
    content: React.ReactElement;
    isActive?: boolean;
  }>;
};

const Tabs: React.FC<TabsPropTypes> = ({ tabData, ...rest }) => {
  return (
    <TabsChakra align="start" variant="enclosed" {...rest}>
      <TabList
        borderBottom="1px solid"
        borderBottomColor="body2"
        w="100%"
        h="100%"
        overflowX="auto"
        overflowY="hidden"
      >
        {tabData
          .filter((item) => item.isActive)
          .map((tab, index) => (
            <Tab
              w={{ base: '100%', md: 'auto' }}
              minW="max-content"
              fontSize={{ base: 'sm', xl: 'md' }}
              color="body2"
              fontWeight="medium"
              textTransform="capitalize"
              _active={{ background: 'none' }}
              pb="4"
              _selected={{
                color: 'body',
                borderBottom: '3px solid',
                borderBottomColor: 'primary',
              }}
              key={index}
              px={{ base: 2, md: 4 }}
            >
              {tab.label}
            </Tab>
          ))}
      </TabList>
      <TabPanels borderColor="body2">
        {tabData
          .filter((item) => item.isActive)
          .map((tab, index) => (
            <TabPanel p="0" key={index}>
              {tab.content}
            </TabPanel>
          ))}
      </TabPanels>
    </TabsChakra>
  );
};

export default Tabs;
