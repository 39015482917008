import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ReactComponent as StarActive } from 'assets/icons/starActive.svg';

const LevelRequirements: React.FC<{
  title: string | undefined;
  requirement: string;
}> = ({ title, requirement }) => {
  if (title) {
    return (
      <Box
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
        my={8}
        px={2}
        borderRadius={10}
        border="1px solid grey"
        gap={2}
      >
        <Box
          bgColor={{ base: 'transparent', lg: 'lightPrimary' }}
          py={{ base: 2, lg: 4 }}
          px={1}
        >
          <StarActive />
        </Box>
        <Box
          w="100%"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          justifyContent={{ base: 'center', lg: 'space-between' }}
          py={4}
        >
          <Text color="body2">Requirements for {title}:</Text>
          <Text>{requirement}</Text>
        </Box>
      </Box>
    );
  }

  return <Box />;
};

export default LevelRequirements;
