import React from 'react';
import { Button as CkButton, ButtonProps } from '@chakra-ui/react';

type BtnTypes = {
  title?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'wide' | 'ghost' | 'filled' | 'outline';
} & ButtonProps;

const Button: React.FC<BtnTypes> = ({
  title,
  size = 'md',
  variant = 'filled',
  ...props
}) => {
  return (
    <CkButton size={size} variant={variant} h={12} {...props}>
      {title}
    </CkButton>
  );
};

export default Button;
