import { Box, HStack, Text } from '@chakra-ui/react';

const Head: React.FC<{ title: string }> = ({ title }: { title: string }) => {
  return (
    <Box p={{ base: 4, md: 0 }}>
      <HStack
        justify={{ base: 'center', md: 'space-between' }}
        py={{ base: 2, md: 0 }}
        px={{ base: 4, lg: 0 }}
      >
        <Text
          fontSize={{ base: '3xl', lg: '5xl' }}
          fontWeight="500"
          display={{ base: 'none', md: 'inline' }}
        >
          {title}
        </Text>
      </HStack>
    </Box>
  );
};

export default Head;
