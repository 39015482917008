import { useNavigate } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { useKycLevel1 } from 'hooks';
import { useTranslation } from 'localization';

import { Button } from 'components';

const SumSub = () => {
  const { t } = useTranslation('component.dashboard.sumsub');
  const navigate = useNavigate();

  const { mutateAsync: upgradeLevel1, data: kycData } = useKycLevel1();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCloseHandler = () => {
    navigate(0);
    onClose();
  };

  return (
    <Box w="100%" bg="lightPrimary">
      <Stack
        justify="space-between"
        w="full"
        px={{ base: '6', lg: '10' }}
        py="7"
        borderRadius="lg"
        direction={{ base: 'column', lg: 'row' }}
        align="center"
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          gap="6"
          align="center"
          mb={{ base: '4', lg: '0' }}
        >
          <Warning />
          <Text color="warning">{t('sumSubUpgradeHint')}</Text>
        </Stack>
        <Button
          variant="filled"
          title="Verify"
          w="167px"
          onClick={() => upgradeLevel1([]).then(() => onOpen())}
        />
      </Stack>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => onCloseHandler()}
        size="full"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('upgradeToLvl1')}</ModalHeader>
          <ModalCloseButton right={4} left="unset" />
          <ModalBody p={0} bg="sumsub">
            {kycData && (
              <SumsubWebSdk
                accessToken={kycData.token}
                expirationHandler={() => onCloseHandler()}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default SumSub;
