/* eslint-disable max-len */
import { Link } from 'react-router-dom';
import {
  Box,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { ReactComponent as Deposit } from 'assets/icons/deposit.svg';
import { ReactComponent as ExchangeIcon } from 'assets/icons/exchange.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw.svg';
import { useAccess, useWallets } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Spinner } from 'components';
import { fixDecimal } from 'utils';

const WalletsTab: React.FC = () => {
  const { t } = useTranslation('component.wallet.tabs.wallets');
  const { data: wallets, isLoading } = useWallets({ queries: {} });

  const access = useAccess();

  const disabledDepositOrWithdraw = [
    'MATIC',
    'MKR',
    'BAT',
    'CRV',
    'SUI',
    'BCH',
    'YFI',
    'ADA',
    'DAI',
    'LINK',
    'SUSHI',
    'ZEC',
    'COMP',
    'XRP',
    'SOL',
    'SHIB',
    'LTC',
    'USDC',
    'UNI',
    'GRT',
    'AAVE',
    'SNX',
  ];

  return (
    <VStack mx={{ base: 4, lg: 0 }}>
      <Box
        w="100%"
        py={6}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontWeight="bold" fontSize={{ base: 'lg', lg: '3xl' }}>
          {t('allWallets')}
        </Text>
      </Box>
      <Box w="100%" display={{ base: 'none', xl: 'block' }}>
        {isLoading && (
          <HStack justify="center" w="100%">
            <Spinner />
          </HStack>
        )}
        {wallets && wallets.list?.length > 0 && (
          <Table size="sm">
            <Thead>
              <Th>{t('currency')}</Th>
              <Th textAlign="center">{t('freezed')}</Th>
              <Th textAlign="center">{t('amount')}</Th>
              <Th textAlign="center">{t('actions')}</Th>
            </Thead>
            <Tbody>
              {wallets?.list
                ?.sort((a, b) =>
                  a.currency.symbol === 'USDT'
                    ? -1
                    : b.currency.symbol === 'USDT'
                      ? 1
                      : a.currency.symbol.localeCompare(b.currency.symbol)
                )
                ?.sort((a, b) => b.amount - a.amount)
                .map((wallet) => {
                  return (
                    <Tr key={wallet.id}>
                      <Td textAlign="center">
                        <Stack flexDir="row" align="center" gap={4}>
                          <Box
                            width="40px"
                            height="40px"
                            borderRadius={50}
                            overflow="hidden"
                          >
                            <img
                              src={wallet.currency.icon}
                              alt={wallet.currency.title}
                              width="40px"
                              height="40px"
                            />
                          </Box>
                          <Box>{wallet.currency.title}</Box>
                        </Stack>
                      </Td>
                      <Td textAlign="center">
                        {fixDecimal({
                          value: wallet.frozen_amount,
                          fractionDigits: wallet.type === 'CRYPTO' ? 5 : 2,
                        })}
                      </Td>
                      <Td textAlign="center">
                        {fixDecimal({
                          value: wallet.amount - wallet.frozen_amount,
                          fractionDigits: wallet.type === 'CRYPTO' ? 5 : 2,
                        })}
                      </Td>
                      <Td textAlign="center">
                        <HStack align="center" justify="flex-end">
                          <Box p={2}>
                            <Link
                              to={`${routes.exchange.url}?wallet=${wallet.id}`}
                            >
                              <ExchangeIcon />
                            </Link>
                          </Box>
                          {!disabledDepositOrWithdraw.includes(
                            wallet.currency.symbol
                          ) &&
                            access.withdrawService && (
                              <Box p={2}>
                                <Link
                                  to={`${routes.withdraw.url}?tab=0&wallet=${wallet.id}&currency=${wallet.currency.id}`}
                                >
                                  <Withdraw cursor="pointer" />
                                </Link>
                              </Box>
                            )}
                          {!disabledDepositOrWithdraw.includes(
                            wallet.currency.symbol
                          ) && (
                            <Box p={2}>
                              <Link
                                to={`${routes.deposit.url}?tab=0&wallet=${wallet.id}&currency=${wallet.currency.id}`}
                              >
                                <Deposit cursor="pointer" />
                              </Link>
                            </Box>
                          )}
                          <Box p={2}>
                            <Link
                              to={`${routes.wallets.url}?tab=transaction&wallet=${wallet.currency.id}`}
                            >
                              <HistoryIcon />
                            </Link>
                          </Box>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
      </Box>
      <VStack w="100%" display={{ xl: 'none' }}>
        {wallets?.list
          ?.sort((a, b) => b.amount - a.amount)
          ?.map((wallet) => {
            return (
              <VStack
                my="2"
                key={wallet.id}
                p="4"
                bg="secondary"
                borderRadius="lg"
                align="flex-start"
                gap="4"
              >
                <VStack w="100%" align="center" justify="space-between">
                  <HStack w="100%" justify="space-between" align="center">
                    <Text fontSize="md" fontWeight="bold" color="body">
                      {wallet.currency.symbol}
                    </Text>
                    <Box
                      width="40px"
                      height="40px"
                      borderRadius={50}
                      overflow="hidden"
                    >
                      <img
                        src={wallet.currency.icon}
                        alt={wallet.currency.symbol}
                        width="40px"
                        height="40px"
                      />
                    </Box>
                  </HStack>
                  <HStack w="100%" justify="space-between" align="center">
                    <Text color="body2">Freezed Balance</Text>
                    <Text fontSize="sm" fontWeight="bold" color="body">
                      {fixDecimal({
                        value: wallet.frozen_amount,
                        fractionDigits: wallet.type === 'CRYPTO' ? 5 : 2,
                      })}
                    </Text>
                  </HStack>
                  <HStack w="100%" justify="space-between" align="center">
                    <Text color="body2">Amount Balance</Text>
                    <Text fontSize="sm" fontWeight="bold" color="body">
                      {fixDecimal({
                        value: wallet.amount,
                        fractionDigits: wallet.type === 'CRYPTO' ? 5 : 2,
                      })}
                    </Text>
                  </HStack>
                  <HStack w="100%" justify="space-between" align="center">
                    <Box p={2}>
                      <Link to={`${routes.exchange.url}?wallet=${wallet.id}`}>
                        <ExchangeIcon />
                      </Link>
                    </Box>
                    {!disabledDepositOrWithdraw.includes(
                      wallet.currency.symbol
                    ) &&
                      access.withdrawService && (
                        <Box p={2}>
                          <Link
                            to={`${routes.withdraw.url}?tab=0&wallet=${wallet.id}&currency=${wallet.currency.id}`}
                          >
                            <Withdraw cursor="pointer" />
                          </Link>
                        </Box>
                      )}
                    {!disabledDepositOrWithdraw.includes(
                      wallet.currency.symbol
                    ) && (
                      <Box p={2}>
                        <Link
                          to={`${routes.deposit.url}?tab=0&wallet=${wallet.id}&currency=${wallet.currency.id}`}
                        >
                          <Deposit cursor="pointer" />
                        </Link>
                      </Box>
                    )}
                    <Box p={2}>
                      <Link
                        to={`${routes.wallets.url}?tab=transaction&wallet=${wallet.currency.id}`}
                      >
                        <HistoryIcon />
                      </Link>
                    </Box>
                  </HStack>
                </VStack>
              </VStack>
            );
          })}
      </VStack>
    </VStack>
  );
};

export default WalletsTab;
