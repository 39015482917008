import React, { useState } from 'react';
import {
  Icon,
  Input as InputChakra,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from 'assets/icons/magnifying-glass.svg';

const Search = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...rest }, forwardedRef) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const [filled, setFilled] = useState<boolean>(false);
    return (
      <InputGroup ref={ref}>
        <InputRightElement
          color={filled ? 'body' : 'body2'}
          borderColor="body2"
        >
          <Icon
            as={SearchIcon}
            h="10"
            w="10"
            borderColor="body2"
            stroke={filled ? 'body' : 'body2'}
          />
        </InputRightElement>
        <InputChakra
          placeholder="Search"
          height="10"
          w="full"
          border="1px solid"
          borderColor="body2"
          borderRadius="lg"
          background="dark"
          color="secondary2"
          fontSize="md"
          onKeyDown={() => setFilled(true)}
          onFocus={() => setFilled(false)}
          _placeholder={{ color: 'body2' }}
          _focus={{
            borderColor: 'lightPrimary',
          }}
          ref={forwardedRef}
          {...rest}
        />
      </InputGroup>
    );
  }
);

Search.displayName = 'Search';
export default Search;
