import React from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';
import { emailRegex } from 'utils/regex';

type PayloadType = { username: string; password: string };
/* eslint-disable no-unused-vars */
type FormType = {
  onButton: (data: PayloadType) => void;
  defaultEmail?: string;
};
const Form: React.FC<FormType> = ({ onButton, defaultEmail }: FormType) => {
  const { t } = useTranslation('component.auth.login.form');

  const schema = yup.object().shape({
    username: yup
      .string()
      .email()
      .lowercase()
      .matches(emailRegex, t('email_validate'))
      .label('username')
      .required(),
    password: yup.string().label('password').required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (data: PayloadType) => {
    onButton(data);
  };

  React.useEffect(() => {
    if (defaultEmail) {
      setValue('username', defaultEmail);
    }
  }, [defaultEmail]);

  const formProps = useInputAttributes(register, errors, {
    username: t('username'),
    password: t('password'),
  });
  return (
    <Box
      px={{ base: 2, sm: 0 }}
      w={{ base: '100%', sm: 312 }}
      mt={{ base: 2, md: 20 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={6}>
          <Input {...formProps.username} isDisabled={Boolean(defaultEmail)} />
        </Box>
        <Box>
          <Input type="password" {...formProps.password} />
        </Box>
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={t('login')}
          minW="100%"
          mt={{ base: 8, md: 8 }}
        />
      </form>
    </Box>
  );
};

export default Form;
