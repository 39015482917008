import React from 'react';

import { DepositProcess, DepositResult } from './components';

const XMDeposit: React.FC = () => {
  const [transactionResult, setTransactionResult] = React.useState<{
    status: 'success' | 'cancel';
    redirectUrl: string;
  } | null>(null);

  if (transactionResult?.status) {
    return <DepositResult transactionResult={transactionResult} />;
  }
  return (
    <DepositProcess
      onAction={(response) => {
        setTransactionResult(response);
      }}
    />
  );
};

export default XMDeposit;
