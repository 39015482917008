import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateContact, ErrorResponse } from 'types';

import { api } from 'utils';

const useCreateContact = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  CreateContact
> => {
  return useMutation((data) => api.post(url.createContact, data));
};

export default useCreateContact;
