import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { Contact, ErrorResponse, ListResponse } from 'types';

const useContacts = (): UseQueryResult<
  ListResponse<Contact>,
  ErrorResponse
> => {
  return useQuery([url.contacts]);
};
export default useContacts;
