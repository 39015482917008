import { theme } from '@chakra-ui/react';
import { type ThemeConfig } from '@chakra-ui/react';

import { colors } from './colors';
import { ComponentGS } from './components';

const themeConfig = {
  ...theme,
  styles: {
    global: {
      'html, body': {
        userSelect: 'none',
        _focusVisible: { boxShadow: 'none' },
      },
    },
  },
  shadows: {
    outline: 'none',
    mobileSm: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    sm: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    md: '0px 6px 20px -2px rgba(0, 0, 0, 0.2)',
    lg: '0px 15px 50px -10px rgba(0, 0, 0, 0.3)',
    box: '0px 0px 4px rgba(0, 0, 0, 0.24)',
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Satoshi, sans-serif',
  },
  fontSizes: {
    '3xl': '32px',
    '2xl': '24px',
    xl: '20px',
    lg: '18px',
    md: '16px',
    sm: '14px',
    xs: '12px',
    xxs: '10px',
  },
  config: {
    cssVarPrefix: 'ck',
  } as ThemeConfig,
  colors,
  components: {
    ...ComponentGS,
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: 'black',
        },
      },
      // variants: {
      //   floating: {
      //     container: {
      //       label: {
      //         top: 0,
      //         right: 0,
      //         zIndex: 2,
      //         position: 'absolute',
      //         backgroundColor: 'white',
      //         pointerEvents: 'none',
      //         mx: 6,
      //         px: 3,
      //         my: 2,
      //         transform: 'scale(0.85) translateY(-24px)',
      //         width: 'fit-content',
      //       },
      //     },
      //   },
      // },
    },
    Modal: {
      parts: ['closeButton'],
      baseStyle: {
        closeButton: {
          right: 'unset',
          left: '2',
        },
      },
    },
    Switch: {
      baseStyle: {
        thumb: {
          background: 'body2',
          width: '20px',
          height: '20px',
          _checked: {
            background: 'primary',
            marginLeft: '4px',
          },
        },
        track: {
          background: 'white',
          width: '36px',
          height: '18px',
          alignItems: 'center',
          padding: 0,
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          background: 'body2',
          width: 6,
          height: 6,
          borderColor: 'body2',
          bg: 'transparent',
          borderRadius: 4,
          _checked: { borderColor: 'primary' },
        },
        icon: {
          color: 'primary',
        },
      },
    },
    Container: {
      baseStyle: {
        maxWidth: '1440px',
        py: 0,
        px: 0,
      },
    },
    Heading: {
      size: {
        '3xl': '32px',
        '2xl': '24px',
        xl: '20px',
        lg: '18px',
        md: '16px',
        sm: '14px',
        xs: '12px',
      },
      baseStyle: {
        fontFamily: 'inherit',
        pb: { base: 4, md: 8 },
      },
    },
    Link: {
      baseStyle: {
        _hover: { textDecoration: 'none', color: 'primary.500' },
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
        color: 'body',
      },
    },
    Select: {
      baseStyle: {
        icon: {
          position: 'absolute',
          right: 'calc(100% - 30px)',
        },
      },
    },
  },
};

export { themeConfig };
