import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useLogout = (): UseMutationResult<any, ErrorResponse> => {
  return useMutation(() => api.post(url.logout));
};
export default useLogout;
