import { Stack } from '@chakra-ui/react';

import { downloadBase64, standaloneToast } from 'utils';

import Button from '../‌Button';

type DownloadCardProps = {
  file: string;
};

const DownloadCard: React.FC<DownloadCardProps> = ({
  file,
}: DownloadCardProps) => {
  return (
    <Stack w="100%">
      {file
        .substring('data:image/'.length, file.indexOf(';base64'))
        .includes('pdf') ? (
        ''
      ) : (
        <img src={file} alt="bank-receipt" style={{ width: '100%' }} />
      )}
      <Button
        title="Download File"
        p={1}
        fontSize="sm"
        variant="filled"
        onClick={() => {
          downloadBase64({ title: 'payment', file });
          standaloneToast({
            position: 'top-right',
            description: 'File downloaded',
            status: 'info',
            duration: 2000,
            isClosable: true,
          });
        }}
      />
    </Stack>
  );
};
export default DownloadCard;
