import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, ListResponse, Proposal } from 'types';

const useProposalsList = ({
  transaction_method_category,
}: {
  transaction_method_category: Proposal['transaction_method_category'];
}): UseQueryResult<ListResponse<Proposal>, ErrorResponse> => {
  return useQuery([
    url.proposalList,
    `?transaction_method_category=${transaction_method_category}`,
  ]);
};
export default useProposalsList;
