/* eslint-disable no-unused-vars */
import { FieldErrors } from 'react-hook-form';

type attrType = (
  register: any,
  errors: FieldErrors,
  fields: Record<string, string>,
  errorExclude?: string[]
) => Record<string, any>;

const useInputAttributes: attrType = (
  register,
  errors = {},
  fields,
  errorExclude = []
) => {
  return Object.fromEntries(
    Object.entries(fields).map(([key, label]) => [
      key,
      {
        name: key,
        error: errorExclude.includes(key) ? '' : errors[key]?.message,
        id: key,
        placeholder: label,
        label: label,
        ...register(key),
      },
    ])
  );
};

export default useInputAttributes;
