const downloadBase64 = ({
  file,
  title,
}: {
  file: string;
  title: string;
}): void => {
  const a = document.createElement('a');
  a.href = file;
  a.download = title + '.' + file.split('/')[1].split(';')[0];
  a.click();
};

export default downloadBase64;
