import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, SystemIdentifier } from 'types';

import { api } from 'utils';

const useExternalUsersVerify = (): UseMutationResult<
  [],
  ErrorResponse,
  { system_identifier: SystemIdentifier }
> => {
  return useMutation(({ system_identifier }) =>
    api.post(`${url.externalUserVerify}/${system_identifier}/verify`)
  );
};
export default useExternalUsersVerify;
