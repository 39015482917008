import { Global } from '@emotion/react';

const GlobalStyle = () => (
  <Global
    styles={`
      @font-face {
        font-weight: 200;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-ExtraLightBETA.ttf') format('truetype');
      } 
      // @font-face {
      //   font-weight: 200;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-ExtraLightItalicBETA.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 300;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-Light.ttf') format('truetype');
      } 
      // @font-face {
      //   font-weight: 300;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-LightItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 300;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-LightBETA.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 300;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-LightItalicBETA.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 400;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-Regular.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 400;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-Italic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 500;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-Medium.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 500;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-MediumItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 600;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-SemiBold.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 600;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-SemiBoldItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 700;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-Bold.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 700;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-BoldItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 800;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-ExtraBold.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 800;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-ExtraBoldItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 900;
        font-family: Inter;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Inter-Black.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 900;
      //   font-family: Inter;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Inter-BlackItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 400;
        font-family: Satoshi;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Satoshi-Regular.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 400;
      //   font-family: Satoshi;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Satoshi-Italic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 500;
        font-family: Satoshi;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Satoshi-Medium.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 500;
      //   font-family: Satoshi;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Satoshi-MediumItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 700;
        font-family: Satoshi;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Satoshi-Bold.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 700;
      //   font-family: Satoshi;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Satoshi-BoldItalic.ttf') format('truetype');
      // }
      @font-face {
        font-weight: 900;
        font-family: Satoshi;
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Satoshi-Black.ttf') format('truetype');
      }
      // @font-face {
      //   font-weight: 900;
      //   font-family: Satoshi;
      //   font-style: normal;
      //   font-display: swap;
      //   src: url('/fonts/Satoshi-BlackItalic.ttf') format('truetype');
      // }


      .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
      }
                  
      *{
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      }
      *:focus {
        box-shadow: none !important;
      }
      
      *[data-focus] {
        box-shadow: none !important;
      }
        
      .tradingview-widget-copyright{
        display: none;
      }
      `}
  />
);

export default GlobalStyle;
