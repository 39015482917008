import YouTubePlayer from 'react-player/youtube';
import { useNavigate } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { routes } from 'statics';
import { Profile } from 'types';

import { Button } from 'components';

type YoutubeFrameType = { profile: Profile };

type Countries = 'IRQ' | 'TUR' | 'SAU';

const YoutubeFrame: React.FC<YoutubeFrameType> = ({
  profile,
}: YoutubeFrameType) => {
  const { t } = useTranslation('component.dashboard.youtubeFrame');

  const navigate = useNavigate();

  const links = {
    SAU: {
      currency: 'USD using Saudi Riyal',
      title: t('titleSAU'),
      rightAlign: true,
      link: 'https://youtu.be/yMm1yCZNOlc',
    },
    TUR: {
      currency: 'Lira',
      title: t('titleTUR'),
      rightAlign: false,
      link: 'https://youtu.be/UIl7VYs9tt0',
    },
    IRQ: {
      currency: 'USD using Iraqi Dinar',
      title: t('titleIRQ'),
      rightAlign: true,
      link: 'https://youtu.be/iG2JlqvW3OI',
    },
    PAK: {
      currency: 'USD using Pakistan Rupee',
      title: t('titlePAK'),
      rightAlign: false,
      link: 'https://youtu.be/uJeLsxUsuL0',
    },
  };

  const canShow =
    Object.keys(links).includes(profile.country.cca3 as Countries) &&
    ['LEVEL1', 'LEVEL2'].includes(profile.role.title);

  const countryData = links[profile.country.cca3 as Countries];

  return canShow ? (
    <Stack
      w="100%"
      flexDir="column"
      align="center"
      justify="center"
      bg="dark"
      borderRadius={10}
      overflow="hidden"
      gap={8}
      p={8}
    >
      <Text
        w="100%"
        textAlign={countryData.rightAlign ? 'right' : 'left'}
        fontSize={{ base: 'lg', md: 'xl' }}
      >
        {countryData.title}
      </Text>
      <Stack w={{ base: '100%', xl: '60%' }}>
        <YouTubePlayer url={countryData.link} width="100%" />
      </Stack>
      <Button
        title={[t('deposit'), countryData.currency].join(' ')}
        onClick={() => navigate(routes.deposit.url)}
      />
    </Stack>
  ) : (
    ''
  );
};
export default YoutubeFrame;
