import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as WithdrawIcon } from 'assets/icons/transfer.svg';
import { useTimer, useWithdrawSendOtp } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { fixDecimal, yup } from 'utils';

type ApproveModalType = {
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
  data: {
    id: string;
    symbol: string;
    fee: number;
    amount: number;
    destination_amount: number;
  };
  // eslint-disable-next-line no-unused-vars
  onButton: (code: string) => void;
};

const ApproveModal: React.FC<ApproveModalType> = ({
  onClose,
  isOpen,
  data,
  loading,
  onButton,
}: ApproveModalType) => {
  const { t } = useTranslation('component.withdraw.modal');
  const { counter, setCounter } = useTimer(0);

  const schema = yup.object().shape({
    code: yup.string().label(t('form.code')).required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: { code: string }) => {
    onButton(data.code);
    reset();
  };

  const formProps = useInputAttributes(register, errors, {
    code: t('form.code'),
  });

  const { mutateAsync: requestToGetOtp, isLoading } = useWithdrawSendOtp();

  React.useEffect(() => {
    if (isOpen) {
      setCounter(120);
    }
  }, [isOpen]);

  return (
    <Box display="flex" alignItems="center">
      <Modal
        onClose={() => {
          reset();
          onClose();
        }}
        size="xl"
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalBody py={10} px={4} overflowY="auto">
            <VStack align="center" justify="flex-start" h="100%">
              <VStack w="100%" align="center" justify="center" flex="auto">
                <WithdrawIcon />
                <Text fontSize="4xl" fontWeight="500">
                  {`${data.destination_amount} ${data.symbol}`}
                </Text>
              </VStack>
              <TableContainer w="100%" overflowX="auto">
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td textAlign="left">{t('amount')}</Td>
                      <Td textAlign="right">{`${data.amount} ${data.symbol}`}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="left">{t('fee')}</Td>
                      <Td textAlign="right">
                        {fixDecimal({ value: data.fee })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="left">{t('destination_amount')}</Td>
                      <Td textAlign="right">{`${data.destination_amount}  ${data.symbol}`}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Box w="100%">
                <Alert status="info">
                  <Text w="100%" textAlign="center" color="info">
                    {t('codeSent')}
                  </Text>
                </Alert>
              </Box>
              <Box w="100%">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', lg: 'row' }}
                    gap={4}
                  >
                    <Input
                      {...formProps.code}
                      textAlign="center"
                      placeholder="      -      -      -      -      -      -      "
                      type="number"
                      min="0"
                      maxLength="6"
                      minLength="6"
                    />
                    <Button
                      variant="filled"
                      display="block"
                      title={
                        counter > 0 ? '( ' + counter + ' )' : t('sendCode')
                      }
                      isDisabled={counter > 0}
                      width={{ base: '100%', lg: '200px' }}
                      isLoading={isLoading}
                      onClick={() =>
                        requestToGetOtp([]).then(() => {
                          setCounter(120);
                        })
                      }
                      mt={{ base: 0, lg: 8 }}
                    />
                  </Box>
                  <Button
                    w="100%"
                    type="submit"
                    isLoading={loading}
                    variant="filled"
                    title={t('confirm')}
                    mt={4}
                  />
                </form>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ApproveModal;
