import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as AddIcon } from 'assets/icons/addNewAccount.svg';
import {
  useBankAccounts,
  useDepositBankTransfer,
  useFee,
  useRate,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import DestinationAmount from 'pages/Withdraw/components/DestinationAmount';
import { routes } from 'statics';
import { ListResponse, Profile, TransactionMethod, Wallet } from 'types';

import {
  AddBankAccount,
  BankTransferHint,
  Button,
  CopyClipBoard,
  Input,
  Uploader,
} from 'components';
import { yup } from 'utils';

import { BankMetadata } from './components';

type PayloadType = {
  transaction_method: string;
  source_bank_account: string;
  amount: number;
  bank_receipt_file: string;
};
type ThumbFileType = File & { preview: string };

type BankTransferTabProps = {
  wallet?: Wallet;
  trMethods: ListResponse<TransactionMethod> | undefined;
  isSuccessTrMethods: boolean;
  isRefetchingTrMethods: boolean;
  profile: Profile | undefined;
};

const BankTransferTab: React.FC<BankTransferTabProps> = ({
  wallet,
  trMethods,
  isSuccessTrMethods,
  isRefetchingTrMethods,
  profile,
}) => {
  const { t } = useTranslation('component.deposit');
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedFile, setSelectedFile] = React.useState<ThumbFileType[]>([]);

  const { mutateAsync: submit, isLoading: submitLoading } =
    useDepositBankTransfer();

  // Manage form in 3 code block
  const schema = yup.object().shape({
    source_bank_account: yup
      .string()
      .label(t('form.source_bank_account'))
      .required(),
    transaction_method: yup
      .string()
      .label(t('form.transaction_method'))
      .required(),
    amount: yup.number().label(t('form.amount')).required(),
    bank_receipt_file: yup
      .string()
      .label(t('form.bank_receipt_file'))
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, {
    source_bank_account: t('form.source_bank_account'),
    transaction_method: t('form.transaction_method'),
    bank_receipt_file: t('form.bank_receipt_file'),
    amount: t('form.amount'),
  });

  // Manage TR methods in 3 code block
  const trMethod =
    trMethods?.list.find((item) => item.id === watch('transaction_method')) ||
    trMethods?.list[0] ||
    null;

  React.useEffect(() => {
    if (isSuccessTrMethods && transactionMethodList.length > 0) {
      setValue('transaction_method', transactionMethodList[0].value);
    }
  }, [isSuccessTrMethods, isRefetchingTrMethods]);

  const transactionMethodList =
    trMethods?.list?.map((transactionMethod) => ({
      label: transactionMethod.title,
      value: transactionMethod.id,
      key: transactionMethod.key,
    })) || [];

  // Manage bank accounts in 2 code block
  const { data: bankAccounts, refetch: refetchBankAccounts } = useBankAccounts({
    queries: trMethod
      ? { wallet: wallet?.id || '', transaction_method: trMethod.id }
      : {},
    enabled: Boolean(trMethod),
  });

  const bankAccountsList =
    bankAccounts?.list?.map((bankAcc) => ({
      label: bankAcc.title,
      value: bankAcc.id,
    })) || [];

  // Manage rate in 3 code block
  const { data: fee } = useFee({
    queries: {
      currency: wallet?.currency.id || '',
      transaction_method: trMethod?.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  const {
    data: rate,
    mutateAsync: getRate,
    isLoading: rateLoading,
  } = useRate();

  React.useEffect(() => {
    if (trMethod) {
      getRate({
        destination_symbol: trMethod.pay_currency.symbol || '',
        source_symbol: wallet?.currency.symbol || '',
        transaction_method_key: trMethod.key,
      });
    }
  }, [trMethod]);

  const onSubmit = async (data: PayloadType) => {
    submit({
      ...data,
      destination_wallet: wallet?.id || '',
      pay_amount: data.amount * (rate?.rate || 0),
    }).then((res) => {
      if (res.id) {
        reset();
        setValue('bank_receipt_file', '');
        setSelectedFile([]);
        navigate(
          `${routes.wallets.url}?tab=transaction&wallet=${
            wallet?.currency.id || ''
          }`
        );
      }
    });
  };

  React.useEffect(() => {
    if (bankAccountsList.length === 1) {
      setValue('source_bank_account', bankAccountsList[0].value);
    }
  }, [bankAccounts]);

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          p={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.transaction_method}
              isSelectBox
              options={transactionMethodList}
              isDisabled={transactionMethodList.length < 1}
            />
          </Box>
        </Box>
        {fee && wallet && (
          <BankTransferHint
            maximum_credit={fee.maximum_credit}
            minimum_credit={fee.minimum_credit}
            maximum={fee.maximum}
            minimum={fee.minimum}
            percent={fee.percent}
            symbol={wallet.currency.symbol}
            type="deposit-bank"
          />
        )}
        {(trMethod?.metadata?.account_id ||
          trMethod?.metadata.qr_code ||
          trMethod?.metadata.holder_name ||
          trMethod?.metadata.phone) && (
          <BankMetadata metadata={trMethod.metadata} />
        )}
        <Box display="flex" alignItems="flex-end" w="100%" p={8} gap={25}>
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.source_bank_account}
              isSelectBox
              options={bankAccountsList}
              isDisabled={bankAccountsList.length < 1}
            />
          </Box>
          {wallet && trMethod && (
            <Box w={{ base: 'max-content', md: '50%' }} mb={4}>
              <IconButton
                size="lg"
                aria-label="Search database"
                icon={<AddIcon cursor="pointer" onClick={() => onOpen()} />}
              />
            </Box>
          )}
        </Box>
        {profile?.identifier_number &&
          !['IQD'].includes(trMethod?.pay_currency.symbol || '') && (
            <Box
              display="flex"
              flexDir={{ base: 'column', md: 'row' }}
              alignItems="center"
              px={8}
              py={4}
            >
              <Text pr={2}>{t('reference')}</Text>
              <CopyClipBoard
                initialValue={profile?.identifier_number.toString() || ''}
              />
            </Box>
          )}
        {trMethod?.metadata?.note && (
          <Box bgColor="lightPrimary" p={4} textAlign="center" w="100%">
            {trMethod?.metadata?.note
              .split('\n')
              .map((item: string, index: number) => {
                return (
                  <Text w="100%" textAlign="left" color="warning" key={index}>
                    {item}
                  </Text>
                );
              })}
          </Box>
        )}
        <Stack
          flexDir={{ base: 'column', xl: 'row' }}
          align="flex-start"
          w="100%"
          px={8}
        >
          <Box w={{ base: '100%', xl: '50%' }} minW="300px">
            <Input
              {...formProps.amount}
              bgColor="lightPrimary"
              border="none"
              borderRadius={5}
              py={10}
              px={10}
              type="number"
              step="0.01"
              min="0"
              fontSize="2xl"
              fontWeight="500"
              _placeholder={{ color: 'grey' }}
            />
          </Box>
          <Box w={{ base: '100%', xl: '50%' }} minW="300px">
            {parseFloat((watch('amount') || 0).toString()) > 0 &&
              wallet &&
              rate?.rate && (
                <DestinationAmount
                  amount={watch('amount')}
                  type="rate"
                  symbol={trMethod?.pay_currency.symbol || ''}
                  rate={rate?.rate || 0}
                  source_symbol={wallet.currency.symbol || ''}
                  loading={rateLoading}
                  isInsufficient={false}
                />
              )}
          </Box>
        </Stack>
        <Box w="100%" p={8}>
          <Uploader
            {...formProps.bank_receipt_file}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            getValue={(value) => setValue('bank_receipt_file', value)}
          />
        </Box>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          <Button
            type="submit"
            variant="filled"
            display="block"
            title={t('submit')}
            minW="100%"
            isDisabled={rateLoading}
            isLoading={submitLoading}
            mt={{ base: 8, md: 8 }}
          />
        </VStack>
      </form>
      <AddBankAccount
        wallet={wallet?.id || ''}
        transactionMethod={trMethod?.id || ''}
        accountFields={trMethod?.account_fields || []}
        onClose={onClose}
        isOpen={isOpen}
        onSuccess={() => refetchBankAccounts()}
      />
    </Box>
  );
};

export default BankTransferTab;
