import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Profile } from 'types';

const useProfile = (
  enabled?: boolean
): UseQueryResult<Profile, ErrorResponse> => {
  return useQuery([url.profile], { enabled: enabled });
};
export default useProfile;
