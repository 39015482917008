import React from 'react';

const useTimer = (remainingTime: number) => {
  const [counter, setCounter] = React.useState(remainingTime);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (timer) {
      return () => {
        clearInterval(timer);
      };
    }
  }, [counter]);

  return { counter, setCounter };
};
export default useTimer;
