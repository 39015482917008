import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, FeeCalculation } from 'types';

import { api } from 'utils';

const useFeeCalculation = (): UseMutationResult<
  FeeCalculation,
  ErrorResponse,
  { data: { amount: number }; id: string }
> => {
  return useMutation(({ data, id }) =>
    api.post(`${url.feeCalculation}/${id}`, data)
  );
};
export default useFeeCalculation;
