import React from 'react';
import { HStack, Text, useClipboard } from '@chakra-ui/react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import { formatPhone, standaloneToast } from 'utils';

type CopyClipBoardProps = {
  initialValue: string;
  showCharacterLength?: number;
  phoneFormatter?: boolean;
  full?: boolean;
};
const CopyClipBoard: React.FC<CopyClipBoardProps> = ({
  initialValue,
  showCharacterLength,
  phoneFormatter = false,
  full = false,
}: {
  initialValue: string;
  showCharacterLength?: number;
  phoneFormatter?: boolean;
  full?: boolean;
}) => {
  const { onCopy, setValue, hasCopied } = useClipboard('');

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <HStack
      border="1px dashed"
      borderRadius={10}
      p={2}
      borderColor="primary"
      onClick={() => {
        onCopy();
        standaloneToast({
          position: 'top-right',
          description: 'Copied to clipboard',
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
      }}
      cursor="pointer"
      justify="space-between"
      w={full ? '100%' : 'unset'}
    >
      <Text
        color={hasCopied ? 'primary' : 'grey'}
        minW="120px"
        maxW={full ? '85%' : 'unset'}
        wordBreak={full ? 'break-all' : 'unset'}
      >
        {showCharacterLength && initialValue.length > showCharacterLength
          ? initialValue.slice(0, showCharacterLength) + '...'
          : phoneFormatter
          ? formatPhone(initialValue)
          : initialValue}
      </Text>
      <CopyIcon />
    </HStack>
  );
};
export default CopyClipBoard;
