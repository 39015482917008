import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Fee } from 'types';

const useFee = ({
  queries,
  enabled,
}: {
  queries: {
    transaction_method: string;
    currency: string;
  };
  enabled?: boolean;
}): UseQueryResult<Fee, ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.fee}${queryParams}`], {
    enabled: enabled,
  });
};
export default useFee;
