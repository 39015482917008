import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProposalRate } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = {
  rate: number;
};

type RateFromProps = {
  proposalId: string;
  refetch: () => void;
};

const RateForm: React.FC<RateFromProps> = ({ proposalId, refetch }) => {
  const { t } = useTranslation('component.requests.proposals.rateForm');

  const { mutateAsync: submit, isLoading: submitLoading } = useProposalRate();

  const schema = yup.object().shape({
    rate: yup.number().label(t('rate')).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    submit({ data: data, id: proposalId }).then(() => {
      reset();
      refetch();
    });
  };

  const formProps = useInputAttributes(register, errors, {
    rate: t('rate'),
  });

  return (
    <Box w="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text>{t('enterRate')}</Text>
        <Box w="100%">
          <Input
            {...formProps.rate}
            label=""
            type="number"
            min="0"
            step="0.01"
            bgColor="lightPrimary"
            border="none"
            borderRadius={5}
            py={10}
            fontSize="4xl"
            fontWeight="500"
            _placeholder={{ color: 'grey' }}
          />
        </Box>
        <Stack
          flexDir="row"
          align="center"
          justify="space-between"
          gap={2}
          pt={4}
        >
          <Button
            type="submit"
            variant="filled"
            display="block"
            title={t('submit')}
            w="100%"
            isLoading={submitLoading}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default RateForm;
