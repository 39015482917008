import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, TransferPayload, TransferResponse } from 'types';

import { api } from 'utils';

const useTransfer = (): UseMutationResult<
  TransferResponse,
  ErrorResponse,
  TransferPayload
> => {
  return useMutation((data) => api.post(url.transfer, data));
};
export default useTransfer;
