import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, SignUpPassword } from 'types';

import { api } from 'utils';

const useSignUpPassword = (
  token: string
): UseMutationResult<SignUpPassword, ErrorResponse> => {
  return useMutation((data) =>
    api.post(url.sendPassword, data, { headers: { 'csrf-token': token } })
  );
};
export default useSignUpPassword;
