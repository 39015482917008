import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import BorderedBox from '../BorderedBox';

const PersonalDetail: React.FC<{
  title: string;
  list: { label: string; value: any }[];
}> = ({ title, list }) => {
  return (
    <BorderedBox title={title}>
      <Box>
        {list.map((item) => {
          return (
            <Box
              key={item.label}
              display="flex"
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent="flex-start"
              flexDir={{ base: 'column', md: 'row' }}
              py={2}
            >
              <Text fontSize="lg" color="body2" pr={2}>
                {item.label} :
              </Text>
              <Text fontSize="lg">{item.value}</Text>
            </Box>
          );
        })}
      </Box>
    </BorderedBox>
  );
};

export default PersonalDetail;
