import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  ErrorResponse,
  GenerateForgotPasswordToken,
  GenerateForgotPasswordTokenResponse,
} from 'types';

import { api } from 'utils';

const useGenerateForgotPasswordToken = (): UseMutationResult<
  GenerateForgotPasswordTokenResponse,
  ErrorResponse,
  GenerateForgotPasswordToken
> => {
  return useMutation((data) => api.post(url.generateForgotPasswordToken, data));
};

export default useGenerateForgotPasswordToken;
