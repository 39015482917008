import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useVerifyServiceWithdraw = (): UseMutationResult<
  any,
  ErrorResponse,
  { data: { code: string }; id: string }
> => {
  return useMutation(({ data, id }: { data: { code: string }; id: string }) =>
    api.put(`${url.verifyServiceWithdraw}/${id}/approve`, data)
  );
};
export default useVerifyServiceWithdraw;
