import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { useProfile, useWallets } from 'hooks';
import { useTranslation } from 'localization';

import { Input } from 'components';

const Head: React.FC = () => {
  const { t } = useTranslation('component.deposit');
  const [searchparams, setSearchParams] = useSearchParams();

  const { data: wallets } = useWallets({ queries: {} });

  const { data: profile } = useProfile();

  const defaultWallet = profile?.country?.cca3 === 'IRQ' ? 'USD' : 'USDT';

  const walletList =
    wallets?.list?.map((wallet) => ({
      label: wallet.currency.title,
      value: wallet.id,
      currency_id: wallet.currency.id,
      symbol: wallet.currency.symbol,
    })) || [];

  const onChangeWallet = ({ targetValue }: { targetValue?: string }) => {
    if (targetValue) {
      searchparams.set(
        'wallet',
        walletList.find((wallet) => wallet.value === targetValue)
          ?.value as string
      );
      searchparams.set(
        'currency',
        walletList.find((wallet) => wallet.value === targetValue)
          ?.currency_id as string
      );
    } else {
      searchparams.set(
        'wallet',
        walletList.find((item) => item.symbol === defaultWallet)?.value ||
          walletList[0]?.value
      );
      searchparams.set(
        'currency',
        walletList.find((item) => item.symbol === defaultWallet)?.currency_id ||
          walletList[0]?.currency_id
      );
    }
    searchparams.set('tab', '0');
    setSearchParams(searchparams);
  };

  React.useEffect(() => {
    if (
      wallets &&
      wallets?.list?.length > 0 &&
      !searchparams.get('wallet') &&
      !searchparams.get('currency')
    ) {
      onChangeWallet({});
    }
  }, [wallets]);

  return (
    <Stack
      flexDir={{ base: 'column', md: 'row' }}
      align="center"
      justify={{ base: 'center', md: 'space-between' }}
      py={{ base: 2, md: 0 }}
      px={{ base: 4, lg: 0 }}
      gap={4}
      mt={{ base: 0, md: 4 }}
    >
      <Text fontSize={{ base: '3xl', lg: '5xl' }} fontWeight="500">
        {t('deposit')}
      </Text>
      <Stack minW={{ base: '100%', md: '300px' }}>
        <Input
          label={t('selectWallet')}
          isSelectBox
          inputGroupStyle={{ mb: 0 }}
          labelStyle={{ pos: 'absolute', top: -5, fontSize: '12px' }}
          options={walletList}
          value={searchparams.get('wallet') || ''}
          onChange={(v) => {
            onChangeWallet({ targetValue: v.target.value });
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Head;
