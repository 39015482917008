import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'statics';

import useLocalStorage from './useLocalStorage';

const usePublic = () => {
  const { getItem } = useLocalStorage();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = getItem('magix-token');
    if (token && location.pathname.includes('auth')) {
      navigate(routes.dashboard.url);
    }
  }, []);
};

export default usePublic;
