const countryCode = [
  {
    flagSvg:
      'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
    flag: '🇦🇫',
    label: 'Afghanistan',
    value: '+93',
  },
  {
    flagSvg: 'https://flagcdn.com/ax.svg',
    flag: '🇦🇽',
    label: 'Åland Islands',
    value: '+35818',
  },
  {
    flagSvg: 'https://flagcdn.com/al.svg',
    flag: '🇦🇱',
    label: 'Albania',
    value: '+355',
  },
  {
    flagSvg: 'https://flagcdn.com/dz.svg',
    flag: '🇩🇿',
    label: 'Algeria',
    value: '+213',
  },
  {
    flagSvg: 'https://flagcdn.com/as.svg',
    flag: '🇦🇸',
    label: 'American Samoa',
    value: '+1684',
  },
  {
    flagSvg: 'https://flagcdn.com/ad.svg',
    flag: '🇦🇩',
    label: 'Andorra',
    value: '+376',
  },
  {
    flagSvg: 'https://flagcdn.com/ao.svg',
    flag: '🇦🇴',
    label: 'Angola',
    value: '+244',
  },
  {
    flagSvg: 'https://flagcdn.com/ai.svg',
    flag: '🇦🇮',
    label: 'Anguilla',
    value: '+1264',
  },
  {
    flagSvg: 'https://flagcdn.com/ag.svg',
    flag: '🇦🇬',
    label: 'Antigua and Barbuda',
    value: '+1268',
  },
  {
    flagSvg: 'https://flagcdn.com/ar.svg',
    flag: '🇦🇷',
    label: 'Argentina',
    value: '+54',
  },
  {
    flagSvg: 'https://flagcdn.com/am.svg',
    flag: '🇦🇲',
    label: 'Armenia',
    value: '+374',
  },
  {
    flagSvg: 'https://flagcdn.com/aw.svg',
    flag: '🇦🇼',
    label: 'Aruba',
    value: '+297',
  },
  {
    flagSvg: 'https://flagcdn.com/au.svg',
    flag: '🇦🇺',
    label: 'Australia',
    value: '+61',
  },
  {
    flagSvg: 'https://flagcdn.com/at.svg',
    flag: '🇦🇹',
    label: 'Austria',
    value: '+43',
  },
  {
    flagSvg: 'https://flagcdn.com/az.svg',
    flag: '🇦🇿',
    label: 'Azerbaijan',
    value: '+994',
  },
  {
    flagSvg: 'https://flagcdn.com/bs.svg',
    flag: '🇧🇸',
    label: 'Bahamas',
    value: '+1242',
  },
  {
    flagSvg: 'https://flagcdn.com/bh.svg',
    flag: '🇧🇭',
    label: 'Bahrain',
    value: '+973',
  },
  {
    flagSvg: 'https://flagcdn.com/bd.svg',
    flag: '🇧🇩',
    label: 'Bangladesh',
    value: '+880',
  },
  {
    flagSvg: 'https://flagcdn.com/bb.svg',
    flag: '🇧🇧',
    label: 'Barbados',
    value: '+1246',
  },
  {
    flagSvg: 'https://flagcdn.com/by.svg',
    flag: '🇧🇾',
    label: 'Belarus',
    value: '+375',
  },
  {
    flagSvg: 'https://flagcdn.com/be.svg',
    flag: '🇧🇪',
    label: 'Belgium',
    value: '+32',
  },
  {
    flagSvg: 'https://flagcdn.com/bz.svg',
    flag: '🇧🇿',
    label: 'Belize',
    value: '+501',
  },
  {
    flagSvg: 'https://flagcdn.com/bj.svg',
    flag: '🇧🇯',
    label: 'Benin',
    value: '+229',
  },
  {
    flagSvg: 'https://flagcdn.com/bm.svg',
    flag: '🇧🇲',
    label: 'Bermuda',
    value: '+1441',
  },
  {
    flagSvg: 'https://flagcdn.com/bt.svg',
    flag: '🇧🇹',
    label: 'Bhutan',
    value: '+975',
  },
  {
    flagSvg: 'https://flagcdn.com/bo.svg',
    flag: '🇧🇴',
    label: 'Bolivia',
    value: '+591',
  },
  {
    flagSvg: 'https://flagcdn.com/ba.svg',
    flag: '🇧🇦',
    label: 'Bosnia and Herzegovina',
    value: '+387',
  },
  {
    flagSvg: 'https://flagcdn.com/bw.svg',
    flag: '🇧🇼',
    label: 'Botswana',
    value: '+267',
  },
  {
    flagSvg: 'https://flagcdn.com/bv.svg',
    flag: '🇧🇻',
    label: 'Bouvet Island',
    value: '+47',
  },
  {
    flagSvg: 'https://flagcdn.com/br.svg',
    flag: '🇧🇷',
    label: 'Brazil',
    value: '+55',
  },
  {
    flagSvg: 'https://flagcdn.com/io.svg',
    flag: '🇮🇴',
    label: 'British Indian Ocean Territory',
    value: '+246',
  },
  {
    flagSvg: 'https://flagcdn.com/vg.svg',
    flag: '🇻🇬',
    label: 'British Virgin Islands',
    value: '+1284',
  },
  {
    flagSvg: 'https://flagcdn.com/bn.svg',
    flag: '🇧🇳',
    label: 'Brunei',
    value: '+673',
  },
  {
    flagSvg: 'https://flagcdn.com/bg.svg',
    flag: '🇧🇬',
    label: 'Bulgaria',
    value: '+359',
  },
  {
    flagSvg: 'https://flagcdn.com/bf.svg',
    flag: '🇧🇫',
    label: 'Burkina Faso',
    value: '+226',
  },
  {
    flagSvg: 'https://flagcdn.com/bi.svg',
    flag: '🇧🇮',
    label: 'Burundi',
    value: '+257',
  },
  {
    flagSvg: 'https://flagcdn.com/kh.svg',
    flag: '🇰🇭',
    label: 'Cambodia',
    value: '+855',
  },
  {
    flagSvg: 'https://flagcdn.com/cm.svg',
    flag: '🇨🇲',
    label: 'Cameroon',
    value: '+237',
  },
  {
    flagSvg: 'https://flagcdn.com/ca.svg',
    flag: '🇨🇦',
    label: 'Canada',
    value: '+1',
  },
  {
    flagSvg: 'https://flagcdn.com/cv.svg',
    flag: '🇨🇻',
    label: 'Cape Verde',
    value: '+238',
  },
  {
    flagSvg: 'https://flagcdn.com/bq.svg',
    flag: '🇧🇶',
    label: 'Caribbean Netherlands',
    value: '+599',
  },
  {
    flagSvg: 'https://flagcdn.com/ky.svg',
    flag: '🇰🇾',
    label: 'Cayman Islands',
    value: '+1345',
  },
  {
    flagSvg: 'https://flagcdn.com/cf.svg',
    flag: '🇨🇫',
    label: 'Central African Republic',
    value: '+236',
  },
  {
    flagSvg: 'https://flagcdn.com/td.svg',
    flag: '🇹🇩',
    label: 'Chad',
    value: '+235',
  },
  {
    flagSvg: 'https://flagcdn.com/cl.svg',
    flag: '🇨🇱',
    label: 'Chile',
    value: '+56',
  },
  {
    flagSvg: 'https://flagcdn.com/cn.svg',
    flag: '🇨🇳',
    label: 'China',
    value: '+86',
  },
  {
    flagSvg: 'https://flagcdn.com/cx.svg',
    flag: '🇨🇽',
    label: 'Christmas Island',
    value: '+61',
  },
  {
    flagSvg: 'https://flagcdn.com/cc.svg',
    flag: '🇨🇨',
    label: 'Cocos (Keeling) Islands',
    value: '+61',
  },
  {
    flagSvg: 'https://flagcdn.com/co.svg',
    flag: '🇨🇴',
    label: 'Colombia',
    value: '+57',
  },
  {
    flagSvg: 'https://flagcdn.com/km.svg',
    flag: '🇰🇲',
    label: 'Comoros',
    value: '+269',
  },
  {
    flagSvg: 'https://flagcdn.com/ck.svg',
    flag: '🇨🇰',
    label: 'Cook Islands',
    value: '+682',
  },
  {
    flagSvg: 'https://flagcdn.com/cr.svg',
    flag: '🇨🇷',
    label: 'Costa Rica',
    value: '+506',
  },
  {
    flagSvg: 'https://flagcdn.com/hr.svg',
    flag: '🇭🇷',
    label: 'Croatia',
    value: '+385',
  },
  {
    flagSvg: 'https://flagcdn.com/cw.svg',
    flag: '🇨🇼',
    label: 'Curaçao',
    value: '+599',
  },
  {
    flagSvg: 'https://flagcdn.com/cy.svg',
    flag: '🇨🇾',
    label: 'Cyprus',
    value: '+357',
  },
  {
    flagSvg: 'https://flagcdn.com/cz.svg',
    flag: '🇨🇿',
    label: 'Czechia',
    value: '+420',
  },
  {
    flagSvg: 'https://flagcdn.com/dk.svg',
    flag: '🇩🇰',
    label: 'Denmark',
    value: '+45',
  },
  {
    flagSvg: 'https://flagcdn.com/dj.svg',
    flag: '🇩🇯',
    label: 'Djibouti',
    value: '+253',
  },
  {
    flagSvg: 'https://flagcdn.com/dm.svg',
    flag: '🇩🇲',
    label: 'Dominica',
    value: '+1767',
  },
  {
    flagSvg: 'https://flagcdn.com/do.svg',
    flag: '🇩🇴',
    label: 'Dominican Republic',
    value: '+1',
  },
  {
    flagSvg: 'https://flagcdn.com/cd.svg',
    flag: '🇨🇩',
    label: 'DR Congo',
    value: '+243',
  },
  {
    flagSvg: 'https://flagcdn.com/eg.svg',
    flag: '🇪🇬',
    label: 'Egypt',
    value: '+20',
  },
  {
    flagSvg: 'https://flagcdn.com/sv.svg',
    flag: '🇸🇻',
    label: 'El Salvador',
    value: '+503',
  },
  {
    flagSvg: 'https://flagcdn.com/gq.svg',
    flag: '🇬🇶',
    label: 'Equatorial Guinea',
    value: '+240',
  },
  {
    flagSvg: 'https://flagcdn.com/er.svg',
    flag: '🇪🇷',
    label: 'Eritrea',
    value: '+291',
  },
  {
    flagSvg: 'https://flagcdn.com/ee.svg',
    flag: '🇪🇪',
    label: 'Estonia',
    value: '+372',
  },
  {
    flagSvg: 'https://flagcdn.com/sz.svg',
    flag: '🇸🇿',
    label: 'Eswatini',
    value: '+268',
  },
  {
    flagSvg: 'https://flagcdn.com/et.svg',
    flag: '🇪🇹',
    label: 'Ethiopia',
    value: '+251',
  },
  {
    flagSvg: 'https://flagcdn.com/fk.svg',
    flag: '🇫🇰',
    label: 'Falkland Islands',
    value: '+500',
  },
  {
    flagSvg: 'https://flagcdn.com/fo.svg',
    flag: '🇫🇴',
    label: 'Faroe Islands',
    value: '+298',
  },
  {
    flagSvg: 'https://flagcdn.com/fj.svg',
    flag: '🇫🇯',
    label: 'Fiji',
    value: '+679',
  },
  {
    flagSvg: 'https://flagcdn.com/fi.svg',
    flag: '🇫🇮',
    label: 'Finland',
    value: '+358',
  },
  {
    flagSvg: 'https://flagcdn.com/fr.svg',
    flag: '🇫🇷',
    label: 'France',
    value: '+33',
  },
  {
    flagSvg: 'https://flagcdn.com/gf.svg',
    flag: '🇬🇫',
    label: 'French Guiana',
    value: '+594',
  },
  {
    flagSvg: 'https://flagcdn.com/pf.svg',
    flag: '🇵🇫',
    label: 'French Polynesia',
    value: '+689',
  },
  {
    flagSvg: 'https://flagcdn.com/tf.svg',
    flag: '🇹🇫',
    label: 'French Southern and Antarctic Lands',
    value: '+262',
  },
  {
    flagSvg: 'https://flagcdn.com/ga.svg',
    flag: '🇬🇦',
    label: 'Gabon',
    value: '+241',
  },
  {
    flagSvg: 'https://flagcdn.com/gm.svg',
    flag: '🇬🇲',
    label: 'Gambia',
    value: '+220',
  },
  {
    flagSvg: 'https://flagcdn.com/ge.svg',
    flag: '🇬🇪',
    label: 'Georgia',
    value: '+995',
  },
  {
    flagSvg: 'https://flagcdn.com/de.svg',
    flag: '🇩🇪',
    label: 'Germany',
    value: '+49',
  },
  {
    flagSvg: 'https://flagcdn.com/gh.svg',
    flag: '🇬🇭',
    label: 'Ghana',
    value: '+233',
  },
  {
    flagSvg: 'https://flagcdn.com/gi.svg',
    flag: '🇬🇮',
    label: 'Gibraltar',
    value: '+350',
  },
  {
    flagSvg: 'https://flagcdn.com/gr.svg',
    flag: '🇬🇷',
    label: 'Greece',
    value: '+30',
  },
  {
    flagSvg: 'https://flagcdn.com/gl.svg',
    flag: '🇬🇱',
    label: 'Greenland',
    value: '+299',
  },
  {
    flagSvg: 'https://flagcdn.com/gd.svg',
    flag: '🇬🇩',
    label: 'Grenada',
    value: '+1473',
  },
  {
    flagSvg: 'https://flagcdn.com/gp.svg',
    flag: '🇬🇵',
    label: 'Guadeloupe',
    value: '+590',
  },
  {
    flagSvg: 'https://flagcdn.com/gu.svg',
    flag: '🇬🇺',
    label: 'Guam',
    value: '+1671',
  },
  {
    flagSvg: 'https://flagcdn.com/gt.svg',
    flag: '🇬🇹',
    label: 'Guatemala',
    value: '+502',
  },
  {
    flagSvg: 'https://flagcdn.com/gg.svg',
    flag: '🇬🇬',
    label: 'Guernsey',
    value: '+44',
  },
  {
    flagSvg: 'https://flagcdn.com/gn.svg',
    flag: '🇬🇳',
    label: 'Guinea',
    value: '+224',
  },
  {
    flagSvg: 'https://flagcdn.com/gw.svg',
    flag: '🇬🇼',
    label: 'Guinea-Bissau',
    value: '+245',
  },
  {
    flagSvg: 'https://flagcdn.com/gy.svg',
    flag: '🇬🇾',
    label: 'Guyana',
    value: '+592',
  },
  {
    flagSvg: 'https://flagcdn.com/ht.svg',
    flag: '🇭🇹',
    label: 'Haiti',
    value: '+509',
  },
  {
    flagSvg: 'https://flagcdn.com/hn.svg',
    flag: '🇭🇳',
    label: 'Honduras',
    value: '+504',
  },
  {
    flagSvg: 'https://flagcdn.com/hk.svg',
    flag: '🇭🇰',
    label: 'Hong Kong',
    value: '+852',
  },
  {
    flagSvg: 'https://flagcdn.com/hu.svg',
    flag: '🇭🇺',
    label: 'Hungary',
    value: '+36',
  },
  {
    flagSvg: 'https://flagcdn.com/is.svg',
    flag: '🇮🇸',
    label: 'Iceland',
    value: '+354',
  },
  {
    flagSvg: 'https://flagcdn.com/in.svg',
    flag: '🇮🇳',
    label: 'India',
    value: '+91',
  },
  {
    flagSvg: 'https://flagcdn.com/id.svg',
    flag: '🇮🇩',
    label: 'Indonesia',
    value: '+62',
  },
  {
    flagSvg: 'https://flagcdn.com/iq.svg',
    flag: '🇮🇶',
    label: 'Iraq',
    value: '+964',
  },
  {
    flagSvg: 'https://flagcdn.com/ie.svg',
    flag: '🇮🇪',
    label: 'Ireland',
    value: '+353',
  },
  {
    flagSvg: 'https://flagcdn.com/im.svg',
    flag: '🇮🇲',
    label: 'Isle of Man',
    value: '+44',
  },
  {
    flagSvg: 'https://flagcdn.com/il.svg',
    flag: '🇮🇱',
    label: 'Israel',
    value: '+972',
  },
  {
    flagSvg: 'https://flagcdn.com/it.svg',
    flag: '🇮🇹',
    label: 'Italy',
    value: '+39',
  },
  {
    flagSvg: 'https://flagcdn.com/ci.svg',
    flag: '🇨🇮',
    label: 'Ivory Coast',
    value: '+225',
  },
  {
    flagSvg: 'https://flagcdn.com/jm.svg',
    flag: '🇯🇲',
    label: 'Jamaica',
    value: '+1876',
  },
  {
    flagSvg: 'https://flagcdn.com/jp.svg',
    flag: '🇯🇵',
    label: 'Japan',
    value: '+81',
  },
  {
    flagSvg: 'https://flagcdn.com/je.svg',
    flag: '🇯🇪',
    label: 'Jersey',
    value: '+44',
  },
  {
    flagSvg: 'https://flagcdn.com/jo.svg',
    flag: '🇯🇴',
    label: 'Jordan',
    value: '+962',
  },
  {
    flagSvg: 'https://flagcdn.com/kz.svg',
    flag: '🇰🇿',
    label: 'Kazakhstan',
    value: '+7',
  },
  {
    flagSvg: 'https://flagcdn.com/ke.svg',
    flag: '🇰🇪',
    label: 'Kenya',
    value: '+254',
  },
  {
    flagSvg: 'https://flagcdn.com/ki.svg',
    flag: '🇰🇮',
    label: 'Kiribati',
    value: '+686',
  },
  {
    flagSvg: 'https://flagcdn.com/xk.svg',
    flag: '🇽🇰',
    label: 'Kosovo',
    value: '+383',
  },
  {
    flagSvg: 'https://flagcdn.com/kw.svg',
    flag: '🇰🇼',
    label: 'Kuwait',
    value: '+965',
  },
  {
    flagSvg: 'https://flagcdn.com/kg.svg',
    flag: '🇰🇬',
    label: 'Kyrgyzstan',
    value: '+996',
  },
  {
    flagSvg: 'https://flagcdn.com/la.svg',
    flag: '🇱🇦',
    label: 'Laos',
    value: '+856',
  },
  {
    flagSvg: 'https://flagcdn.com/lv.svg',
    flag: '🇱🇻',
    label: 'Latvia',
    value: '+371',
  },
  {
    flagSvg: 'https://flagcdn.com/lb.svg',
    flag: '🇱🇧',
    label: 'Lebanon',
    value: '+961',
  },
  {
    flagSvg: 'https://flagcdn.com/ls.svg',
    flag: '🇱🇸',
    label: 'Lesotho',
    value: '+266',
  },
  {
    flagSvg: 'https://flagcdn.com/lr.svg',
    flag: '🇱🇷',
    label: 'Liberia',
    value: '+231',
  },
  {
    flagSvg: 'https://flagcdn.com/ly.svg',
    flag: '🇱🇾',
    label: 'Libya',
    value: '+218',
  },
  {
    flagSvg: 'https://flagcdn.com/li.svg',
    flag: '🇱🇮',
    label: 'Liechtenstein',
    value: '+423',
  },
  {
    flagSvg: 'https://flagcdn.com/lt.svg',
    flag: '🇱🇹',
    label: 'Lithuania',
    value: '+370',
  },
  {
    flagSvg: 'https://flagcdn.com/lu.svg',
    flag: '🇱🇺',
    label: 'Luxembourg',
    value: '+352',
  },
  {
    flagSvg: 'https://flagcdn.com/mo.svg',
    flag: '🇲🇴',
    label: 'Macau',
    value: '+853',
  },
  {
    flagSvg: 'https://flagcdn.com/mg.svg',
    flag: '🇲🇬',
    label: 'Madagascar',
    value: '+261',
  },
  {
    flagSvg: 'https://flagcdn.com/mw.svg',
    flag: '🇲🇼',
    label: 'Malawi',
    value: '+265',
  },
  {
    flagSvg: 'https://flagcdn.com/my.svg',
    flag: '🇲🇾',
    label: 'Malaysia',
    value: '+60',
  },
  {
    flagSvg: 'https://flagcdn.com/mv.svg',
    flag: '🇲🇻',
    label: 'Maldives',
    value: '+960',
  },
  {
    flagSvg: 'https://flagcdn.com/ml.svg',
    flag: '🇲🇱',
    label: 'Mali',
    value: '+223',
  },
  {
    flagSvg: 'https://flagcdn.com/mt.svg',
    flag: '🇲🇹',
    label: 'Malta',
    value: '+356',
  },
  {
    flagSvg: 'https://flagcdn.com/mh.svg',
    flag: '🇲🇭',
    label: 'Marshall Islands',
    value: '+692',
  },
  {
    flagSvg: 'https://flagcdn.com/mq.svg',
    flag: '🇲🇶',
    label: 'Martinique',
    value: '+596',
  },
  {
    flagSvg: 'https://flagcdn.com/mr.svg',
    flag: '🇲🇷',
    label: 'Mauritania',
    value: '+222',
  },
  {
    flagSvg: 'https://flagcdn.com/mu.svg',
    flag: '🇲🇺',
    label: 'Mauritius',
    value: '+230',
  },
  {
    flagSvg: 'https://flagcdn.com/yt.svg',
    flag: '🇾🇹',
    label: 'Mayotte',
    value: '+262',
  },
  {
    flagSvg: 'https://flagcdn.com/mx.svg',
    flag: '🇲🇽',
    label: 'Mexico',
    value: '+52',
  },
  {
    flagSvg: 'https://flagcdn.com/fm.svg',
    flag: '🇫🇲',
    label: 'Micronesia',
    value: '+691',
  },
  {
    flagSvg: 'https://flagcdn.com/md.svg',
    flag: '🇲🇩',
    label: 'Moldova',
    value: '+373',
  },
  {
    flagSvg: 'https://flagcdn.com/mc.svg',
    flag: '🇲🇨',
    label: 'Monaco',
    value: '+377',
  },
  {
    flagSvg: 'https://flagcdn.com/mn.svg',
    flag: '🇲🇳',
    label: 'Mongolia',
    value: '+976',
  },
  {
    flagSvg: 'https://flagcdn.com/me.svg',
    flag: '🇲🇪',
    label: 'Montenegro',
    value: '+382',
  },
  {
    flagSvg: 'https://flagcdn.com/ms.svg',
    flag: '🇲🇸',
    label: 'Montserrat',
    value: '+1664',
  },
  {
    flagSvg: 'https://flagcdn.com/ma.svg',
    flag: '🇲🇦',
    label: 'Morocco',
    value: '+212',
  },
  {
    flagSvg: 'https://flagcdn.com/mz.svg',
    flag: '🇲🇿',
    label: 'Mozambique',
    value: '+258',
  },
  {
    flagSvg: 'https://flagcdn.com/mm.svg',
    flag: '🇲🇲',
    label: 'Myanmar',
    value: '+95',
  },
  {
    flagSvg: 'https://flagcdn.com/na.svg',
    flag: '🇳🇦',
    label: 'Namibia',
    value: '+264',
  },
  {
    flagSvg: 'https://flagcdn.com/nr.svg',
    flag: '🇳🇷',
    label: 'Nauru',
    value: '+674',
  },
  {
    flagSvg: 'https://flagcdn.com/np.svg',
    flag: '🇳🇵',
    label: 'Nepal',
    value: '+977',
  },
  {
    flagSvg: 'https://flagcdn.com/nl.svg',
    flag: '🇳🇱',
    label: 'Netherlands',
    value: '+31',
  },
  {
    flagSvg: 'https://flagcdn.com/nc.svg',
    flag: '🇳🇨',
    label: 'New Caledonia',
    value: '+687',
  },
  {
    flagSvg: 'https://flagcdn.com/nz.svg',
    flag: '🇳🇿',
    label: 'New Zealand',
    value: '+64',
  },
  {
    flagSvg: 'https://flagcdn.com/ni.svg',
    flag: '🇳🇮',
    label: 'Nicaragua',
    value: '+505',
  },
  {
    flagSvg: 'https://flagcdn.com/ne.svg',
    flag: '🇳🇪',
    label: 'Niger',
    value: '+227',
  },
  {
    flagSvg: 'https://flagcdn.com/ng.svg',
    flag: '🇳🇬',
    label: 'Nigeria',
    value: '+234',
  },
  {
    flagSvg: 'https://flagcdn.com/nu.svg',
    flag: '🇳🇺',
    label: 'Niue',
    value: '+683',
  },
  {
    flagSvg: 'https://flagcdn.com/nf.svg',
    flag: '🇳🇫',
    label: 'Norfolk Island',
    value: '+672',
  },
  {
    flagSvg: 'https://flagcdn.com/mk.svg',
    flag: '🇲🇰',
    label: 'North Macedonia',
    value: '+389',
  },
  {
    flagSvg: 'https://flagcdn.com/mp.svg',
    flag: '🇲🇵',
    label: 'Northern Mariana Islands',
    value: '+1670',
  },
  {
    flagSvg: 'https://flagcdn.com/no.svg',
    flag: '🇳🇴',
    label: 'Norway',
    value: '+47',
  },
  {
    flagSvg: 'https://flagcdn.com/om.svg',
    flag: '🇴🇲',
    label: 'Oman',
    value: '+968',
  },
  {
    flagSvg: 'https://flagcdn.com/pk.svg',
    flag: '🇵🇰',
    label: 'Pakistan',
    value: '+92',
  },
  {
    flagSvg: 'https://flagcdn.com/pw.svg',
    flag: '🇵🇼',
    label: 'Palau',
    value: '+680',
  },
  {
    flagSvg: 'https://flagcdn.com/ps.svg',
    flag: '🇵🇸',
    label: 'Palestine',
    value: '+970',
  },
  {
    flagSvg: 'https://flagcdn.com/pa.svg',
    flag: '🇵🇦',
    label: 'Panama',
    value: '+507',
  },
  {
    flagSvg: 'https://flagcdn.com/pg.svg',
    flag: '🇵🇬',
    label: 'Papua New Guinea',
    value: '+675',
  },
  {
    flagSvg: 'https://flagcdn.com/py.svg',
    flag: '🇵🇾',
    label: 'Paraguay',
    value: '+595',
  },
  {
    flagSvg: 'https://flagcdn.com/pe.svg',
    flag: '🇵🇪',
    label: 'Peru',
    value: '+51',
  },
  {
    flagSvg: 'https://flagcdn.com/ph.svg',
    flag: '🇵🇭',
    label: 'Philippines',
    value: '+63',
  },
  {
    flagSvg: 'https://flagcdn.com/pn.svg',
    flag: '🇵🇳',
    label: 'Pitcairn Islands',
    value: '+64',
  },
  {
    flagSvg: 'https://flagcdn.com/pl.svg',
    flag: '🇵🇱',
    label: 'Poland',
    value: '+48',
  },
  {
    flagSvg: 'https://flagcdn.com/pt.svg',
    flag: '🇵🇹',
    label: 'Portugal',
    value: '+351',
  },
  {
    flagSvg: 'https://flagcdn.com/pr.svg',
    flag: '🇵🇷',
    label: 'Puerto Rico',
    value: '+1',
  },
  {
    flagSvg: 'https://flagcdn.com/qa.svg',
    flag: '🇶🇦',
    label: 'Qatar',
    value: '+974',
  },
  {
    flagSvg: 'https://flagcdn.com/cg.svg',
    flag: '🇨🇬',
    label: 'Republic of the Congo',
    value: '+242',
  },
  {
    flagSvg: 'https://flagcdn.com/re.svg',
    flag: '🇷🇪',
    label: 'Réunion',
    value: '+262',
  },
  {
    flagSvg: 'https://flagcdn.com/ro.svg',
    flag: '🇷🇴',
    label: 'Romania',
    value: '+40',
  },
  {
    flagSvg: 'https://flagcdn.com/rw.svg',
    flag: '🇷🇼',
    label: 'Rwanda',
    value: '+250',
  },
  {
    flagSvg: 'https://flagcdn.com/bl.svg',
    flag: '🇧🇱',
    label: 'Saint Barthélemy',
    value: '+590',
  },
  {
    flagSvg: 'https://flagcdn.com/kn.svg',
    flag: '🇰🇳',
    label: 'Saint Kitts and Nevis',
    value: '+1869',
  },
  {
    flagSvg: 'https://flagcdn.com/lc.svg',
    flag: '🇱🇨',
    label: 'Saint Lucia',
    value: '+1758',
  },
  {
    flagSvg: 'https://flagcdn.com/mf.svg',
    flag: '🇲🇫',
    label: 'Saint Martin',
    value: '+590',
  },
  {
    flagSvg: 'https://flagcdn.com/pm.svg',
    flag: '🇵🇲',
    label: 'Saint Pierre and Miquelon',
    value: '+508',
  },
  {
    flagSvg: 'https://flagcdn.com/vc.svg',
    flag: '🇻🇨',
    label: 'Saint Vincent and the Grenadines',
    value: '+1784',
  },
  {
    flagSvg: 'https://flagcdn.com/ws.svg',
    flag: '🇼🇸',
    label: 'Samoa',
    value: '+685',
  },
  {
    flagSvg: 'https://flagcdn.com/sm.svg',
    flag: '🇸🇲',
    label: 'San Marino',
    value: '+378',
  },
  {
    flagSvg: 'https://flagcdn.com/st.svg',
    flag: '🇸🇹',
    label: 'São Tomé and Príncipe',
    value: '+239',
  },
  {
    flagSvg: 'https://flagcdn.com/sa.svg',
    flag: '🇸🇦',
    label: 'Saudi Arabia',
    value: '+966',
  },
  {
    flagSvg: 'https://flagcdn.com/sn.svg',
    flag: '🇸🇳',
    label: 'Senegal',
    value: '+221',
  },
  {
    flagSvg: 'https://flagcdn.com/rs.svg',
    flag: '🇷🇸',
    label: 'Serbia',
    value: '+381',
  },
  {
    flagSvg: 'https://flagcdn.com/sc.svg',
    flag: '🇸🇨',
    label: 'Seychelles',
    value: '+248',
  },
  {
    flagSvg: 'https://flagcdn.com/sl.svg',
    flag: '🇸🇱',
    label: 'Sierra Leone',
    value: '+232',
  },
  {
    flagSvg: 'https://flagcdn.com/sg.svg',
    flag: '🇸🇬',
    label: 'Singapore',
    value: '+65',
  },
  {
    flagSvg: 'https://flagcdn.com/sx.svg',
    flag: '🇸🇽',
    label: 'Sint Maarten',
    value: '+1721',
  },
  {
    flagSvg: 'https://flagcdn.com/sk.svg',
    flag: '🇸🇰',
    label: 'Slovakia',
    value: '+421',
  },
  {
    flagSvg: 'https://flagcdn.com/si.svg',
    flag: '🇸🇮',
    label: 'Slovenia',
    value: '+386',
  },
  {
    flagSvg: 'https://flagcdn.com/sb.svg',
    flag: '🇸🇧',
    label: 'Solomon Islands',
    value: '+677',
  },
  {
    flagSvg: 'https://flagcdn.com/so.svg',
    flag: '🇸🇴',
    label: 'Somalia',
    value: '+252',
  },
  {
    flagSvg: 'https://flagcdn.com/za.svg',
    flag: '🇿🇦',
    label: 'South Africa',
    value: '+27',
  },
  {
    flagSvg: 'https://flagcdn.com/gs.svg',
    flag: '🇬🇸',
    label: 'South Georgia',
    value: '+500',
  },
  {
    flagSvg: 'https://flagcdn.com/kr.svg',
    flag: '🇰🇷',
    label: 'South Korea',
    value: '+82',
  },
  {
    flagSvg: 'https://flagcdn.com/ss.svg',
    flag: '🇸🇸',
    label: 'South Sudan',
    value: '+211',
  },
  {
    flagSvg: 'https://flagcdn.com/es.svg',
    flag: '🇪🇸',
    label: 'Spain',
    value: '+34',
  },
  {
    flagSvg: 'https://flagcdn.com/lk.svg',
    flag: '🇱🇰',
    label: 'Sri Lanka',
    value: '+94',
  },
  {
    flagSvg: 'https://flagcdn.com/sd.svg',
    flag: '🇸🇩',
    label: 'Sudan',
    value: '+249',
  },
  {
    flagSvg: 'https://flagcdn.com/sr.svg',
    flag: '🇸🇷',
    label: 'Suriname',
    value: '+597',
  },
  {
    flagSvg: 'https://flagcdn.com/sj.svg',
    flag: '🇸🇯',
    label: 'Svalbard and Jan Mayen',
    value: '+4779',
  },
  {
    flagSvg: 'https://flagcdn.com/se.svg',
    flag: '🇸🇪',
    label: 'Sweden',
    value: '+46',
  },
  {
    flagSvg: 'https://flagcdn.com/ch.svg',
    flag: '🇨🇭',
    label: 'Switzerland',
    value: '+41',
  },
  {
    flagSvg: 'https://flagcdn.com/tw.svg',
    flag: '🇹🇼',
    label: 'Taiwan',
    value: '+886',
  },
  {
    flagSvg: 'https://flagcdn.com/tj.svg',
    flag: '🇹🇯',
    label: 'Tajikistan',
    value: '+992',
  },
  {
    flagSvg: 'https://flagcdn.com/tz.svg',
    flag: '🇹🇿',
    label: 'Tanzania',
    value: '+255',
  },
  {
    flagSvg: 'https://flagcdn.com/th.svg',
    flag: '🇹🇭',
    label: 'Thailand',
    value: '+66',
  },
  {
    flagSvg: 'https://flagcdn.com/tl.svg',
    flag: '🇹🇱',
    label: 'Timor-Leste',
    value: '+670',
  },
  {
    flagSvg: 'https://flagcdn.com/tg.svg',
    flag: '🇹🇬',
    label: 'Togo',
    value: '+228',
  },
  {
    flagSvg: 'https://flagcdn.com/tk.svg',
    flag: '🇹🇰',
    label: 'Tokelau',
    value: '+690',
  },
  {
    flagSvg: 'https://flagcdn.com/to.svg',
    flag: '🇹🇴',
    label: 'Tonga',
    value: '+676',
  },
  {
    flagSvg: 'https://flagcdn.com/tt.svg',
    flag: '🇹🇹',
    label: 'Trinidad and Tobago',
    value: '+1868',
  },
  {
    flagSvg: 'https://flagcdn.com/tn.svg',
    flag: '🇹🇳',
    label: 'Tunisia',
    value: '+216',
  },
  {
    flagSvg: 'https://flagcdn.com/tr.svg',
    flag: '🇹🇷',
    label: 'Turkey',
    value: '+90',
  },
  {
    flagSvg: 'https://flagcdn.com/tm.svg',
    flag: '🇹🇲',
    label: 'Turkmenistan',
    value: '+993',
  },
  {
    flagSvg: 'https://flagcdn.com/tc.svg',
    flag: '🇹🇨',
    label: 'Turks and Caicos Islands',
    value: '+1649',
  },
  {
    flagSvg: 'https://flagcdn.com/tv.svg',
    flag: '🇹🇻',
    label: 'Tuvalu',
    value: '+688',
  },
  {
    flagSvg: 'https://flagcdn.com/ug.svg',
    flag: '🇺🇬',
    label: 'Uganda',
    value: '+256',
  },
  {
    flagSvg: 'https://flagcdn.com/ua.svg',
    flag: '🇺🇦',
    label: 'Ukraine',
    value: '+380',
  },
  {
    flagSvg: 'https://flagcdn.com/ae.svg',
    flag: '🇦🇪',
    label: 'United Arab Emirates',
    value: '+971',
  },
  {
    flagSvg: 'https://flagcdn.com/gb.svg',
    flag: '🇬🇧',
    label: 'United Kingdom',
    value: '+44',
  },
  {
    flagSvg: 'https://flagcdn.com/us.svg',
    flag: '🇺🇸',
    label: 'United States',
    value: '+1',
  },
  {
    flagSvg: 'https://flagcdn.com/um.svg',
    flag: '🇺🇲',
    label: 'United States Minor Outlying Islands',
    value: '+268',
  },
  {
    flagSvg: 'https://flagcdn.com/vi.svg',
    flag: '🇻🇮',
    label: 'United States Virgin Islands',
    value: '+1340',
  },
  {
    flagSvg: 'https://flagcdn.com/uy.svg',
    flag: '🇺🇾',
    label: 'Uruguay',
    value: '+598',
  },
  {
    flagSvg: 'https://flagcdn.com/uz.svg',
    flag: '🇺🇿',
    label: 'Uzbekistan',
    value: '+998',
  },
  {
    flagSvg: 'https://flagcdn.com/vu.svg',
    flag: '🇻🇺',
    label: 'Vanuatu',
    value: '+678',
  },
  {
    flagSvg: 'https://flagcdn.com/va.svg',
    flag: '🇻🇦',
    label: 'Vatican City',
    value: '+3',
  },
  {
    flagSvg: 'https://flagcdn.com/ve.svg',
    flag: '🇻🇪',
    label: 'Venezuela',
    value: '+58',
  },
  {
    flagSvg: 'https://flagcdn.com/vn.svg',
    flag: '🇻🇳',
    label: 'Vietnam',
    value: '+84',
  },
  {
    flagSvg: 'https://flagcdn.com/wf.svg',
    flag: '🇼🇫',
    label: 'Wallis and Futuna',
    value: '+681',
  },
  {
    flagSvg: 'https://flagcdn.com/eh.svg',
    flag: '🇪🇭',
    label: 'Western Sahara',
    value: '+2',
  },
  {
    flagSvg: 'https://flagcdn.com/ye.svg',
    flag: '🇾🇪',
    label: 'Yemen',
    value: '+967',
  },
  {
    flagSvg: 'https://flagcdn.com/zm.svg',
    flag: '🇿🇲',
    label: 'Zambia',
    value: '+260',
  },
];
export default countryCode;
