import React from 'react';
import { Box } from '@chakra-ui/react';
import { useExchangeTabs } from 'hooks';
import { useTranslation } from 'localization';

import { Tabs } from 'components';

import { AdvanceExchange, Head, SimpleExchange } from './components';

const Exchange: React.FC = () => {
  const { t } = useTranslation('component.exchange');

  const { active, setActive } = useExchangeTabs();

  return (
    <Box w="100%">
      <Head />
      <Box my={{ base: 0, lg: 8 }}>
        <Tabs
          isLazy
          index={active}
          onChange={(activeIndex) => setActive(activeIndex)}
          tabData={[
            {
              label: t('tabs.simple'),
              content: <SimpleExchange />,
              isActive: true,
            },
            {
              label: t('tabs.advance'),
              content: <AdvanceExchange />,
              isActive: true,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Exchange;
