import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ChangePassword, ErrorResponse } from 'types';

import { api } from 'utils';

const useUpdatePassword = (): UseMutationResult<
  ChangePassword,
  ErrorResponse
> => {
  return useMutation((data) => api.put(url.updatePassword, data));
};
export default useUpdatePassword;
