import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, ListResponse, Session } from 'types';

const useSessions = (): UseQueryResult<
  ListResponse<Session> & { current_session_id: string },
  ErrorResponse
> => {
  return useQuery([url.sessions]);
};
export default useSessions;
