import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUploadDepositBankReceipt } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Uploader } from 'components';
import { yup } from 'utils';

type PayloadType = {
  bank_receipt_file: string;
};

type CompleteLdDepositProps = {
  transactionId: string;
  refetch: () => void;
};

const CompleteLdDeposit: React.FC<CompleteLdDepositProps> = ({
  transactionId,
  refetch,
}) => {
  const { t } = useTranslation(
    'component.ldRequests.transactions.completeForm'
  );

  type ThumbFileType = File & { preview: string };
  const [selectedFile, setSelectedFile] = React.useState<Array<ThumbFileType>>(
    []
  );

  const schema = yup.object().shape({
    bank_receipt_file: yup.string().label(t('bank_receipt_file')).required(),
  });

  const { mutateAsync: upload, isLoading: uploadLoading } =
    useUploadDepositBankReceipt();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    upload({ id: transactionId, data }).then(() => {
      reset();
      setValue('bank_receipt_file', '');
      setSelectedFile([]);
      refetch();
    });
  };

  const formProps = useInputAttributes(register, errors, {
    bank_receipt_file: t('bank_receipt_file'),
  });

  return (
    <Box w="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack flexDir="column">
          <Uploader
            {...formProps.bank_receipt_file}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            getValue={(value) => setValue('bank_receipt_file', value)}
          />
        </Stack>
        <Button
          type="submit"
          title={t('upload')}
          isLoading={uploadLoading}
          width="100%"
          mt={6}
        />
      </form>
    </Box>
  );
};

export default CompleteLdDeposit;
