import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateWithdrawProposal, ErrorResponse } from 'types';

import { api } from 'utils';

const useInitWithdrawProposal = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  CreateWithdrawProposal
> => {
  return useMutation((data) => api.post(url.initWithdrawProposal, data));
};

export default useInitWithdrawProposal;
