import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useExchangeTabs = () => {
  const [active, setActive] = React.useState<number>(0);

  const [searchparams, setSearchparams] = useSearchParams();

  React.useEffect(() => {
    if (searchparams.get('tab') === 'simple') {
      setActive(0);
    } else if (searchparams.get('tab') === 'advance') {
      setActive(1);
    } else {
      setActive(0);
    }
  }, [searchparams.get('tab')]);

  const changeActiveTab = (index: number) => {
    const tabs: Record<number, string> = { 0: 'simple', 1: 'advance' };
    setSearchparams({ tab: tabs[index] });
    setActive(index);
  };

  return { active, setActive: changeActiveTab };
};

export default useExchangeTabs;
