import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { LDUsers } from 'types';

import { LDList } from './components';

type LDTabs = { lds: LDUsers };

const LDTab: React.FC<LDTabs> = ({ lds }) => {
  const { t } = useTranslation('component.withdraw.ld');

  return (
    <Box my={4} w="100%">
      <Text
        fontSize={{ base: 'md', lg: '2xl' }}
        py={2}
        px={{ base: 4, lg: 0 }}
        fontWeight="bold"
      >
        {t('title')}
      </Text>
      <VStack
        display="flex"
        w="100%"
        py={8}
        px={4}
        gap={25}
        bgColor="dark"
        align="flex-start"
      >
        <LDList lds={lds} />
      </VStack>
    </Box>
  );
};

export default LDTab;
