import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useAccess, useProfile } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Tabs } from 'components';

import {
  Head,
  //  UserToBroker,
  UserToUser,
} from './components';

const Transfer: React.FC = () => {
  const { t } = useTranslation('page.transfer');
  const navigate = useNavigate();
  const access = useAccess();

  const { data: profile } = useProfile();

  // LD cant access to Transfer page
  React.useEffect(() => {
    if (['LD', 'BROKER'].includes(profile?.role.title || '')) {
      navigate(routes.dashboard.url);
    }
  }, [profile]);

  return (
    <Box w="100%" pt={{ base: 2, lg: 8 }}>
      <Head />
      <Box my={{ base: 0, lg: 4 }}>
        <Tabs
          isLazy
          tabData={[
            {
              label: t('tabs.userToUser'),
              content: <UserToUser />,
              isActive: access.transferService,
            },
            // { label: t('tabs.userToBroker'), content: <UserToBroker /> },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Transfer;
