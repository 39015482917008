import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useWithdrawBankTransferApprove = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string; data: { code: string } }
> => {
  return useMutation(({ id, data }) =>
    api.put(`${url.withdrawBankTransferApprove}/${id}/approve`, data)
  );
};

export default useWithdrawBankTransferApprove;
