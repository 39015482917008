import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'statics';

import { useProfile } from './queries';

const useRequestsTabs = () => {
  const { data: profile } = useProfile();
  const navigate = useNavigate();

  const [active, setActive] = React.useState<number>(0);

  const [searchparams, setSearchparams] = useSearchParams();

  React.useEffect(() => {
    if (profile?.role.title !== 'LD') {
      navigate(routes.dashboard.url);
    }
  }, [profile]);

  React.useEffect(() => {
    if (searchparams.get('tab') === 'withdraw') {
      setActive(1);
    } else {
      setActive(0);
    }
  }, [searchparams.get('tab')]);

  const changeActiveTab = (index: number) => {
    const tabs: Record<number, string> = {
      0: 'deposit',
      1: 'withdraw',
    };
    setSearchparams({ tab: tabs[index] });
    setActive(index);
  };

  return { active, setActive: changeActiveTab };
};

export default useRequestsTabs;
