import React from 'react';
import { useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFee } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { ListResponse, TransactionMethod, Wallet } from 'types';

import { BankTransferHint, CopyClipBoard, Input, Spinner } from 'components';
import { yup } from 'utils';

type CryptoTabProps = {
  wallet?: Wallet;
  trMethods: ListResponse<TransactionMethod> | undefined;
  isSuccessTrMethods: boolean;
  isFetchingTrMethods: boolean;
  isLoadingTrMethods: boolean;
};

const CryptoTab: React.FC<CryptoTabProps> = ({
  wallet,
  trMethods,
  isSuccessTrMethods,
  isFetchingTrMethods,
  isLoadingTrMethods,
}) => {
  const { t } = useTranslation('component.deposit.crypto');
  const [searchparams, setSearchParams] = useSearchParams();

  // Manage form in 4 code block
  const schema = yup.object().shape({
    wallet: yup.string().label(t('form.wallet')).required(),
    transaction_method: yup
      .string()
      .label(t('form.transaction_method'))
      .required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async () => {
    // setShowWallet(true);
  };

  const formProps = useInputAttributes(register, errors, {
    wallet: t('form.wallet'),
    transaction_method: t('form.transaction_method'),
  });

  // Manage TR methods in 3 code block
  const trMethod =
    trMethods?.list.find((item) => item.id === watch('transaction_method')) ||
    trMethods?.list[0] ||
    null;

  React.useEffect(() => {
    if (isSuccessTrMethods && transactionMethodList.length > 0) {
      setValue('transaction_method', transactionMethodList[0].value);
    }
    // handles When we need to get fee
    setSearchParams(searchparams);
  }, [isSuccessTrMethods, isFetchingTrMethods]);

  const transactionMethodList =
    trMethods?.list?.map((tr) => ({
      label: tr.title,
      value: tr.id,
      key: tr.key,
    })) || [];

  const {
    data: fee,
    isLoading: isLoadingFee,
    isFetching: isFetchingFee,
  } = useFee({
    queries: {
      currency: wallet?.currency.id || '',
      transaction_method: trMethod?.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  const cryptoWalletAddress = wallet?.external_wallet_addresses.find(
    (item) => item.transaction_method === watch('transaction_method')
  )?.address;

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          p={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.transaction_method}
              isSelectBox
              options={transactionMethodList}
              isDisabled={transactionMethodList.length < 1}
            />
          </Box>
        </Box>
        {isLoadingTrMethods && (
          <Stack w="100%" align="center" justify="center">
            <Spinner size="md" />
          </Stack>
        )}
        {trMethod?.fee && (
          <Box px={8} py={4} display="flex">
            <Text color="body2">{t('fee')}</Text>
            {trMethod?.fee && (
              <Text pl={4}>{trMethod?.fee ? '% ' + trMethod?.fee : '0'}</Text>
            )}
          </Box>
        )}
        {isLoadingFee && isFetchingFee && (
          <Stack w="100%" align="center" justify="center" py={10}>
            <Spinner size="md" />
          </Stack>
        )}
        {fee && wallet && (
          <Stack w="100%">
            <BankTransferHint
              maximum_credit={fee.maximum_credit}
              minimum_credit={fee.minimum_credit}
              maximum={fee.maximum}
              minimum={fee.minimum}
              percent={fee.percent}
              symbol={wallet.currency.symbol}
              type="deposit-crypto"
            />
          </Stack>
        )}
        {cryptoWalletAddress && (
          <Box p={8} textAlign="center">
            <Box
              display="flex"
              flexDir={{ base: 'column', xl: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '200px', width: '200px' }}
                value={cryptoWalletAddress}
                viewBox={'0 0 256 256'}
              />
              <CopyClipBoard initialValue={cryptoWalletAddress} full />
            </Box>
          </Box>
        )}
        {/* {!showWallet && (
          <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
            <Button
              type="submit"
              variant="filled"
              display="block"
              title={t('receive')}
              minW="100%"
              isDisabled={showWallet}
              mt={{ base: 8, md: 8 }}
            />
          </VStack>
        )} */}
      </form>
    </Box>
  );
};

export default CryptoTab;
