import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ListItem,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocalStorage, useLogout } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

type MenuListProps = {
  list: Array<{ title: string; link: string }>;
  closeDrawer?: () => void;
};
const MenuList: React.FC<MenuListProps> = ({ list, closeDrawer }) => {
  const { t } = useTranslation('layout.header');
  const { setItem } = useLocalStorage();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();

  const { mutateAsync: logout } = useLogout();

  return (
    <UnorderedList
      styleType="none"
      display="flex"
      gap="4"
      flexDir="column"
      marginInlineStart="0"
    >
      {list.map((item) => {
        return (
          <NavLink
            key={item.link}
            to={item.link}
            onClick={closeDrawer ? () => closeDrawer() : () => ''}
          >
            {({ isActive }) => (
              <ListItem
                textTransform="capitalize"
                fontSize="md"
                bg={
                  isActive
                    ? 'linear-gradient(90deg, #42CF90 0%, #051721 100%)'
                    : 'secondary'
                }
                py="3"
                pl={{ base: '4', md: '72px' }}
                pr="140px"
                color="body"
                _hover={{ bg: 'lightPrimary' }}
              >
                {t(item.title)}
              </ListItem>
            )}
          </NavLink>
        );
      })}
      <ListItem
        fontSize="md"
        py="3"
        pl={{ base: '4', md: '72px' }}
        pr="140px"
        color="error"
        cursor="pointer"
        onClick={() => onOpen()}
      >
        {t('logout')}
      </ListItem>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{t('logoutTitle')}</AlertDialogHeader>
          <AlertDialogBody>{t('logoutDescription')}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('no')}
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() =>
                logout([]).then(() => {
                  setItem('magix-token', '');
                  onClose();
                  navigate(routes.auth.signIn.url);
                })
              }
            >
              {t('yes')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </UnorderedList>
  );
};
export default MenuList;
