import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import {
  useAccess,
  useLDUsers,
  useProfile,
  useTransactionMethods,
  useWallet,
} from 'hooks';
import { useTranslation } from 'localization';

import { Spinner, Tabs } from 'components';

import { BankTransferTab, CryptoTab, Head, LDTab } from './components';

const Deposit: React.FC = () => {
  const { t } = useTranslation('page.deposit');
  const access = useAccess();

  const [searchparams, setSearchParams] = useSearchParams();

  const { data: profile } = useProfile();

  const { data: wallet, isLoading: isLoadingWallet } = useWallet({
    id: searchparams.get('wallet'),
  });

  const { data: LDs, isLoading: lDsLoading } = useLDUsers({
    queries: {
      wallet: searchparams.get('wallet') || '',
    },
    enabled: access.depositLDUsersListPermission,
  });

  const {
    data: bankTrMethods,
    isSuccess: isSuccessBankTrMethods,
    isLoading: isLoadingBankTrMethods,
    isRefetching: isRefetchingBankTrMethods,
  } = useTransactionMethods({
    queries: wallet
      ? {
          allowed_currencies: wallet.currency.id,
          allowed_countries: profile?.country?.id || '',
          category: 'DEPOSIT_BANK_TRANSFER',
        }
      : {},
    enabled: Boolean(wallet),
  });

  const {
    data: cryptoTrMethods,
    isSuccess: isSuccessCryptoTrMethods,
    isFetching: isFetchingCryptoTrMethods,
    isLoading: isLoadingCryptoTrMethods,
  } = useTransactionMethods({
    queries: wallet
      ? {
          allowed_currencies: wallet.currency.id,
          category: 'DEPOSIT_CRYPTO',
        }
      : {},
    enabled: Boolean(wallet),
  });

  const allowed_tabs = [
    {
      label: t('tabs.bankTr'),
      content: (
        <BankTransferTab
          wallet={wallet}
          trMethods={bankTrMethods}
          isSuccessTrMethods={isSuccessBankTrMethods}
          isRefetchingTrMethods={isRefetchingBankTrMethods}
          profile={profile}
        />
      ),
      isActive:
        access.depositBankTransferCreatePermission &&
        bankTrMethods?.list &&
        bankTrMethods?.list.length > 0,
    },
    {
      label: t('tabs.ld'),
      content: <LDTab lds={LDs || []} symbol={wallet?.currency.symbol} />,
      isActive:
        access.depositLDUsersListPermission &&
        access.depositLDCreatePermission &&
        LDs &&
        LDs?.length > 0,
    },
    {
      label: t('tabs.crypto'),
      content: (
        <CryptoTab
          wallet={wallet}
          trMethods={cryptoTrMethods}
          isSuccessTrMethods={isSuccessCryptoTrMethods}
          isFetchingTrMethods={isFetchingCryptoTrMethods}
          isLoadingTrMethods={isLoadingCryptoTrMethods}
        />
      ),
      isActive: cryptoTrMethods?.list && cryptoTrMethods?.list.length > 0,
    },
  ];

  const changeTab = (i: number) => {
    searchparams.set('tab', i.toString());
    setSearchParams(searchparams);
  };

  React.useEffect(() => {
    if (searchparams.get('tab')) {
      changeTab(parseInt(searchparams.get('tab') as string));
    }
  }, [searchparams.get('tab')]);

  return (
    <Box w="100%" pt={{ base: 2, lg: 8 }}>
      <Head />
      <Box my={{ base: 0, lg: 4 }}>
        {isLoadingWallet &&
        isLoadingBankTrMethods &&
        isLoadingCryptoTrMethods &&
        lDsLoading ? (
          <Stack w="100%" justify="center" align="center" my={10}>
            <Spinner size="md" />
          </Stack>
        ) : (
          <Tabs
            // isLazy
            index={parseInt(searchparams.get('tab') as string)}
            onChange={(i) => changeTab(i)}
            tabData={allowed_tabs}
          />
        )}
      </Box>
    </Box>
  );
};

export default Deposit;
