import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, ResendCode } from 'types';

import { api } from 'utils';

const useResendCode = (
  token: string
): UseMutationResult<ResendCode, ErrorResponse> => {
  return useMutation((data) =>
    api.post(url.resendCode, data, { headers: { 'csrf-token': token } })
  );
};
export default useResendCode;
