import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { usePrivate } from 'hooks';
import { NoMatch } from 'pages';
import { routes } from 'statics';
import { Magix } from 'wrappers';

const Authenticated: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: profile, isLoading } = usePrivate({
    redirect: (location, navigate) => {
      if (
        searchParams.get('system_identifier') &&
        searchParams.get('client_email')
      ) {
        navigate(routes.auth.signIn.url, {
          state: {
            client_email: searchParams.get('client_email'),
            system_identifier: searchParams.get('system_identifier'),
          },
        });
      } else {
        navigate(routes.auth.signIn.url);
      }
    },
  });

  React.useEffect(() => {
    if (
      profile?.role.title === 'LEVEL0' &&
      !(
        location.pathname.includes('dashboard') ||
        location.pathname.includes('settings')
      )
    ) {
      navigate(routes.dashboard.url);
    }
  }, [profile]);

  if (isLoading || !profile) {
    return <NoMatch />;
  }

  if (profile) {
    return <Magix />;
  }
};
export default Authenticated;
