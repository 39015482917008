import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useRejectProposal = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) => api.put(`${url.rejectProposal}/${id}/reject`));
};
export default useRejectProposal;
