import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  PinInput as CkPinInput,
  PinInputField,
  PinInputProps,
} from '@chakra-ui/react';

type PinInputTypes = {
  invalid?: boolean;
  name: string;
  length: number;
  control: Control<any>;
} & PinInputProps;
const PinInput: React.FC<PinInputTypes> = ({
  invalid,
  name,
  control,
  length,
  ...props
}) => {
  const [filled, setFilled] = useState<boolean>(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl isInvalid={invalid}>
          <Box
            border="1px"
            borderColor={filled ? 'primary' : 'body2'}
            rounded="lg"
            background={filled ? 'rgba(241, 248, 242, 0.1)' : 'transparent'}
            pt={1}
            pb={0.5}
            _hover={{ background: 'rgba(241, 248, 242, 0.1)' }}
          >
            <CkPinInput
              autoFocus
              otp
              placeholder="_"
              isInvalid={invalid}
              onChange={onChange}
              value={value}
              {...props}
            >
              {Array.from({ length: length }, (_, index) => {
                return (
                  <PinInputField
                    color="secondary2"
                    _placeholder={{ color: 'secondary2' }}
                    key={index}
                    onFocus={() => setFilled(true)}
                    onBlur={() => setFilled(false)}
                    onKeyDown={() => setFilled(false)}
                    border="none"
                    bg="transparent"
                  />
                );
              })}
            </CkPinInput>
          </Box>
        </FormControl>
      )}
    />
  );
};

PinInput.displayName = 'PinInput';
export default PinInput;
