import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const usePrimaryContact = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) =>
    api.post(`${url.primaryContact}/${id}/primary`)
  );
};

export default usePrimaryContact;
