import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useCancelProposalByUser = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) =>
    api.put(`${url.cancelProposalByUser}/${id}/cancel`)
  );
};
export default useCancelProposalByUser;
