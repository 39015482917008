import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useSendCodeServiceTransaction = (): UseMutationResult<
  any,
  ErrorResponse
> => {
  return useMutation(() => api.post(url.sendCodeServiceTransaction, {}));
};
export default useSendCodeServiceTransaction;
