import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Input } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';

const DateRangeSelect: React.FC<{
  dateRange: (Date | null)[];
  // eslint-disable-next-line no-unused-vars
  setDateRange: (data: (Date | null)[]) => void;
}> = ({ dateRange, setDateRange }) => {
  const [startDate, endDate] = dateRange;

  // eslint-disable-next-line react/display-name
  const ExampleCustomInput: any = forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <Input
        sx={{
          h: 8,
          w: 56,
          padding: '0 0 0 10px',
        }}
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Select date range"
      />
    )
  );

  return (
    <Box>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        onCalendarClose={() => {
          return dateRange.includes(null) ? setDateRange([null, null]) : true;
        }}
        customInput={<ExampleCustomInput />}
        isClearable
        selectsRange
      />
    </Box>
  );
};

export default DateRangeSelect;
