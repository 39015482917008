import {
  AccordionButton,
  AccordionIcon,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Proposal } from 'types';

type LDProposalAccordionButton = {
  createdAt: string;
  username: string;
  status: Proposal['status'];
  panel: 'LD' | 'USER';
  transaction_type: 'WITHDRAW' | 'DEPOSIT';
};

type BadgeStatusType = Record<
  'WITHDRAW' | 'DEPOSIT',
  Record<
    'LD' | 'USER',
    Record<
      Proposal['status'],
      { scheme: string; variant: string; text: string }
    >
  >
>;

const LDProposalAccordionButton: React.FC<LDProposalAccordionButton> = ({
  createdAt,
  username,
  status,
  panel,
  transaction_type,
}: LDProposalAccordionButton) => {
  const badgeStatus: BadgeStatusType = {
    DEPOSIT: {
      USER: {
        INIT: { scheme: 'blue', variant: 'solid', text: 'Wait for Rate' },
        PENDING: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Payment selection',
        },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        APPROVED: {
          scheme: 'blue',
          variant: 'solid',
          text: 'Wait for approve',
        },
        REJECTED: { scheme: 'red', variant: 'solid', text: 'Rejected' },
        SETTLED: {
          scheme: 'blue',
          variant: 'solid',
          text: 'wait for payment',
        },
        FAILED: { scheme: '', variant: '', text: '' },
        COMPLETED: { scheme: '', variant: '', text: '' },
      },
      LD: {
        INIT: { scheme: 'blue', variant: 'outline', text: 'Enter rate' },
        PENDING: { scheme: 'blue', variant: 'solid', text: 'Wait for user' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        APPROVED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Ready to approve',
        },
        REJECTED: { scheme: 'red', variant: 'solid', text: 'Rejected' },
        SETTLED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Submit payment detail',
        },
        FAILED: { scheme: '', variant: '', text: '' },
        COMPLETED: { scheme: '', variant: '', text: '' },
      },
    },
    WITHDRAW: {
      USER: {
        INIT: { scheme: 'blue', variant: 'solid', text: 'Wait for Rate' },
        PENDING: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Payment selection',
        },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        APPROVED: {
          scheme: 'blue',
          variant: 'solid',
          text: 'Wait for approve',
        },
        REJECTED: { scheme: 'red', variant: 'solid', text: 'Rejected' },
        SETTLED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Confirm payment detail',
        },
        FAILED: { scheme: '', variant: '', text: '' },
        COMPLETED: { scheme: '', variant: '', text: '' },
      },
      LD: {
        INIT: { scheme: 'blue', variant: 'outline', text: 'Enter rate' },
        PENDING: { scheme: 'blue', variant: 'solid', text: 'Wait for user' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        APPROVED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Ready to approve',
        },
        REJECTED: { scheme: 'red', variant: 'solid', text: 'Rejected' },
        SETTLED: {
          scheme: 'blue',
          variant: 'solid',
          text: 'wait for payment',
        },
        FAILED: { scheme: '', variant: '', text: '' },
        COMPLETED: { scheme: '', variant: '', text: '' },
      },
    },
  };

  return (
    <AccordionButton px={{ base: 1, lg: 2 }}>
      <Stack
        display="flex"
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        align={{ base: 'flex-start', lg: 'center' }}
        justify="space-between"
        w="100%"
      >
        <Stack display="flex" flexDir="column" align="flex-start" gap={0}>
          <Text fontWeight="bold" textAlign="left" fontSize="lg">
            {username}
          </Text>
          <Text fontSize="sm" textAlign="left">
            {'( ' + dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss') + ' )'}
          </Text>
        </Stack>
        <Tag
          size="md"
          colorScheme={badgeStatus[transaction_type][panel][status].scheme}
          variant={badgeStatus[transaction_type][panel][status].variant}
          mx={{ base: 0, lg: 2 }}
        >
          <TagLabel>
            {badgeStatus[transaction_type][panel][status].text}
          </TagLabel>
          {/* <TagRightIcon as={} /> */}
        </Tag>
      </Stack>
      <AccordionIcon />
    </AccordionButton>
  );
};

export default LDProposalAccordionButton;
