import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Rate, RateResponse } from 'types';

import { api } from 'utils';

const useRate = (): UseMutationResult<RateResponse, ErrorResponse, Rate> => {
  return useMutation((data) => api.post(url.rate, data));
};
export default useRate;
