import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useAccess, useKycLevel2 } from 'hooks';
import { useTranslation } from 'localization';
import { UserLevel } from 'types';

import { Button } from 'components';

const StepsLevel: React.FC<{
  levelStep: {
    id: number;
    title: string;
    type: UserLevel | 'CORPORATE';
  }[];
  levelManagement: {
    [level: string]: {
      title: string;
      steps: {
        0: { color: string; icon: any };
        1: { color: string; icon: any };
        2: { color: string; icon: any };
        3: { color: string; icon: any };
      };
      features: { title: string; isActive: boolean; description: string }[];
    };
  };
  role: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedLevel: (value: UserLevel | 'CORPORATE') => void;
}> = ({ levelStep, levelManagement, role, setSelectedLevel }) => {
  const { t } = useTranslation('component.settings.verification');
  const access = useAccess();
  const navigate = useNavigate();

  const {
    data: kycData,
    mutateAsync: upgradeLevel2,
    isLoading,
  } = useKycLevel2();
  const {
    isOpen: isOpenLevel2,
    onOpen: onOpenLevel2,
    onClose: onCloseLevel2,
  } = useDisclosure();

  const closeKycModalHandler = () => {
    onCloseLevel2();
    navigate(0);
  };

  return (
    <Box>
      <Box
        w="100%"
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent={{ base: 'center', lg: 'space-between' }}
        py={8}
      >
        <Box display="flex" alignItems="center" gap={2}>
          {levelStep.map((item) => {
            return (
              <Box key={item.id} display="flex">
                <Box
                  display="flex"
                  flexDir={{ base: 'column', lg: 'row' }}
                  alignItems={{ base: 'flex-start', lg: 'center' }}
                  gap={2}
                  cursor="pointer"
                  onClick={() => setSelectedLevel(item.type as UserLevel)}
                >
                  {
                    levelManagement[role || 'LEVEL0'].steps[
                      item.id as 0 | 1 | 2 | 3
                    ].icon
                  }
                  <Text
                    color={
                      levelManagement[role || 'LEVEL0'].steps[
                        item.id as 0 | 1 | 2 | 3
                      ].color
                    }
                    fontSize={{ base: 'sm', lg: 'md' }}
                  >
                    {item.title}
                  </Text>
                  {item.id < 3 && (
                    <Box
                      w={4}
                      h={0.5}
                      display={{ base: 'none', lg: 'inline' }}
                      bgColor={
                        levelManagement[role || 'LEVEL0'].steps[
                          item.id as 0 | 1 | 2 | 3
                        ].color
                      }
                    />
                  )}
                </Box>
                {item.id < 3 && (
                  <Box
                    w={4}
                    h={0.5}
                    display={{ base: 'inline', lg: 'none' }}
                    mt={4}
                    bgColor={
                      levelManagement[role || 'LEVEL0'].steps[
                        item.id as 0 | 1 | 2 | 3
                      ].color
                    }
                  />
                )}
              </Box>
            );
          })}
        </Box>
        {access.kycLevel2Permission && (
          <Box py={2}>
            <Button
              variant="ghost"
              title="Next Level"
              onClick={() => upgradeLevel2([]).then(() => onOpenLevel2())}
              isLoading={isLoading}
            />
          </Box>
        )}
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenLevel2}
        onClose={closeKycModalHandler}
        size={{ base: 'full', lg: 'full' }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('upgradeToLvl2')}</ModalHeader>
          <ModalCloseButton right={4} left="unset" />
          <ModalBody px={0} bg="sumsub">
            {kycData && (
              <SumsubWebSdk
                accessToken={kycData.token}
                expirationHandler={() => closeKycModalHandler()}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StepsLevel;
