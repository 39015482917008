import { ReactComponent as Error } from 'assets/icons/errorIcon.svg';
import { ReactComponent as Pending } from 'assets/icons/pendingIcon.svg';
import { ReactComponent as Success } from 'assets/icons/successIcon.svg';
import { Transaction } from 'types';

const TransactionStatus: React.FC<{ status: Transaction['status'] }> = ({
  status,
}: {
  status: Transaction['status'];
}) => {
  const success = ['SETTLED'].includes(status);
  const failed = ['UNAUTHORIZED', 'NOT_CLEARED', 'DENIED', 'CANCELED'].includes(
    status
  );
  if (success) {
    return <Success />;
  }
  if (failed) {
    return <Error />;
  }
  return <Pending />;
};

export default TransactionStatus;
