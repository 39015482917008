import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useAddWhiteListOtp = (): UseMutationResult<[] | any, ErrorResponse> => {
  return useMutation(() => api.post(url.createCryptoWhitelistOtp));
};

export default useAddWhiteListOtp;
