import { useForm } from 'react-hook-form';
import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';
import { emailRegex } from 'utils/regex';

type PayloadType = { email: string };
/* eslint-disable no-unused-vars */
type FormType = {
  onButton: (data: PayloadType) => void;
  counter: number;
};
const Form: React.FC<FormType> = ({ onButton, counter }: FormType) => {
  const { t } = useTranslation('component.auth.forgotPassword.form');

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .lowercase()
      .matches(emailRegex, t('email_validate'))
      .label('email')
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: PayloadType) => {
    onButton(data);
  };

  const formProps = useInputAttributes(register, errors, {
    email: t('email'),
  });

  const resendButton = `${t('resend')} ( ${counter.toString()} )`;

  const disable = counter !== 0;

  return (
    <Box
      px={{ base: 2, sm: 0 }}
      w={{ base: '100%', sm: 312 }}
      mt={{ base: 2, md: 20 }}
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      {disable && (
        <Alert status="info" mb={4} w={{ base: '100%', md: '550px' }}>
          <AlertIcon />
          <Text color="info">{t('codeSent')}</Text>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '320px' }}>
        <Input {...formProps.email} w="100%" disabled={disable} />
        <Button
          type="submit"
          variant="filled"
          display="block"
          title={disable ? resendButton : t('submit')}
          isDisabled={disable}
          minW="100%"
          mt={{ base: 24, md: 8 }}
        />
      </form>
    </Box>
  );
};

export default Form;
