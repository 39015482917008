import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useContactSendOtp = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) =>
    api.post(`${url.contactSendOtp}/${id}/send-verification-otp`)
  );
};

export default useContactSendOtp;
