import React from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { ReactComponent as MagixGroupLogo } from 'assets/icons/magixGroupLogo.svg';
import { useAccess, useProfile } from 'hooks';
import { NoMatch } from 'pages';
import { XMModal } from 'pages/Dashboard/components';
import { routes } from 'statics';

import { Header, MenuList } from 'components';

const DashboardLayout: React.FC = () => {
  const { data: profile, isLoading } = useProfile();
  const access = useAccess();

  const username =
    profile?.profile.first_name && profile?.profile.last_name
      ? profile?.profile.first_name + ' ' + profile?.profile.last_name
      : '';

  const ldLevel = (profile?.role.title || 'LD') === 'LD';
  const brokerLevel = (profile?.role.title || 'BROKER') === 'BROKER';

  const menuList = [
    {
      title: 'dashboard',
      link: '/dashboard',
      isActive: true,
    },
    {
      title: 'exchange',
      link: '/exchange',
      isActive: access.swapService,
    },
    {
      title: 'transfer',
      link: '/transfer',
      isActive: access.transferService && !ldLevel && !brokerLevel,
    },
    { title: 'wallets', link: '/wallets', isActive: access.walletService },
    {
      title: 'requests',
      link: '/requests',
      isActive: ldLevel && access.requestLDService,
    },
    { title: 'settings', link: '/settings', isActive: true },
  ];

  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    if (
      searchParams.get('system_identifier') === 'XM' &&
      access.verifyInitiateExternalServicePermission &&
      access.rejectInitiateExternalServicePermission
    ) {
      onOpen();
      setSearchParams({});
    }
    if (location.state && location.state.returnUrl) {
      navigate(location.state.returnUrl || '');
    }
  }, []);

  if (isLoading) {
    return <NoMatch />;
  }

  return (
    <Box bg="secondary" width="full" height="100%" pb="6" minH="100vh">
      <VStack display={{ base: 'flex', lg: 'none' }}>
        <Header
          username={username}
          level={profile?.role.title}
          isLoading={isLoading}
        />
        <Outlet />
      </VStack>
      <HStack
        height="full"
        gap="0"
        display={{ base: 'none', lg: 'flex' }}
        align="flex-start"
        position="relative"
      >
        <VStack
          position="fixed"
          flex="1"
          pt="10"
          borderRight="1px solid"
          borderColor="body2"
          height="full"
          gap="0"
          maxH="100vh"
          bg="secondary"
          overflowY="auto"
        >
          <Box
            mb="131px"
            cursor="pointer"
            onClick={() => navigate(routes.dashboard.url)}
          >
            <MagixGroupLogo />
          </Box>
          <MenuList list={menuList.filter((item) => item.isActive)} />
        </VStack>
        <Box flex="3" px="72px" h="full" pt="7" ml="287.5px">
          <Header
            username={username}
            level={profile?.role.title}
            isLoading={isLoading}
          />
          <Outlet />
        </Box>
      </HStack>
      <XMModal
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
      />
    </Box>
  );
};
export default DashboardLayout;
