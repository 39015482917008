import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, ListResponse, Wallet } from 'types';

const useWalletWithCurrency = ({
  currency_id,
}: {
  currency_id: string;
}): UseQueryResult<ListResponse<Wallet>, ErrorResponse> => {
  return useQuery([`${url.wallets}?currency=${currency_id}`], {
    enabled: Boolean(currency_id),
  });
};
export default useWalletWithCurrency;
