import React from 'react';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';

const TradingView: React.FC = () => {
  const [symbol] = React.useState('BINANCE:BTCUSDT');

  return (
    <AdvancedRealTimeChart
      theme="dark"
      autosize
      enable_publishing={false}
      hide_legend={false}
      hide_top_toolbar={false}
      withdateranges={false}
      hide_side_toolbar={false}
      allow_symbol_change={false}
      show_popup_button={false}
      save_image={false}
      details={false}
      hotlist={false}
      calendar={false}
      symbol={symbol}
      interval="D"
    />
  );
};

export default TradingView;
