import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  CreateWithdrawCrypto,
  CreateWithdrawCryptoResponse,
  ErrorResponse,
} from 'types';

import { api } from 'utils';

const useWithdrawCrypto = (): UseMutationResult<
  CreateWithdrawCryptoResponse,
  ErrorResponse,
  CreateWithdrawCrypto
> => {
  return useMutation((data) => api.post(url.withdrawCrypto, data));
};

export default useWithdrawCrypto;
