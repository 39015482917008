import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  FormControl,
  HStack,
  InputGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/cloud-arrow-up.svg';
import { ReactComponent as InputErrorIcon } from 'assets/icons/input_error.svg';
import PDF from 'assets/icons/pdf.svg';

import { standaloneToast } from 'utils';

type InputPropTypes = {
  error?: string;
  invalid?: boolean;
  isRequired?: boolean;
  label: string;
  // eslint-disable-next-line no-unused-vars
  getValue: (value: string) => void;
  selectedFile: Array<ThumbFileType>;
  // eslint-disable-next-line no-unused-vars
  setSelectedFile: (data: Array<ThumbFileType>) => void;
};
type ThumbFileType = File & { preview: string };
const Uploader: React.FC<InputPropTypes> = ({
  invalid,
  isRequired = false,
  error,
  selectedFile,
  setSelectedFile,
  label,
  getValue,
}) => {
  const getBase64 = async (file: any) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: false,
    onDrop: async (acceptedFiles) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            standaloneToast({
              position: 'top-right',
              description: 'File is too large (max size : 3MB)',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }

          if (err.code === 'file-invalid-type') {
            standaloneToast({
              position: 'top-right',
              description: 'Invalid file (just: PNG, JPG, JPEG, PDF)',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
      });
      setSelectedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      const uploadedFile = acceptedFiles[0];
      const res = await getBase64(uploadedFile);
      getValue(res || '');
    },
    maxFiles: 1,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    maxSize: 3000000,
  });
  const thumbs = selectedFile.map((file: ThumbFileType) => {
    const type = file.type.split('/')[0];
    return (
      <HStack
        key={file.name}
        gap="2"
        align="center"
        justifyContent={{ base: 'flex-start', md: 'initial' }}
        w="full"
        zIndex="99"
      >
        <Box sx={{ '.img': { width: '4', height: '4' } }}>
          <img
            src={type === 'application' ? PDF : file.preview}
            className="img"
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </Box>
        <Text fontSize="xs">{file.name}</Text>
        <CloseIcon
          onClick={(e) => {
            e.stopPropagation();
            setSelectedFile([]);
          }}
        />
      </HStack>
    );
  });
  return (
    <VStack align="flex-start" pos="relative">
      <Text>{label}</Text>
      <Stack
        w="full"
        rounded="lg"
        position="relative"
        overflow="hidden"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
        justify={selectedFile.length > 0 ? 'space-between' : 'center'}
        px="6"
        py="5"
        rowGap="4"
        sx={{
          ':before': {
            content: '" "',
            position: 'absolute',
            border: '10px dashed ',
            borderColor: selectedFile.length > 0 ? 'primary' : '#757575',
            top: '-8px',
            bottom: '-8px',
            left: '-8px',
            right: '-8px',
            zIndex: 0,
          },
        }}
        {...getRootProps()}
      >
        {thumbs}
        <Stack
          zIndex={1}
          direction={{ base: 'column', md: 'row' }}
          gap="2"
          mr={{ base: 0, md: '60px' }}
          align="center"
        >
          <UploadIcon />
          <FormControl isInvalid={invalid} isRequired={isRequired} w={0}>
            <InputGroup>
              <input {...getInputProps()} />
            </InputGroup>
          </FormControl>
          <HStack>
            <Text fontSize="xs" fontWeight="medium">
              Drop your file here, or
            </Text>
            <Text color="primary" fontSize="xs" fontWeight="medium">
              Browse
            </Text>
          </HStack>
        </Stack>
      </Stack>
      <Text mt="1" fontSize="xs">
        JPEG, JPG, PNG, PDF (MAX 3MB)
      </Text>
      {error && (
        <Box
          color="error"
          fontSize="xs"
          textAlign="start"
          display="flex"
          alignItems="center"
          gap={1}
          pos="absolute"
          left={0}
          bottom={-5}
        >
          <InputErrorIcon />
          {error}
        </Box>
      )}
    </VStack>
  );
};
export default Uploader;
