import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import { SelectPropTypes } from './data';

const CurrencyInput: React.FC<SelectPropTypes> = ({
  invalid,
  data,
  defaultValue,
  name,
  control,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name, ref } }) => (
        <FormControl isInvalid={invalid}>
          <Select
            ref={ref}
            name={name}
            defaultValue={defaultValue}
            options={data}
            variant="unstyled"
            onChange={onChange}
            value={value}
            chakraStyles={{
              container: () => ({
                background: 'transparent',
                color: 'body',
                fontSize: '1.625rem',
                fontFamily: 'Inter',
                fontWeight: 'semibold',
                textTransform: 'uppercase',
              }),

              placeholder: () => ({
                color: 'body',
                fontSize: '26px',
              }),
              control: () => ({
                fontSize: '26px',
                display: 'flex',
                alignItems: 'center',
              }),
              indicatorsContainer: () => ({}),
              valueContainer: () => ({
                display: 'flex',
                alignItems: 'center',
              }),
              menu: (provided) => ({
                ...provided,
                marginBlock: 0,
              }),
              menuList: () => ({
                borderRadius: 'lg',
                background: 'secondary',
                border: '1px solid',
                borderColor: 'body2',
                marginBlock: 0,
              }),
              downChevron: (provided, { selectProps }) => ({
                ...provided,
                transition: '300ms',
                transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
                cursor: 'pointer',
                marginLeft: '-10px',
                fontSize: '34px',
                lineHeight: '34px',
              }),
              inputContainer: (provided) => ({
                ...provided,
                maxW: 'max-content',
              }),
              option: (provided, { isSelected, isFocused }) => ({
                ...provided,
                ':first-of-type': { borderRadius: '8px 8px 0 0' },
                ':last-of-type': { borderRadius: '0 0 8px 8px' },

                ...(isFocused && {
                  backgroundColor: '#2D3335',
                }),
                ...(!isFocused &&
                  isSelected && {
                    backgroundColor: 'transparent',
                  }),
              }),
            }}
          />
        </FormControl>
      )}
    />
  );
};

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
