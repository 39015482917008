// Must be a work for all phone number formats
const formatPhone = (phone: string): string => {
  const last4 = phone.substring(phone.length - 4);
  const first3afterLast4 = phone.slice(phone.length - 7, phone.length - 4);
  const second3afterFirst3 = phone.slice(phone.length - 10, phone.length - 7);

  const othersFromStart = phone.slice(0, phone.length - 10);

  return (
    othersFromStart +
    ' ' +
    second3afterFirst3 +
    ' ' +
    first3afterLast4 +
    ' ' +
    last4
  );
};

export default formatPhone;
