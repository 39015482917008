import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { CreateProposalBankAccount, ErrorResponse } from 'types';

import { api } from 'utils';

const useProposalBankAccount = (): UseMutationResult<
  { id: string },
  ErrorResponse,
  { id: string; data: CreateProposalBankAccount }
> => {
  return useMutation(({ id, data }) =>
    api.put(`${url.proposalBankAccount}/${id}/bank-account`, data)
  );
};

export default useProposalBankAccount;
