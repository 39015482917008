import { Box, Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

const Spinner: React.FC<SpinnerProps> = ({ ...props }) => {
  return (
    <Box>
      <ChakraSpinner
        thickness="4px"
        speed="0.65s"
        emptyColor="secondary"
        color="primary"
        size="xl"
        {...props}
      />
    </Box>
  );
};

export default Spinner;
