import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useContactSendOtp,
  useCreateContact,
  useTimer,
  useVerifyContact,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { countryCode, yup } from 'utils';
import { emailRegex } from 'utils/regex';

const CreateContact: React.FC<{
  buttonTitle: string;
  type: 'EMAIL' | 'MOBILE';
  verifyId?: string;
  onSuccess: () => void;
}> = ({ buttonTitle, type, verifyId = '', onSuccess }) => {
  const { t } = useTranslation('component.settings.profile');
  const [step, setStep] = React.useState<'init' | 'verify'>('init');
  const [selectedCountry, setSelectedCountry] = React.useState<string>('');
  const [directlyToVerify, setDirectlyToVerify] =
    React.useState<boolean>(false);
  const [submittedContactId, setSubmittedContactId] =
    React.useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: submitContact, isLoading: submitLoading } =
    useCreateContact();
  const { mutateAsync: sendOtp, isLoading: sendOtpLoading } =
    useContactSendOtp();
  const { mutateAsync: verifyContact, isLoading: verifyLoading } =
    useVerifyContact();

  const { counter, setCounter } = useTimer(0);

  const closeHandler = () => {
    onClose();
    setStep('init');
    setSubmittedContactId('');
    setSelectedCountry('');
    resetSubmit();
    resetVerify();
    onSuccess();
    setDirectlyToVerify(false);
    setCounter(0);
  };

  React.useEffect(() => {
    if (directlyToVerify && verifyId) {
      setStep('verify');
      sendOtp({ id: verifyId })
        .then(() => {
          setCounter(120);
          setSubmittedContactId(verifyId);
        })
        .catch(() => {
          closeHandler();
        });
    }
  }, [directlyToVerify]);

  const formFieldsIdentity =
    type === 'MOBILE'
      ? {
          yup: {
            country: yup.string().label(t('form.country')).required(),
            identity: yup.string().label(t('form.mobile')).required(),
          },
          attributes: {
            country: t('form.country'),
            identity: t('form.mobile'),
          },
        }
      : {
          yup: {
            country: yup.string().label(t('form.country')),
            identity: yup
              .string()
              .email()
              .lowercase()
              .matches(emailRegex, t('email_validate'))
              .label(t('form.email'))
              .required(),
          },
          attributes: {
            country: t('form.country'),
            identity: t('form.email'),
          },
        };

  const {
    register: registerSubmit,
    handleSubmit,
    reset: resetSubmit,
    resetField: resetSubmitField,
    formState: { errors: errorsSubmit },
  } = useForm({
    resolver: yupResolver(yup.object().shape(formFieldsIdentity.yup)),
    defaultValues: {},
  });

  const formPropsSubmit = useInputAttributes(
    registerSubmit,
    errorsSubmit,
    formFieldsIdentity.attributes
  );
  const onSubmit = async (data: { identity: string; country?: string }) => {
    let payload = {
      ...data,
      type: type,
    };
    if (data.country) {
      payload = {
        ...payload,
        identity:
          payload.country + data.identity.replace(payload.country || '', ''),
      };
    }
    submitContact(payload).then((res) => {
      if (res.id) {
        setSubmittedContactId(res.id);
        resetSubmit();
        setStep('verify');
        sendOtp({ id: res.id }).then(() => {
          setCounter(120);
        });
      }
    });
  };

  const formFieldsVerify = {
    yup: {
      code: yup.string().label(t('form.code')).required(),
    },
    attributes: {
      code: t('form.code'),
    },
  };

  const {
    register: registerVerify,
    handleSubmit: handleVerify,
    reset: resetVerify,

    formState: { errors: errorsVerify },
  } = useForm({
    resolver: yupResolver(yup.object().shape(formFieldsVerify.yup)),
    defaultValues: {},
  });

  const formPropsVerify = useInputAttributes(
    registerVerify,
    errorsVerify,
    formFieldsVerify.attributes
  );
  const onVerify = async (data: { code: string }) => {
    verifyContact({ data, id: submittedContactId }).then(() => {
      resetVerify();
      closeHandler();
    });
  };

  const setOtpHandler = () => {
    sendOtp({ id: submittedContactId }).then(() => {
      setCounter(120);
    });
  };

  const selectedFlag = countryCode.find(
    (item) => item.value === selectedCountry
  )?.flagSvg;

  return (
    <Box>
      <Button
        variant="ghost"
        title={buttonTitle}
        fontSize={verifyId ? 'xs' : 'md'}
        onClick={() => {
          if (verifyId) {
            setDirectlyToVerify(true);
          }
          onOpen();
        }}
      />
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => closeHandler()}
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>
            Add new {type === 'MOBILE' ? 'Mobile' : 'Email'}
          </ModalHeader>
          <ModalBody pb={6}>
            {step === 'init' ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack w="100%">
                  {type === 'MOBILE' && (
                    <Box w="100%">
                      <Input
                        {...formPropsSubmit.country}
                        isSelectBox
                        options={countryCode}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          resetSubmitField('identity');
                        }}
                        leftValue={
                          selectedFlag ? (
                            <img
                              src={selectedFlag}
                              alt="-"
                              width="20px"
                              height="20px"
                            />
                          ) : (
                            ''
                          )
                        }
                      />
                    </Box>
                  )}
                  <Box w="100%">
                    <Input
                      {...formPropsSubmit.identity}
                      leftValue={selectedCountry}
                    />
                  </Box>
                </VStack>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="flex-end"
                  gap={4}
                  mt={10}
                >
                  <Button
                    variant="filled"
                    title="Submit"
                    type="submit"
                    isLoading={submitLoading}
                  />
                  <Button
                    variant="ghost"
                    title="Close"
                    onClick={() => closeHandler()}
                  />
                </Box>
              </form>
            ) : (
              <form onSubmit={handleVerify(onVerify)}>
                <Box>
                  <Input
                    {...formPropsVerify.code}
                    textAlign="center"
                    placeholder="     -     -     -     -     -     -     "
                  />
                </Box>
                <Box mt={6} w="100%">
                  <Button
                    variant="filled"
                    w="100%"
                    title={counter === 0 ? 'Send Code' : counter.toString()}
                    onClick={() => setOtpHandler()}
                    isLoading={sendOtpLoading}
                    isDisabled={counter !== 0}
                  />
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="flex-end"
                  gap={4}
                  mt={10}
                >
                  <Button
                    variant="filled"
                    title="Submit"
                    type="submit"
                    isLoading={verifyLoading}
                  />
                  <Button
                    variant="ghost"
                    title="Close"
                    onClick={() => closeHandler()}
                  />
                </Box>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateContact;
