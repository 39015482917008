import React from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { useProfile } from 'hooks';
import { useTranslation } from 'localization';

import { Tabs } from 'components';

import {
  FinanceTab,
  Head,
  ProfileTab,
  SecurityTab,
  VerificationTab,
} from './components';

const Settings: React.FC = () => {
  const [tab, setTab] = React.useState<number>(0);
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const { t } = useTranslation('page.settings');

  const { data: profile } = useProfile();

  const title = [
    t('profileTitle'),
    t('tabs.verification'),
    t('tabs.finance'),
    t('tabs.security'),
  ];

  return (
    <Box w="100%" pt={{ base: 2, lg: 8 }}>
      <Head title={title[tab]} />
      <Box my={{ base: 0, lg: 4 }}>
        <Tabs
          isLazy
          index={tab}
          onChange={(active) => setTab(active)}
          tabData={[
            {
              label: t('tabs.profile'),
              content: <ProfileTab profile={profile} />,
              isActive: true,
            },
            {
              label: isLargerThan800 ? t('tabs.verification') : 'Verify',
              content: <VerificationTab />,
              isActive: true,
            },
            {
              label: t('tabs.finance'),
              content: <FinanceTab />,
              isActive: profile?.role.title !== 'LEVEL0',
            },
            {
              label: t('tabs.security'),
              content: <SecurityTab />,
              isActive: profile?.role.title !== 'LEVEL0',
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Settings;
