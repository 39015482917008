import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useApproveTransfer = (
  transactionId: string
): UseMutationResult<any, ErrorResponse> => {
  return useMutation(() =>
    api.put(`${url.approveTransaction}/${transactionId}/approve`)
  );
};
export default useApproveTransfer;
