import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

const useRouterState = ({
  onChange,
  dependencies = [],
}: {
  // eslint-disable-next-line no-unused-vars
  onChange: (n: NavigateFunction, l: Location) => void;
  dependencies?: any[];
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const callback = React.useCallback(() => {
    onChange(navigate, location);
    navigate(location.pathname + location.search, { replace: true });
  }, [location.state, ...dependencies]);

  React.useEffect(() => {
    callback();
  }, [location.state, ...dependencies]);

  return { navigate, location };
};

export default useRouterState;
