import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useProposalCashPayment = (): UseMutationResult<
  [],
  ErrorResponse,
  { id: string }
> => {
  return useMutation(({ id }) =>
    api.put(`${url.proposalCashPayment}/${id}/cash-payment`)
  );
};
export default useProposalCashPayment;
