import React from 'react';
import { Box } from '@chakra-ui/react';
import { ReactComponent as Lvl1Filled } from 'assets/icons/1filled.svg';
import { ReactComponent as Lvl2 } from 'assets/icons/2.svg';
import { ReactComponent as Lvl2Filled } from 'assets/icons/2filled.svg';
import { ReactComponent as Checked } from 'assets/icons/circle-check.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { useProfile } from 'hooks';
import { UserLevel } from 'types';

import {
  CorporateFrom,
  LevelFeatures,
  LevelRequirements,
  StepsLevel,
} from './components';

const VerificationTab: React.FC = () => {
  const { data: profile } = useProfile();
  const [selectedLevel, setSelectedLevel] = React.useState<
    UserLevel | 'CORPORATE'
  >(profile?.role.title || 'LEVEL0');
  const levelManagement: {
    [level: string]: {
      title: string;
      steps: {
        0: { color: string; icon: any };
        1: { color: string; icon: any };
        2: { color: string; icon: any };
        3: { color: string; icon: any };
      };
      features: { title: string; isActive: boolean; description: string }[];
      requirements: string;
    };
  } = {
    LEVEL0: {
      title: 'Level 0',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'body2', icon: <Lvl1Filled /> },
        2: { color: 'body2', icon: <Lvl2 /> },
        3: { color: 'body2', icon: <Star /> },
      },
      features: [
        { title: 'Exchange', isActive: false, description: '' },
        { title: 'Transfer', isActive: false, description: '' },
        { title: 'Deposit', isActive: false, description: '' },
        { title: 'Withdraw', isActive: false, description: '' },
      ],
      requirements: 'Email verification',
    },
    LEVEL1: {
      title: 'Level 1',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'primary', icon: <Checked /> },
        2: { color: 'body2', icon: <Lvl2Filled /> },
        3: { color: 'body2', icon: <Star /> },
      },
      features: [
        { title: 'Exchange', isActive: true, description: 'Unlimited' },
        {
          title: 'Transfer',
          isActive: true,
          description: '(Only to verified Brokers) 5K$ Daily, 95K$ Monthly',
        },
        { title: 'Deposit', isActive: true, description: 'Unlimited' },
        { title: 'Withdraw', isActive: false, description: '' },
      ],
      requirements:
        'Phone Verification, Identity Verification, Proof of liveness',
    },
    LEVEL2: {
      title: 'Level 2',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'primary', icon: <Checked /> },
        2: { color: 'primary', icon: <Checked /> },
        3: { color: 'body2', icon: <StarFilled /> },
      },
      features: [
        { title: 'Exchange', isActive: true, description: 'Unlimited' },
        { title: 'Transfer', isActive: true, description: 'Unlimited' },
        { title: 'Deposit', isActive: true, description: 'Unlimited' },
        {
          title: 'Withdraw',
          isActive: true,
          description:
            '(Fiat: 10K$ Daily, 250K$ Monthly) (Crypto: 1M$ Monthly)',
        },
      ],
      requirements: 'Proof of address',
    },
    CORPORATE: {
      title: 'Corporate',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'primary', icon: <Checked /> },
        2: { color: 'primary', icon: <Checked /> },
        3: { color: 'primary', icon: <Checked /> },
      },
      features: [
        { title: 'Exchange', isActive: true, description: 'Unlimited' },
        { title: 'Transfer', isActive: true, description: 'Unlimited' },
        { title: 'Deposit', isActive: true, description: 'Unlimited' },
        {
          title: 'Withdraw',
          isActive: true,
          description:
            '(Fiat: 50K$ Daily, 1.5M$ Monthly) (Crypto: 5M$ Monthly)',
        },
      ],
      requirements: 'Please complete our corporate sign up Questionnaires',
    },
    LD: {
      title: 'Local Depositor',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'primary', icon: <Checked /> },
        2: { color: 'primary', icon: <Checked /> },
        3: { color: 'body2', icon: <StarFilled /> },
      },
      features: [],
      requirements: '',
    },
    BROKER: {
      title: 'Broker',
      steps: {
        0: { color: 'primary', icon: <Checked /> },
        1: { color: 'primary', icon: <Checked /> },
        2: { color: 'primary', icon: <Checked /> },
        3: { color: 'primary', icon: <Checked /> },
      },
      features: [],
      requirements: '',
    },
  };

  const levelStep: {
    id: number;
    title: string;
    type: UserLevel | 'CORPORATE';
  }[] = [
    { id: 0, title: 'Level 0', type: 'LEVEL0' },
    { id: 1, title: 'Level 1', type: 'LEVEL1' },
    { id: 2, title: 'Level 2', type: 'LEVEL2' },
    { id: 3, title: 'Corporate', type: 'CORPORATE' },
  ];

  return (
    <Box>
      <StepsLevel
        levelManagement={levelManagement}
        levelStep={levelStep}
        setSelectedLevel={setSelectedLevel}
        role={profile?.role.title || 'LEVEL0'}
      />
      <Box
        w="100%"
        bgColor="dark"
        display="flex"
        flexDir={{ base: 'column', xl: 'row' }}
        alignItems="flex-start"
        justifyContent="space-between"
        my={2}
        gap={4}
      >
        <Box px={4} w={{ base: '100%', xl: '50%' }}>
          <LevelFeatures levelManagement={levelManagement[selectedLevel]} />
        </Box>
        <Box px={4} w={{ base: '100%', xl: '50%' }}>
          {selectedLevel === 'CORPORATE' && <CorporateFrom />}
          <LevelRequirements
            title={levelManagement[selectedLevel].title}
            requirement={levelManagement[selectedLevel].requirements}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VerificationTab;
