import {
  AccordionButton,
  AccordionIcon,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Transaction } from 'types';

type LDTransactionAccordionButton = {
  createdAt: string;
  username: string;
  status: Transaction['status'];
  panel: 'LD' | 'USER';
  transaction_type: 'WITHDRAW' | 'DEPOSIT';
};
type BadgeStatusType = Record<
  'WITHDRAW' | 'DEPOSIT',
  Record<
    'LD' | 'USER',
    Record<
      Transaction['status'],
      { scheme: string; variant: string; text: string }
    >
  >
>;

const LDTransactionAccordionButton: React.FC<LDTransactionAccordionButton> = ({
  createdAt,
  username,
  status,
  panel,
  transaction_type,
}: LDTransactionAccordionButton) => {
  const badgeStatus: BadgeStatusType = {
    DEPOSIT: {
      USER: {
        INIT: { scheme: '', variant: '', text: '' },
        CLEARED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Ready to complete',
        },
        NOT_CLEARED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Failed',
        },
        AUTHORIZED: { scheme: '', variant: '', text: '' },
        UNAUTHORIZED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Failed',
        },
        APPROVED: { scheme: '', variant: '', text: '' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        SETTLING: { scheme: '', variant: '', text: '' },
        SETTLED: { scheme: 'green', variant: 'solid', text: 'Transacted' },
        DENIED: { scheme: 'red', variant: 'solid', text: 'Failed' },
        FAILED: { scheme: 'red', variant: 'solid', text: 'Failed' },
      },
      LD: {
        INIT: { scheme: '', variant: '', text: '' },
        CLEARED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Ready for complete',
        },
        NOT_CLEARED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Failed',
        },
        AUTHORIZED: { scheme: '', variant: '', text: '' },
        UNAUTHORIZED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Failed',
        },
        APPROVED: { scheme: '', variant: '', text: '' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        SETTLING: { scheme: '', variant: '', text: '' },
        SETTLED: { scheme: 'green', variant: 'solid', text: 'Transacted' },
        DENIED: { scheme: 'red', variant: 'solid', text: 'Failed' },
        FAILED: { scheme: 'red', variant: 'solid', text: 'Failed' },
      },
    },
    WITHDRAW: {
      USER: {
        INIT: { scheme: '', variant: '', text: '' },
        CLEARED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Wait for approve',
        },
        NOT_CLEARED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Not Cleared',
        },
        AUTHORIZED: { scheme: '', variant: '', text: '' },
        UNAUTHORIZED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Not Cleared',
        },
        APPROVED: { scheme: 'blue', variant: 'outline', text: 'Give code' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        SETTLING: { scheme: '', variant: '', text: '' },
        SETTLED: { scheme: 'green', variant: 'solid', text: 'Transacted' },
        DENIED: { scheme: 'red', variant: 'solid', text: 'Failed' },
        FAILED: { scheme: 'red', variant: 'solid', text: 'Failed' },
      },
      LD: {
        INIT: { scheme: '', variant: '', text: '' },
        CLEARED: {
          scheme: 'blue',
          variant: 'outline',
          text: 'Wait for approve',
        },
        NOT_CLEARED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Not Cleared',
        },
        AUTHORIZED: { scheme: '', variant: '', text: '' },
        UNAUTHORIZED: {
          scheme: 'red',
          variant: 'solid',
          text: 'Not Cleared',
        },
        APPROVED: { scheme: '', variant: '', text: '' },
        CANCELED: { scheme: 'red', variant: 'solid', text: 'Canceled' },
        SETTLING: { scheme: '', variant: '', text: '' },
        SETTLED: { scheme: 'green', variant: 'solid', text: 'Transacted' },
        DENIED: { scheme: 'red', variant: 'solid', text: 'Failed' },
        FAILED: { scheme: 'red', variant: 'solid', text: 'Failed' },
      },
    },
  };

  return (
    <AccordionButton px={{ base: 1, lg: 2 }}>
      <Stack
        display="flex"
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        align={{ base: 'flex-start', lg: 'center' }}
        justify="space-between"
        w="100%"
      >
        <Stack display="flex" flexDir="column" align="flex-start" gap={0}>
          <Text fontWeight="bold" textAlign="left" fontSize="lg">
            {username}
          </Text>
          <Text fontSize="sm" textAlign="left">
            {'( ' + dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss') + ' )'}
          </Text>
        </Stack>
        <Tag
          size="md"
          colorScheme={badgeStatus[transaction_type][panel][status].scheme}
          variant={badgeStatus[transaction_type][panel][status].variant}
          mx={{ base: 0, lg: 2 }}
        >
          <TagLabel>
            {badgeStatus[transaction_type][panel][status].text}
          </TagLabel>
          {/* <TagRightIcon as={} /> */}
        </Tag>
      </Stack>
      <AccordionIcon />
    </AccordionButton>
  );
};

export default LDTransactionAccordionButton;
