import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAccess } from 'hooks';

import { Sessions, Whitelist } from './components';

const SecurityTab: React.FC = () => {
  const access = useAccess();

  return (
    <Box>
      {access.whiteListCryptoWithdrawalService && <Whitelist />}
      <Sessions />
    </Box>
  );
};

export default SecurityTab;
