import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import {
  ErrorResponse,
  GenerateSignUpToken,
  GenerateSignUpTokenResponse,
} from 'types';

import { api } from 'utils';

const useGenerateSignUpToken = (): UseMutationResult<
  GenerateSignUpTokenResponse,
  ErrorResponse,
  GenerateSignUpToken
> => {
  return useMutation((data) => api.post(url.generateSignUpToken, data));
};

export default useGenerateSignUpToken;
