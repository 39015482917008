import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  HStack,
  Select,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useProfile, useTransactions, useWallets } from 'hooks';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import {
  Button,
  DateRangeSelect,
  Spinner,
  TransactionDetailModal,
  TransactionMethod,
  TransactionStatus,
} from 'components';
import { exportPDF, fixDecimal } from 'utils';

const TRansactionsTab: React.FC = () => {
  const { t } = useTranslation('component.wallet.tabs.transactions');
  const [selectedTr, setSelectedTr] = React.useState<Transaction | undefined>(
    undefined
  );
  const [dateRange, setDateRange] = React.useState<(Date | null)[]>([
    null,
    null,
  ]);

  const [searchparams, setSearchparams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedWallet = searchparams.get('wallet') || '';
  const selectedStatus = (searchparams.get('status') ||
    '') as Transaction['status'];

  const dateInQuery = dateRange.includes(null)
    ? {}
    : {
        from_date: dayjs(dateRange[0]).toISOString(),
        to_date: dayjs(dateRange[1]).toISOString(),
      };
  const walletInQuery = searchparams.get('wallet')
    ? {
        currency: searchparams.get('wallet'),
      }
    : {};

  const { data: profile } = useProfile();
  const { data: transactions, isLoading } = useTransactions({
    queries: selectedStatus
      ? { ...dateInQuery, ...walletInQuery, status: selectedStatus }
      : { ...dateInQuery, ...walletInQuery },
  });

  const { data: wallets, isFetched } = useWallets({ queries: {} });

  return (
    <VStack mx={{ base: 4, lg: 0 }}>
      <Box
        w="100%"
        py={6}
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontWeight="bold" fontSize={{ base: 'lg', lg: '3xl' }}>
          {t('list')}
        </Text>
      </Box>
      <VStack w="100%">
        <Stack
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          justify={{ base: 'center', md: 'space-between' }}
        >
          <Button
            variant="ghost"
            title={t('downloadExcel')}
            onClick={() =>
              transactions?.list && transactions.list.length > 0
                ? exportPDF({
                    data: transactions.list.map((item) => {
                      const isDestination =
                        typeof item.destination_wallet === 'object' &&
                        item.destination_wallet !== null;
                      const title = isDestination
                        ? item.destination_wallet.currency.title
                        : item.source_wallet.currency.title;
                      const amount = isDestination
                        ? item.destination_amount
                        : item.amount;
                      return {
                        type:
                          item.transaction_method.type === 'DEPOSIT'
                            ? profile?.role.title === 'LD'
                              ? 'Withdraw'
                              : 'Deposit'
                            : item.transaction_method.type === 'WITHDRAW'
                            ? profile?.role.title === 'LD'
                              ? 'Deposit'
                              : 'Withdraw'
                            : item.transaction_method.type === 'SWAP'
                            ? 'SWAP'
                            : 'Exchange',
                        title: title,
                        amount: amount,
                        time: item.created_at,
                      };
                    }),
                    fileName: 'transactions',
                  })
                : ''
            }
          />
          <Stack
            w={{ base: '100%', md: 'max-content' }}
            align="center"
            justify="center"
          >
            <Stack
              flexDir={{ base: 'column', xl: 'row' }}
              align="center"
              justify="space-between"
              w="max-content"
            >
              <Stack
                w="100%"
                flexDir="row"
                align="center"
                justifyContent="space-between"
              >
                {isFetched && wallets?.list && wallets?.list.length > 0 && (
                  <Box>
                    <Select
                      value={selectedWallet}
                      onChange={(e) => {
                        searchparams.set('wallet', e.target.value);
                        setSearchparams(searchparams);
                      }}
                      sx={{
                        h: 8,
                        w: 20,
                        padding: '0 0 0 28px',
                      }}
                    >
                      <option value="">All</option>
                      {wallets?.list.map((wallet) => {
                        return (
                          <option
                            key={wallet.currency.id}
                            value={wallet.currency.id}
                          >
                            {wallet.currency.symbol}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>
                )}
                <Box>
                  <Select
                    value={selectedStatus}
                    onChange={(e) => {
                      searchparams.set('status', e.target.value);
                      setSearchparams(searchparams);
                    }}
                    sx={{
                      h: 8,
                      w: 24,
                      padding: '0 0 0 28px',
                    }}
                  >
                    <option value="">All</option>
                    <option value="SETTLED">Settled</option>
                  </Select>
                </Box>
              </Stack>
              <Box>
                <DateRangeSelect
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        {isLoading && (
          <HStack justify="center" w="100%">
            <Spinner />
          </HStack>
        )}
        {transactions && transactions.list?.length === 0 ? (
          <VStack w="100%" m="2" textAlign="center">
            <Text>{t('noTransaction')}</Text>
          </VStack>
        ) : (
          transactions?.list.map((transaction) => {
            const trType = transaction.transaction_method.type;
            const isDestination =
              typeof transaction.destination_wallet === 'object' &&
              transaction.destination_wallet !== null;
            const isCrypto = ['DEPOSIT_CRYPTO', 'WITHDRAW_CRYPTO'].includes(
              transaction.transaction_method.category
            );
            const swapDetail =
              trType === 'SWAP'
                ? {
                    destination_amount: fixDecimal({
                      value: transaction.destination_amount,
                      fractionDigits: isCrypto ? 5 : 2,
                    }),
                    destination_symbol:
                      transaction.destination_wallet.currency.symbol,
                    source_amount: fixDecimal({
                      value: transaction.amount,
                      fractionDigits: isCrypto ? 5 : 2,
                    }),
                    source_symbol: transaction.source_wallet.currency.symbol,
                  }
                : {};

            const icon = isDestination
              ? transaction.destination_wallet.currency.icon
              : transaction.source_wallet.currency.icon;
            const symbol = isDestination
              ? transaction.destination_wallet.currency.symbol
              : transaction.source_wallet.currency.symbol;
            const title = isDestination
              ? transaction.destination_wallet.currency.title
              : transaction.source_wallet.currency.title;
            const amount = isDestination
              ? transaction.destination_amount
              : transaction.amount;
            const isLDTr = ['DEPOSIT_LD', 'WITHDRAW_LD'].includes(
              transaction.transaction_method.category
            );
            const isBrokerTr = ['DEPOSIT_BROKER', 'WITHDRAW_BROKER'].includes(
              transaction.transaction_method.category
            );

            const isIntermediary =
              (profile?.role.title === 'LD' && isLDTr) ||
              (profile?.role.title === 'BROKER' && isBrokerTr);

            const positiveOrNegative = {
              DEPOSIT: isIntermediary ? '-' : '+',
              WITHDRAW: isIntermediary ? '+' : '-',
              TRANSFER: isDestination ? '+' : '-',
              SWAP: isDestination ? '+' : '-',
            };

            return (
              <VStack
                w="100%"
                my="2"
                key={transaction.id}
                p="4"
                bg="secondary"
                borderRadius="lg"
                align="flex-start"
                gap="4"
                onClick={() => {
                  setSelectedTr(transaction);
                  onOpen();
                }}
              >
                <VStack w="100%" align="center" justify="space-between">
                  <HStack w="100%" justify="space-between" align="center">
                    <Box
                      display="flex"
                      flexDir={{ base: 'column', sm: 'row' }}
                      alignItems={{ base: 'flex-start', sm: 'center' }}
                      justifyContent="center"
                      gap={2}
                    >
                      <Box
                        width="40px"
                        height="40px"
                        borderRadius={50}
                        overflow="hidden"
                      >
                        <img
                          src={icon}
                          alt={symbol}
                          width="40px"
                          height="40px"
                        />
                      </Box>
                      <VStack align="flex-start">
                        <Text fontSize="sm" color="body" fontWeight="bold">
                          {title}
                        </Text>
                        <Text fontSize="sm" color="body2">
                          {dayjs(transaction.created_at).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                        </Text>
                      </VStack>
                    </Box>
                    <Box
                      display="flex"
                      flexDir={{ base: 'column', sm: 'row-reverse' }}
                      alignItems={{ base: 'flex-end', sm: 'center' }}
                      justifyContent="center"
                      gap={2}
                    >
                      <Box
                        display="flex"
                        flexDir="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                      >
                        <TransactionMethod
                          type={transaction.transaction_method.type}
                          isIntermediary={isIntermediary}
                        />
                        <TransactionStatus status={transaction.status} />
                      </Box>
                      <VStack>
                        {trType !== 'SWAP' && (
                          <Text fontSize="md" fontWeight="bold" color="body">
                            {
                              positiveOrNegative[
                                transaction.transaction_method.type
                              ]
                            }
                            {`${fixDecimal({
                              value: amount,
                              fractionDigits: isCrypto ? 5 : 2,
                            })} ${symbol}`}
                          </Text>
                        )}
                        {trType === 'SWAP' && (
                          <Stack>
                            <Text fontSize="md" fontWeight="bold" color="body">
                              {`+ ${swapDetail.destination_amount} 
                              ${swapDetail.destination_symbol}`}
                            </Text>
                            <Text fontSize="sm" color="body2">
                              {`- ${swapDetail.source_amount} ${swapDetail.source_symbol}`}
                            </Text>
                          </Stack>
                        )}
                      </VStack>
                    </Box>
                  </HStack>
                </VStack>
              </VStack>
            );
          })
        )}
        {profile && selectedTr && (
          <TransactionDetailModal
            isOpen={isOpen}
            onClose={onClose}
            transaction={selectedTr}
            profile={profile}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default TRansactionsTab;
