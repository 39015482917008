import { Divider, Stack } from '@chakra-ui/react';
import { ReactComponent as Note } from 'assets/icons/note.svg';
import { useTranslation } from 'localization';

import { fixDecimal } from 'utils';

import LDTransactionKeyValue from '../LDTransactionKeyValue';

type LDTransactionCardProps = {
  identifier_number: number;
  pay_with: string;
  amount: number;
  payingValue?: number;
  receivingValue?: number;
  symbol: string;
  rate?: number;
  payment_method?: 'CASH' | 'BANK_TRANSFER';
  note: string;
  panel: 'LD' | 'USER';
  method: 'WITHDRAW' | 'DEPOSIT';
  address?: string;
};

const LDTransactionCard: React.FC<LDTransactionCardProps> = ({
  identifier_number,
  pay_with,
  amount,
  symbol,
  rate,
  note,
  panel,
  payment_method,
  payingValue,
  receivingValue,
  method,
  address,
}: LDTransactionCardProps) => {
  const { t } = useTranslation('component.requests.transactions');

  const PAYMENT_METHOD: Record<'CASH' | 'BANK_TRANSFER', string> = {
    CASH: t('cash'),
    BANK_TRANSFER: t('bank_transfer'),
  };

  const payer =
    (method === 'WITHDRAW' && panel === 'LD') ||
    (method === 'DEPOSIT' && panel === 'USER');
  const receiver =
    (method === 'WITHDRAW' && panel === 'USER') ||
    (method === 'DEPOSIT' && panel === 'LD');

  return (
    <Stack flexDir="column" gap={4} w="100%">
      <LDTransactionKeyValue
        title={
          panel === 'LD' ? t('userIdentifierNumber') : t('ldIdentifierNumber')
        }
        value={identifier_number}
      />
      {pay_with && (
        <LDTransactionKeyValue
          title={
            payer
              ? t('payCurrency')
              : receiver
                ? t('receiveCurrency')
                : t('curr')
          }
          value={pay_with}
        />
      )}
      <LDTransactionKeyValue
        title={t('currency')}
        value={`${amount} ${symbol}`}
      />
      {rate && (
        <LDTransactionKeyValue
          title={t('rate')}
          value={fixDecimal({
            value: rate,
            fractionDigits: 2,
          })}
        />
      )}
      {payingValue && (
        <LDTransactionKeyValue
          title={t('payingValue')}
          value={
            fixDecimal({
              value: payingValue,
              fractionDigits: 5,
            }) +
            ' ' +
            pay_with
          }
        />
      )}
      {receivingValue && (
        <LDTransactionKeyValue
          title={t('receivingValue')}
          value={
            fixDecimal({
              value: receivingValue,
              fractionDigits: 5,
            }) +
            ' ' +
            pay_with
          }
        />
      )}
      {payment_method && (
        <LDTransactionKeyValue
          title={t('payment_method')}
          value={PAYMENT_METHOD[payment_method]}
        />
      )}
      {address && (
        <LDTransactionKeyValue title={t('receiveAddress')} value={address} />
      )}
      <LDTransactionKeyValue
        title={t('userNote')}
        value={note}
        flexDir="column"
        align="flex-start"
        icon={<Note />}
        sx={{ maxW: '100%', overflow: 'hidden' }}
      />
      <Divider />
    </Stack>
  );
};
export default LDTransactionCard;
